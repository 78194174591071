import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { Modal, FormControl, FormLabel, TextField } from '@mui/material';
import TitleText from './TitleText';
import ButtonStyled from './ButtonStyled';
import Chip from './Chip';
import { characteristicsCreate, characteristicsUpdate } from '../services/characteristics';
import { getCode, nextBase36Consecutive } from '../utils/utils';

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '81.4%', md: '83.9%', lg: '62.5%'},
    bgcolor: 'background.paper',
    borderRadius: '0.25rem',
    boxShadow: 24,
    pt: '1.125rem',
    pb: '2.5rem',
    flexGrow: 1,
    '&:focus-visible': { outline: 0}
};

const formLabelStyle = {color: '#A1A1A1', fontWeight:'bold', fontSize:{xs: '1rem',}, display: 'flex', pb:'0.6rem' };
const inputStyle = {sx: { fontSize:{xs: '1rem', md: '1rem'} }};

const CreateCharacteristicModal = ({ reloadTableData, itemToEdit, openModal, onCloseModal}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [characteristicForm, setCharacteristicForm] = useState({
        characteristicName: '',
        values: []
    });
    const [newOption, setNewOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [characteristicNameError, setCharacteristicNameError] = useState(false);
    const [newOptionError, setNewOptionError] = useState(false);

    const {characteristicName, values} = characteristicForm;

    useEffect(() => {
        !!itemToEdit && preSetForm();
    }, [])

    const preSetForm = () =>{
        setCharacteristicForm(itemToEdit);
    };
    

    const validateForm = () => {
        
        setCharacteristicNameError(!characteristicName);

        if (!characteristicName) {
            enqueueSnackbar(`Escribe el nombre de la característica.`, {
            variant: 'error'
            });
            return false;
        }

        if (!values.length) {
            enqueueSnackbar(`Agrega al menos una opción.`, {
            variant: 'error'
            });
            return false;
        }
        return true;
    };

    const updateForm = (e) => {
        let inputName = e.target.name;
        let value =  e.target.value;
    
        const NewValue = {[inputName]: value}
    
        setCharacteristicForm({
            ...characteristicForm,
            ...NewValue
        });
        
        (characteristicNameError && inputName == 'characteristicName') && setCharacteristicNameError(false);
    };

    const handleNewOption = (e) => {
        let value =  e.target.value;
        setNewOption(value);
        
        newOptionError && setNewOptionError(false);
    };

    const addNewOption = (e) => {
        const newOptionTrim = newOption.trim();
        if (!newOptionTrim) {
            return;
        };

        for (let i = 0; i < values.length; i++) {
            if (values[i].valueName === newOptionTrim) {
                setNewOptionError(true);
                enqueueSnackbar("Ya existe una opción con el mismo valor.", {
                    variant: 'warning'
                });
              return;
            }
        }

        let valueCode = getCode(newOptionTrim);

        const duplicateCodes = values.map(value => value.code)
        .filter(codex => codex.startsWith(valueCode));

        if(!!duplicateCodes.length) {
            let codes = duplicateCodes
                .map(element => element.substring(2))
                .filter(code => !!code);
            codes.push('1');
            const nextCode = nextBase36Consecutive(codes);
            valueCode = valueCode + nextCode;
        }

        setCharacteristicForm({
            ...characteristicForm,
            values: [...values, {valueName: newOptionTrim, code: valueCode}]
        });

        setNewOption('');


    };

    const handleDeleteCharacteristic = (index) => {
        const newValues = values.filter((item, i) => i !== index);

        setCharacteristicForm({
            ...characteristicForm,
            values: [...newValues]
        });
    
    }

    const OnSubmitForm = async () => {
        if(isLoading){
          return;
        }
    
        if(!validateForm()){
          return;
        }
        
        setIsLoading(true);
        const resultForm = itemToEdit
        ? await characteristicsUpdate({...characteristicForm, characteristicName: characteristicForm.characteristicName.trim()})
        : await characteristicsCreate({...characteristicForm, characteristicName: characteristicForm.characteristicName.trim()});
        setIsLoading(false);
        if(resultForm.result){
            await reloadTableData();
            onCloseModal();
            enqueueSnackbar("Creación Exitosa.", {
              variant: 'success',
              style: { 
                Width: '100%',
                justifyContent: 'center'
              }
            });
            
        }else{
          enqueueSnackbar(resultForm.message, {
            variant: 'error'
          });
        }
    };

    return (
        <Modal
            disableEscapeKeyDown
            slotProps={{backdrop: {onClick: null}}}
            open={openModal}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                backdropFilter: 'blur(2px)',
                BackdropProps:{
                onClick: null
                },
                '& .MuiModal-backdrop': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                }
            }}
        >
            <Box sx={styleModal}>
                <Box sx={{display: 'flex', justifyContent: "flex-end", width:'100%', px: '1.25rem' }}>
                    <IconButton sx={{ padding: 0}} onClick={onCloseModal}>
                        <CancelIcon sx={{color: '#FC581F', fontSize:'1.8rem'}}/>
                    </IconButton>
                </Box>
                <Box sx={{ display:'flex', flexDirection: 'column', px: {xs: '1.25rem', md:'3.125rem'}, gap:'2.5rem' }}>
                    <TitleText title={`${!!itemToEdit ? 'EDITAR' : 'CREAR'} CARACTERÍSTICA`} customStyle={{ py: 0 }}/>
                    <FormControl variant="standard" fullWidth>
                        <FormLabel sx={formLabelStyle}>
                            NOMBRE DE LA CARACTERÍSTICA
                        </FormLabel>
                        <TextField 
                            inputProps={inputStyle}
                            error={characteristicNameError}
                            name='characteristicName' 
                            value={characteristicName}
                            onChange={updateForm}
                        />
                    </FormControl>
                    <Box>
                        <FormControl variant="standard" fullWidth>
                            <FormLabel sx={formLabelStyle}>
                                LISTADO DE OPCIONES
                            </FormLabel>
                            <Box sx={{display: 'flex', width: '100%', gap:'1rem'}}>
                                <TextField
                                    fullWidth
                                    inputProps={inputStyle}
                                    name='newOption' 
                                    value={newOption}
                                    error={newOptionError}
                                    onChange={handleNewOption}
                                    onKeyDown={(e) => { 
                                     if(e.key == "Enter") addNewOption(e)
                                    }}
                                />
                                <IconButton 
                                    sx={{ 
                                        color: '#FC581F',
                                        p:{xs:'0rem 1rem'},
                                        boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                                    }}
                                    onClick={addNewOption}
                                >
                                    <AddIcon sx={{fontSize:'1.5rem !important'}} />
                                </IconButton>
                                    
                            </Box>
                        </FormControl>
                        <Box
                            sx={{
                                display:'flex',
                                width:'100%',
                                gap:'0.62rem',
                                pt:'0.73rem',
                                overflowX: 'auto',
                                flexWrap: 'nowrap',
                              }}
                        >
                            {values.map((item, indx) => (
                                <Chip key={item.code} index={indx} label={item.valueName} onDelete={handleDeleteCharacteristic}/>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{width:'100%', display:'flex', justifyContent: 'space-between', gap: {xs: '1.14rem', md:'2.21rem', lg: '2.57rem'} }}>
                        <ButtonStyled text={'CANCELAR'} primary={false} customStyle={{ width:'100%' }} handleClick={onCloseModal}/>
                        <ButtonStyled text={'GUARDAR'} customStyle={{ width:'100%' }} handleClick={OnSubmitForm }/>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default CreateCharacteristicModal