export const productCsv = [
    [
        "Nombre del Producto",
        "Descripción del Producto",
        "Precio Base del Producto",
        "Largo",
        "Ancho",
        "Alto",
        "Peso",
        "Caja Propia",
        "Caja de Madera",
        "Stock",
        "Tiempo de entrega",
        "Característica 1",
        "Valor 1",
        "Precio Extra 1",
        "Característica 2",
        "Valor 2",
        "Precio Extra 2",
        "Característica 3",
        "Valor 3",
        "Precio Extra 3",
        "Característica 4",
        "Valor 4",
        "Precio Extra 4",
        "Característica 5",
        "Valor 5",
        "Precio Extra 5",
        "Característica 6",
        "Valor 6",
        "Precio Extra 6",
        "Característica 7",
        "Valor 7",
        "Precio Extra 7",
        "Característica 8",
        "Valor 8",
        "Precio Extra 8",
        "Característica 9",
        "Valor 9",
        "Precio Extra 9",
        "Característica 10",
        "Valor 10",
        "Precio Extra 10",
        "Característica 11",
        "Valor 11",
        "Precio Extra 11",
        "Característica 12",
        "Valor 12",
        "Precio Extra 12",
        "Característica 13",
        "Valor 13",
        "Precio Extra 13",
        "Característica 14",
        "Valor 14",
        "Precio Extra 14",
        "Característica 15",
        "Valor 15",
        "Precio Extra 15",
        "Característica 16",
        "Valor 16",
        "Precio Extra 16",
        "Característica 17",
        "Valor 17",
        "Precio Extra 17",
        "Característica 18",
        "Valor 18",
        "Precio Extra 18",
        "Característica 19",
        "Valor 19",
        "Precio Extra 19",
        "Característica 20",
        "Valor 20",
        "Precio Extra 20",
    ]
];

export const variantsCsv = [[
    "Producto base",
    "Variante",
    "ID Único",
    "Stock",
]]