import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '47%',
    bgcolor: 'background.paper',
    borderRadius: '12px',
    boxShadow: 24,
    px: 3,
    py: 2,
    flexGrow: 1,
    '&:focus-visible': { outline: 0}
  };


const DeleteModal = ({title, confirmButtonText, onConfirmed, openModal, onCloseModal}) => {
    return (
        <Modal
            disableEscapeKeyDown
            slotProps={{backdrop: {onClick: null}}}
            open={openModal}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                backdropFilter: 'blur(2px)',
                BackdropProps:{
                onClick: null
                },
                '& .MuiModal-backdrop': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
                }
            }}
        >
            <Box sx={style}>
                <Grid container rowSpacing={5} columnSpacing={10}>
                    <Grid justifyContent="flex-end" item xs={12} style={{display: 'flex'}}>
                        <IconButton sx={{ padding: 0}} onClick={onCloseModal}>
                            <CancelIcon fontSize='medium' style={{color: '#2C2C2C', opacity: '0.6'}}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} justifyContent="center" style={{display: 'flex'}}>
                        <Typography id="modal-modal-title" variant="h5" >
                            <strong>{title} </strong>
                        </Typography>
                    </Grid>                
                    <Grid item xs={6}>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            fullWidth
                            sx={{
                                typography: 'font-family: "Inter", sans-serif;',
                                fontSize:'16px',
                                backgroundColor: '#303030',
                                borderRadius: '12px',
                                textTransform: 'none',
                                '&:hover': { backgroundColor: '#303030'}
                            }}
                            onClick={onCloseModal}
                        > 
                            {'Cancelar'}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            fullWidth
                            sx={{
                                typography: 'font-family: "Inter", sans-serif;',
                                fontSize:'16px',
                                backgroundColor: '#FC581F',
                                borderRadius: '12px',
                                textTransform: 'none',
                                '&:hover': { backgroundColor: '#FC581F'}
                            }}
                            onClick={onConfirmed}
                        > 
                            {confirmButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default DeleteModal