import axios from '../utils/axios';

const SERVICE_URL = '/subcategories';

export const getSubCategoriesList = async () => {
    try {
        let response = await axios.get(`${SERVICE_URL}`);
        return response.data.subCategories.items;
    } catch (error) {
        return [];
    }
}

export const getSubCategoriesOnCategoriesList = async () => {
    try {
        let response = await axios.get(`${SERVICE_URL}/bycategory`);
        return response.data.categories;
    } catch (error) {
        return [];
    }
}