import { useState } from 'react';

import { Box, Typography, Tooltip } from '@mui/material';
import ClearIcon from '../components/icons/ClearIcon';

const ChipLabel = ({label, fontstyle = null, showTooltip = false, background = null}) => {

    return(
      <Tooltip title={showTooltip ? label : ''} arrow>
        <Box 
          sx={{
            borderRadius: '6.25rem',
            background: background ? background.bg : '#FC581F',
            display: 'flex',
            padding:' 0.125rem 0.5rem',
            alignItems: 'center',
            border: background ? `1px solid ${background.bg2}` : 0,
            gap: '0.625rem',
            color:'white',
            width: 'fit-content',
            overflow: 'hidden'
          }}
        >
          <Typography
            sx={ fontstyle ?
              fontstyle
              : {
                fontSize:{xs: '0.75rem', lg: '0.875rem'},
                fontStyle: 'normal',
                fontWeight: '400',
                whiteSpace: 'nowrap',
              }
            }
          >
            {label}
          </Typography>
        </Box>
      </Tooltip>
    )
  }

export default ChipLabel


