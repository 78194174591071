import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


import TableCell  from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import DeleteModal from './DeleteModal';
import { formatNumberToCurrency } from '../utils/utils';
import { UserContext } from '../context/UserContext';

const tableStyle = {
  headerCell: {
    color: '#A1A1A1', 
    borderBottom: '0', 
    padding: '0.5rem 0.5rem',
    fontSize:'0.8125rem',
    width:'20%'
  },
  headerRow:{
    borderBottom:'1px solid #A1A1A1'
  },
  bodyCell:{
    padding: '1rem 0.5rem',
    width: '20%',
    borderBottom: '1px solid #F8F8F8',
    maxWidth: 0
  },
  preventOverflow: {
    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
  },
  twoLinesOverflow: {
    fontSize: '0.875rem', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden'
  },
  pagination:{
    fontSize: '0.875rem',
    color: '#303030'
  },
  iconPagination: {
    padding: 0,
    color:'#303030'
  },
  SelectOptions:{
    fontSize: {xs:'0.875rem', md:'1rem'}
  },
  pageNumber: {
    padding: {xs:'0.469rem 3.217rem', md: '0.688rem 1.155rem'},
    borderRadius: '0.25rem',
    border: '1px solid #D9D9D9',
    background: '#FFFEFC',
    fontSize:'1rem'
  }
};

const ActionsButton = ({onEdit, onDelete, onVariants, rowItem}) => {
  return (
    <>
      <IconButton sx={{ padding: 0 }} onClick={() => onEdit(rowItem)}>
        <EditOutlinedIcon style={{ fontSize: {lg:'1.5rem'}, color: 'black'}}/>
      </IconButton>
      <IconButton sx={{ padding: 0 }} onClick={() => onDelete(rowItem)}>
        <DeleteOutlineOutlinedIcon style={{ fontSize: {lg:'1.5rem'}, color: 'black'}} />
      </IconButton>
      <IconButton sx={{ padding: 0 }} onClick={() => onVariants(rowItem)}>
        <SettingsOutlinedIcon style={{ fontSize: {lg:'1.5rem'}, color: 'black'}} />
      </IconButton>
    </>
  )
}

export default function DataTable({
  tableData = [],
  onEdit,
  onDelete,
  onVariants,
  page, 
  rowsPerPage,
  totalPages,
  onPageChange,
  onRowsPerPageChange,
  isLoading
}) {

  const [rows, setRows] = React.useState([]);
  
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteTitle, setDeleteTitle] = React.useState('');
  const [productToDelete, setProductToDelete] = React.useState('');
  const { domain } = React.useContext(UserContext);

  React.useEffect( () => {
    setRows(tableData);
  },[tableData]);


  const onDeleteModal = (product) => {
    setProductToDelete(product);
    setDeleteTitle(`¿Deseas borrar el producto ${product.productName}?`);
    setOpenModal(true);
  };

  const onConfirmed = () => {
    onDelete(productToDelete);
    onCloseModal();
    
  };

  const onCloseModal = () =>{
    setOpenModal(false);
    setProductToDelete('');
  };

  const handleBackButton = () => {
    onPageChange(page - 1);
  };

  const handleNextButton = () => {
    onPageChange(page + 1);
  };

  const handleRowsPerPageChange = (evt) => {
    onRowsPerPageChange(evt.target.value);
  };

  return (
    <>
      <TableContainer sx={{display:{xs:'none', lg:'block'} }} component={Paper}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow sx={tableStyle.headerRow}>
              <TableCell sx={tableStyle.headerCell} align="center">PRODUCTO</TableCell>
              <TableCell sx={tableStyle.headerCell} align="center">DESCRIPCIÓN</TableCell>
              <TableCell sx={tableStyle.headerCell} align="center">PRECIO BASE</TableCell>
              <TableCell sx={tableStyle.headerCell} align="center">SUBCATEGORÍA</TableCell>
              <TableCell sx={tableStyle.headerCell} align="center">ACCIONES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {(rows
            ).map((row) => (
              <TableRow hover key={row._id}>
                <TableCell sx={tableStyle.bodyCell} align="left" >
                    <Box sx={{display:'flex', gap:'0.5rem', width:'100%' }}>
                      <Box sx={{width:'3.1875rem', height:'2.375rem'}} >
                        {
                        row.images.length 
                        ? (<img
                            style={{
                              objectFit:'contain',  
                              height:'100%' 
                            }}
                            src={row.images.length ? `${domain}${row.images[0].url}` : '' }
                          />)
                        : (<Typography sx={{fontSize: '0.8rem',  textAlign: 'center' }}>Sin imagen</Typography>)
                        }
                      </Box>
                      <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'center', width:'calc(100% - 3.1875rem)' }}>
                        <Typography sx={{color:'#2C2C2C', fontSize:'0.875rem', fontWeight:'600', ...tableStyle.twoLinesOverflow }}>
                          {row.productName}
                        </Typography>
                      </Box>
                    </Box>
                </TableCell>
                <TableCell sx={tableStyle.bodyCell} align="center">
                  <Typography sx={tableStyle.twoLinesOverflow}>
                    {row.description}
                  </Typography>
                </TableCell>
                <TableCell sx={{...tableStyle.bodyCell, ...tableStyle.preventOverflow}} align="center">
                  {row.price ? formatNumberToCurrency(row.price): row.price}
                </TableCell>
                <TableCell sx={tableStyle.bodyCell} align="center">
                  <Typography sx={tableStyle.twoLinesOverflow}>
                    {row.subCategoriesName.join(', ')}
                  </Typography>
                </TableCell>
                <TableCell sx={tableStyle.bodyCell} align="center">
                  <Box sx={{display:'flex', gap:'1.5rem', justifyContent:'center', alignItems:'center'}}>
                    <ActionsButton onEdit={onEdit} onDelete={onDeleteModal} onVariants={onVariants} rowItem={row}/>
                  </Box>
                </TableCell>
              </TableRow>
            ))}

            {rows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell colSpan={6} align='center' >
                  SIN DATOS
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{display:{xs:'flex', lg:'none'}, flexDirection: 'column', gap:{xs:'0.63rem'} }}>
        {(rows).map( (row, index) => (
          <Box
          key={index}
            sx={{
              display: 'flex',
              padding: '0.75rem',
              alignItems: 'flex-start',
              gap: {xs:'0.5rem', md:'0.75rem'},
              height:'100%',
              alignItems: 'center',
              alignSelf: 'stretch',
              borderRadius: '0.25rem',
              background: '#FFF',
              boxShadow: '2px 4px 10px 0px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Box sx={{width: {xs: '4.375rem', md:'4.875rem'}, height:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
            {
              row.images.length 
              ? (<img
                  style={{
                    objectFit:'contain',  
                    height:'100%' 
                  }}
                  src={row.images.length ? `${domain}${row.images[0].url}` : '' }
                />)
              : (<Typography sx={{fontSize: {xs:'0.875rem', md:'1rem'},  textAlign: 'center' }}>Sin imagen</Typography>)
            }
              
            </Box>
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: {xs:'calc(100% - 4.875rem)', md: 'calc(100% - 5.625rem)'},
                gap: '1rem',
                flex: '1 0 0'
              }}
            >
              <Box sx={{ 
                display: 'flex',
                paddingBottom: '0.5rem',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '0.75rem',
                alignSelf: 'stretch',
                borderBottom: '1px solid #F8F8F8'
               }}>
                <Typography sx={{color: '#252323', fontSize: {xs:'0.875rem', md:'1rem'}, fontWeight: '700', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width:'100%' }}> {row.productName} </Typography>
                <Typography sx={{color: '#252323', fontSize: {xs:'0.75rem', md:'0.875rem'}, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width:'100%' }}> {row.productsSubcategories.length ? row.productsSubcategories.join(', ') : 'N/A'} </Typography>
              </Box>
              <Box sx={{color: '#252323', fontSize:'0.875rem' }}>
                <Typography 
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 2, 
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  {row.description}
                </Typography>
              </Box>
              <Box sx={{display:'flex', justifyContent:'space-between', gap: '1.5rem' }}>
                <Box sx={{color: '#252323', fontSize: {xs:'0.75rem', md:'0.875rem'} }} >{formatNumberToCurrency(row.price)}</Box>
                <Box sx={{display:'flex', gap:'1.25rem' }}>
                  <ActionsButton onEdit={onEdit} onDelete={onDeleteModal} onVariants={onVariants} rowItem={row}/>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{py:{xs:'1.5rem', md:'2rem'}, display:'flex', flexDirection: {xs:'column', sm: 'row'}, justifyContent:'space-between' }}>
        <Box>
          <Typography sx={{ display: {xs: 'block', sm: 'none' }, textAlign:'center', color: '#676565', pb:'1.2rem', fontSize: tableStyle.pagination.fontSize }}>{`Mostrando ${rows.length} resultados`}</Typography>
          <Select
            sx={{
              display:{xs:'none', sm:'inline-flex'},
              backgroundColor:'#FFFEFC', 
              fontSize: {xs:'0.875rem', md:'1rem'},
              '& .MuiSelect-select': {
                paddingRight: '0.75rem !important', 
              },
            }}
            IconComponent={() => <KeyboardArrowLeft sx={{transform: 'rotate(270deg)', mr: '0.75rem' }}/>}
            MenuProps={{
              MenuListProps:{
                fontSize: '5px'
              }
            }}
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            disabled={isLoading}
          >
            <MenuItem sx={tableStyle.SelectOptions} value={25}>25 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={50}>50 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={75}>75 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={100}>100 resultados</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display:'flex', alignItems:'center', gap:{xs:'0rem', sm:'0.5rem', md:'0.75rem'}, justifyContent:{xs: 'space-between', sm:'unset'} }}>
          <IconButton
            sx={tableStyle.iconPagination}
            onClick={handleBackButton}
            disabled={page === 1 || isLoading}
          >
            <KeyboardArrowLeft/>
          </IconButton>
          <Typography sx={tableStyle.pagination}>Página</Typography>
          <Typography sx={tableStyle.pageNumber}>{page}</Typography>
          <Typography sx={tableStyle.pagination}>{`de ${totalPages}`}</Typography>
          <IconButton
            sx={tableStyle.iconPagination}
            onClick={handleNextButton}
            disabled={page === totalPages || isLoading}
          >
            <KeyboardArrowRight/>
          </IconButton>
        </Box>
      </Box>
      <DeleteModal title={deleteTitle} confirmButtonText={'Eliminar Producto'} onConfirmed={onConfirmed} openModal={openModal} onCloseModal={onCloseModal}/>
    </>
  );
}
