export const chipStatus = {
    paid : {label: 'Pagado', background: {bg: '#109C3E', bg2: '#CCFFCE'}},
    created : {label: 'Creada', background: {bg: '#109C3E', bg2: '#CCFFCE'}},
    delivered : {label: 'Entregado', background: {bg: '#109C3E', bg2: '#CCFFCE'}},
    pending : {label: 'En camino', background: {bg: '#FC7444', bg2: '#FEDED2'}},
    processed : {label: 'Procesada', background: {bg: '#FC7444', bg2: '#FEDED2'}},
    cancelled : {label: 'Cancelado', background: {bg: '#D3D3D3', bg2: '#F4F4F4'}}
};

export const paymentMethodType  = {
    visa: 'Crédito',
    debvisa: 'Débito',
    master: 'Crédito',
    debmaster: 'Débito',
    amex: 'Crédito',
    account_money: 'Mercado pago',
};