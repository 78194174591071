import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import { Modal, Typography, Divider, Grid } from '@mui/material';
import { orderUpdate } from "../services/ordersService";
import { currencyFormat, formatDate } from '../utils/utils';
import ChipLabel from './ChipLabel';
import { chipStatus, paymentMethodType } from '../utils/enums';

import OrderBoxDetail from './OrderBoxDetail';
import IOSSwitch from './IOSSwitch';
import { userGetPresignedUrlForCfdiByCartId } from '../services/userService';

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '92%', md: '84%', lg: '64%'},
    bgcolor: 'background.paper',
    borderRadius: '0.25rem',
    boxShadow: 24,
    py: '2rem',
    px: {xs:'1rem', md: '2rem'},
    flexGrow: 1,
    '&:focus-visible': { outline: 0}
};

const customStyle = {   
    preventOverflow: {
      whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
    },
    twoLinesOverflow: {
        display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden'
    },
    iconPagination: {
      padding: 0,
      color:'#303030'
    },
    descriptionDiv: {
        display: { md:'flex'},
        gap: {md: '0.5rem'}
    },
    priceResumeDiv: {
        display: 'flex',
        gap: '0.75rem',
        justifyContent: 'flex-end'
    },
    subtitle: {
        color: '#303030',
        fontSize: {xs:'0.875rem', lg:'1rem'},
        fontWeight: 500,
        lineHeight: {xs:'1rem', lg:'1.125rem'}, 
        letterSpacing: '0.01563rem',
    },
    subtitleBold: {
        fontWeight: 'bold', 
        fontSize: {xs:'0.875rem', lg:'1rem'},
        lineHeight: {xs: '1.125rem'}, 
        color: '#303030'
    },
    description: {
        color: '#514F4F',
        fontSize: {xs:'0.875rem', lg:'1rem'},
        fontWeight: 400,
        lineHeight: {xs:'1.125rem', lg:'1.25rem'}, 
    }

};

const OrderDetailModal = ({ reloadTableData, order, openModal, onCloseModal}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const [checked, setChecked] = useState(!!order.isPackaged);
    const [boxes, setBoxes] = useState([]);
    const [cfdi_url, setCfdi_url] = useState(null);
    

    useEffect(() => {
        setBoxes(JSON.parse(JSON.stringify(order.shipment)))
    }, [])

    const getCfdi = async () => {
        if (isLoading) return;
    
        setIsLoading(true);
        try {
            let presignedUrl = cfdi_url;
            if (!cfdi_url) {
                presignedUrl = await userGetPresignedUrlForCfdiByCartId(order._id);
                setCfdi_url(presignedUrl);
            }
    
            const response = await fetch(presignedUrl);
            const blob = await response.blob();
            const pdfBlob = new Blob([blob], { type: 'application/pdf' });
            const localUrl = URL.createObjectURL(pdfBlob);
            const newWindow = window.open(localUrl, '_blank');
            newWindow.onload = () => {
                URL.revokeObjectURL(localUrl);
            };
        } catch (error) {
            console.error('Error al obtener el CFDI:', error);
            enqueueSnackbar('Ocurrió un problema al obtener el CFDI.', {
                variant: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };
    

    const handleChange = (value, indx) => {
        const newBoxes = JSON.parse(JSON.stringify(boxes));
        newBoxes[indx].isReadyForCollection = value;
        setBoxes(newBoxes);

        if(newBoxes.every(item => !!item.isReadyForCollection)){
            setChecked(true);
        }else{
            setChecked(false);
        }
    };

    const checkUpdate = async () => {
        if(isLoading) return;
    
        if(boxes.every((item, indx) => !!item.isReadyForCollection == !!order.shipment[indx].isReadyForCollection)){
            onCloseModal();
        }else{
            setIsLoading(true);
            const result = await orderUpdate({_id: order._id, isPackaged: checked, shipment: boxes});
            setIsLoading(false);
            if(result.result){
                reloadTableData();
                enqueueSnackbar(result.message, {
                    variant: 'success',
                    style: { 
                      backgroundColor: '#FC581F',
                      Width: '100%',
                      justifyContent: 'center'
                    }
                });
                onCloseModal();
            }else{
                enqueueSnackbar(result.message,{
                    variant: 'error'
                });
            }
        }
    }

    return (
        <Modal
            disableEscapeKeyDown
            slotProps={{backdrop: {onClick: null}}}
            open={openModal}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                backdropFilter: 'blur(2px)',
                BackdropProps:{
                onClick: null
                },
                '& .MuiModal-backdrop': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                }
            }}
        >
            <Box sx={styleModal}>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: {xs: '1.5rem', lg: '1.25rem'}, maxHeight: "85vh", width:'100%', overflow: 'auto'}}>
                    <Grid container rowSpacing={'1rem'} columnSpacing={'1rem'}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: 'center'}}>
                                <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
                                    <Typography sx={{color:'#303030', fontSize: '1.25rem', fontWeight:'bold', ...customStyle.twoLinesOverflow }}>
                                        {`ID de Orden No. ${order._id.split('-')[0]}`}
                                    </Typography>
                                </Box>
                                <IconButton sx={{ padding: 0}} onClick={checkUpdate}>
                                    <CancelIcon sx={{color: '#FC581F', fontSize:'1.8rem'}}/>
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: { xs:'flex'}, flexDirection: {xs: 'column', md: 'row'}, gap: '0.5rem' }}>
                                <Box sx={{display: 'flex', padding: '0.25rem 1rem', alignItems: 'center', width: 'fit-content', borderRadius: '6.25rem',  border: '1px solid #F4F4F4', background: '#E9E9E9'}}>
                                    <Typography sx={{color: '#303030', fontSize: {xs: '0.875rem', lg: '1rem'}, fontWeight: 500, lineHeight: {xs: '1rem', lg: '1.125rem'}, letterSpacing: '0.01563rem' }}>
                                        {`Número de autorización: ${!!order.paymentId ? order.paymentId : 'S/N'}`}
                                    </Typography>
                                </Box>
                                <ChipLabel label={chipStatus[order.status].label} background={chipStatus[order.status].background} />
                            </Box>    
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={customStyle.subtitle}>
                                Nombre de usuario: <Typography variant={'span'} sx={customStyle.description}>{!!order.clientInfo ? order.clientInfo.name: ''}</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={customStyle.subtitle}>
                                Dirección: <Typography variant={'span'} sx={customStyle.description}>{!!order.clientInfo ? `${order.clientInfo.address1} ${order.clientInfo.colony}, C.P. ${order.clientInfo.postalCode}, ${order.clientInfo.city}, ${order.clientInfo.region}`: ''}</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={'auto'}>
                            <Typography sx={customStyle.subtitle}>
                                Fecha: <Typography variant={'span'} sx={customStyle.description}>{formatDate(order.paidAt, 'D MMMM, YYYY')}</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={!!order.invoiceRequested ? 9 : 12}>
                            <Typography sx={customStyle.subtitle}>
                                Precio Dólar: <Typography variant={'span'} sx={customStyle.description}>{!!order.dollarPrice ? `${currencyFormat(order.dollarPrice)} MXN` : ''}</Typography>
                            </Typography>
                        </Grid>
                        {   !!order.invoiceRequested &&
                            (<>
                                <Grid item xs={12} md={'auto'}>
                                    <Typography sx={customStyle.subtitle}>
                                        Forma de pago: <Typography variant={'span'} sx={customStyle.description}>{paymentMethodType[order.paymentMethod]}</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={true}>
                                    <Typography sx={customStyle.subtitle}>
                                        Usos de CFDI: <Typography variant={'span'} sx={customStyle.description}>{!!order.cfdiUse ? order.cfdiUse : 'N/A' }</Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                                        <Typography sx={customStyle.subtitle}>Factura solicitada:</Typography>
                                        <Typography onClick={getCfdi} sx={{...customStyle.description, color: !isLoading ? '#2F69FF': '#a1a1a1', textDecorationLine: 'underline', cursor:  !isLoading ? 'pointer': 'default', lineHeight: '1.125rem'}}>
                                            Imprimir CFDI
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                            )
                        }
                        <Grid item xs={12}>
                            <Divider sx={{ borderColor:'#E9E9E9'}}/>

                        </Grid>
                    </Grid>
                    <Box id='3/3' sx={{display:'flex', flexDirection: 'column', gap: '1.25rem'}} >
                        <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: 'center', px: '1.25rem'}}>
                            <Typography sx={customStyle.subtitleBold}>Empaquetado:</Typography>
                            <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                                    <Typography sx={{fontSize: {xs:'0.875rem', lg: '1rem'}, fontWeight: 700, lineHeight: {xs: '1rem', lg: '1.125rem'}, fontWeight: '500', color: '#303030'}}>Orden empaquetada:</Typography>
                                    <IOSSwitch 
                                        sx={{ m: 0}} 
                                        checked={checked}
                                        inputProps={{ 'aria-label': 'controlled' }}  
                                    />
                                </Box>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Box sx={{display:'flex', padding: '1.25rem 1rem', gap: '1.5rem', flexDirection:'column', borderRadius: '0.625rem', border: '1px solid #E9E9E9', overflow: 'auto', maxHeight: {xs:'20vh', lg: '22vh', xl: '25vh'} }}>
                                {boxes.map((box, indx) => (
                                    <React.Fragment key={indx}>
                                        <OrderBoxDetail box={box} indx={indx} isLoading={isLoading} handleCheckedChange={handleChange}/>
                                        {indx+1 < boxes.length && <Divider sx={{ borderColor:'#BEBDBD'}}/> }
                                    </React.Fragment>
                                ))}
                            </Box>
                        </Box>
                        <Divider sx={{ borderColor:'#E9E9E9', mx: '1.25rem'}}/>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', gap:'0.75rem'}} >
                            <Box sx={customStyle.priceResumeDiv}>
                                <Typography sx={customStyle.subtitleBold}>Subtotal</Typography>
                                <Typography sx={customStyle.description}>{!!order.mercadopagoFee ? `${currencyFormat(order.totalPaid)} MXN` : ''}</Typography>
                            </Box>
                            <Box sx={customStyle.priceResumeDiv}>
                                <Typography sx={customStyle.subtitleBold}>Cargos por venta</Typography>
                                <Typography sx={customStyle.description}>{!!order.mercadopagoFee ? `- ${currencyFormat(order.mercadopagoFee)} MXN` : ''}</Typography>
                            </Box>
                            <Box sx={customStyle.priceResumeDiv}>
                                <Typography sx={customStyle.subtitleBold}>Envíos</Typography>
                                <Typography sx={customStyle.description}>{!!order.shippingFee ? ` - ${currencyFormat(order.shippingFee)} MXN` : ''}</Typography>
                            </Box>
                            <Divider sx={{ borderColor:'#FC581F'}}/>
                            <Box sx={customStyle.priceResumeDiv}>
                                <Typography sx={{fontSize: {lg:'1.125rem'}, lineHeight: {xs: '1.125rem', lg: '1.25rem'}, fontWeight: 'bold'}}>Monto Total:</Typography>
                                <Typography sx={{fontSize: {lg:'1.125rem'}, lineHeight: {xs: '1.125rem', lg: '1.25rem'}, fontWeight: 'bold'}}>
                                    {`${currencyFormat(Number(order.totalPaid) - Number(order.shippingFee) - Number(order.mercadopagoFee))} MXN`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default OrderDetailModal