import { useState, useEffect } from "react";
import { Box } from "@mui/material";

import { useSnackbar } from "notistack";
import SearchBar from "../components/SearchBar";
import TitleText from "../components/TitleText";
import { getOrdersList } from "../services/ordersService";
import DataTableOrders from "../components/DataTableOrders";
import OrderDetailModal from "../components/OrderDetailModal";

const OrdersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [LastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const [itemToEdit, setItemToEdit] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getTableData();
  }, [searchTerm, page, rowsPerPage]);

  const getTableData = async () => {
    setIsLoading(true);
    let { items, totalPages } = await getOrdersList(
      searchTerm,
      page,
      rowsPerPage,
      LastEvaluatedKey
    );

    setTableData(items);
    setTotalPages(totalPages);
    setLastEvaluatedKey(LastEvaluatedKey);
    setIsLoading(false);
  };

  const onOpenModal = (item) => {
    setItemToEdit(item);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setItemToEdit(null);
    setOpenModal(false);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const handleChangeSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
  };

  return (
    <Box sx={{ px: { xs: "1.5rem", md: "3rem" } }}>
      <TitleText title={"HISTORIAL DE ORDENES"} />
      <Box
        sx={{
          display: "flex",
          gap: { xs: "1rem", md: "1.5rem", lg: "2rem" },
          pb: { xs: "1.5rem", md: "2rem", lg: "2.13rem" },
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <SearchBar
            onSearchTermChange={handleChangeSearchTerm}
            placeHolder={"Buscar por No. de Orden"}
          />
        </Box>
      </Box>
      <DataTableOrders
        tableData={tableData}
        page={page}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onEdit={onOpenModal}
        onDelete={() => {}}
        onVariants={() => {}}
        isLoading={isLoading}
      />
      {openModal && (
        <OrderDetailModal
          reloadTableData={getTableData}
          order={itemToEdit}
          openModal={openModal}
          onCloseModal={onCloseModal}
        />
      )}
    </Box>
  );
};

export default OrdersPage;
