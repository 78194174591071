import axios from '../utils/axios';

const SERVICE_URL = '/characteristics';

export const getCharacteristicsList = async (
    searchTerm,
    page,
    rowsPerPage,
    LastEvaluatedKey
) => {
    try {
        let response = await axios.post(
            `${SERVICE_URL}`
            , 
            { 
                searchTerm,
                page,
                rowsPerPage,
                LastEvaluatedKey
            }
        );
        return response.data;
    } catch (error) {
        return {
            Items: [], 
            LastEvaluatedKey: {},
            totalPages: 1
        };
    }
}

export const getCharacteristicsSearch = async (
    wordToSearch
) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/search`, { wordToSearch });
        return response.data;
    } catch (error) {
        return {
            characteristics: [],
            error
        };
    }
}

export const getCharacteristicById = async (
    _id
) => {

    try {
        let response = await axios.post( `${SERVICE_URL}/id` , { _id } );
        return response.data;
    } catch (error) {
        return {
            error
        };
    }
}

export const characteristicsCreate = async (formData) => {

    try {
        let response = await axios.post(`${SERVICE_URL}/create`, formData );
        return response.data;
    } catch (error) {
        return {
            result: false,
            message: error
        };
    }
}

export const characteristicsUpdate = async (formData) => {

    try {
        let response = await axios.put(`${SERVICE_URL}/update`, formData );
        return response.data;
    } catch (error) {
        return {
            result: false,
            message: error
        };
    }
}

export const characteristicsDelete = async (_id) => {
    try {
        let response = await axios.delete(`${SERVICE_URL}/delete?id=${_id}`);
        return response.data;
    } catch (error) {
        return {
            result: false,
            message: error
        };
    }
}