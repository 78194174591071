import {useState, useEffect} from 'react'
import  { v4 as uuidv4 } from 'uuid';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button, Divider } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { getCategoriesList, categoriesCreate, canDeleteSubByCategory } from '../services/categoriesService';
import DeleteModal from '../components/DeleteModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';

const getLargestNumber = (list, attribute) => {
    if (!list || list.length === 0) {
      return 0;
    }
  
    let largestNumber = 0;
  
    list.forEach((item) => {
      const Name = item[attribute];
      const NameSplit = Name.split(' ');
      const number = parseInt(NameSplit[NameSplit.length-1]);
  
      if (!isNaN(number) && number > largestNumber) {
        largestNumber = number;
      }
    });
  
    return largestNumber;
};

const defaultCategories = [
    'Productos',
    'Marcas',
    'Aplicaciones',
    'Magnitudes'
];


const CategoriesPage = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [subByCategories, setSubByCategories] = useState([]);
    const [categoriesToDelete, setCategoriesToDelete] = useState([]);
    const [subcategoriesToDelete, setSubcategoriesToDelete] = useState([]);
    const [subByCategoriesToDelete, setSubByCategoriesToDelete] = useState([]);
    const [subcategoriesToRename, setSubcategoriesToRename] = useState([]);

    const [openModal, setOpenModal] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState('');
    const [itemToDelete, setItemToDelete] = useState('');

    useEffect(() =>{
        getCategoriesData();
    }, []);

    const getCategoriesData = async() => {
        const { categories, subCategories, subByCategories } = await getCategoriesList();
        const newCategories = addDefaultCategories(categories?.items);
        setSubcategories(subCategories?.items);
        setCategories(newCategories.map(item => ({...item, editable: false})));
        setSubByCategories(subByCategories?.items?.map(item => ({...item, editable: false})));
    };

    const addDefaultCategories = (categoriesList) => {
        const categoriesNames = categoriesList.map(category => category.categoryName);
        const unAddedCategories = defaultCategories.filter(item => !categoriesNames.includes(item));
        const newDefaultCategories =  unAddedCategories.map(category => 
            ({_id: uuidv4(), categoryName: category, subcategoriesId: [],  _status: false, deleted: false, isNewRecord: true, editable: false})
        );
        return [...categoriesList, ...newDefaultCategories];
    };

    const addSubCategory = () => {
        const lastNumber = getLargestNumber(subcategories, 'subCategoryName');
        const NewSub = {_id: uuidv4(), subCategoryName: `nueva subcategoría ${lastNumber + 1}`, _status: false, deleted: false, isNewRecord: true};
        setSubcategories([
            ...subcategories,
            NewSub
        ]);
    };

    const addCategory = () => {
        const lastNumber = getLargestNumber(categories, 'categoryName');
        const NewCategory = {_id: uuidv4(), categoryName: `nueva categoría ${lastNumber + 1}`, subcategoriesId: [],  _status: false, deleted: false, isNewRecord: true, editable: false};
        setCategories([
            ...categories,
            NewCategory
        ]);
    };

    const addSubByCategories = (categoryId, subCategoryId) => {
        const NewSubByCategory = {_id: uuidv4(), categoryId, subCategoryId, index: 1 ,  isNewRecord: true, editable: false};
        setSubByCategories([
            ...subByCategories,
            NewSubByCategory
        ]);
        return NewSubByCategory;
    };

    const deleteSubCategory = async () => {
        const subByCategoryIds = subByCategories.filter(subBy => subBy.subCategoryId == itemToDelete.item._id && !subBy.isNewRecord).map(item => item._id);
    
        if(!!subByCategoryIds.length){
            const canDelete = await canDeleteSubByCategory(subByCategoryIds);
            if(!canDelete.result){
                enqueueSnackbar("No se puede eliminar porque está asociado a un producto.", {
                    variant: 'warning'
                });
                return;
            }
        }


        const isRelated = categories.some(category => 
            category.subcategoriesId.some( subInCategory => subInCategory.subcategoryId === itemToDelete.item._id)
        );
        
        if(isRelated){
            let newSubByCategoriesToDelete = [];
            let newSubByCategories = [...subByCategories];
            const newCategories = categories.map(category => {
                const newSubcategoriesId = category.subcategoriesId.filter(subInCategory => {
                    if(subInCategory.subcategoryId === itemToDelete.item._id){
                        const subByCategoryItem = subByCategories.find(item => item._id == subInCategory.subByCategoriesId);
                        if(!subByCategoryItem.isNewRecord){
                            newSubByCategoriesToDelete.push(subByCategoryItem._id);
                        }
                        newSubByCategories = newSubByCategories.filter(item => item._id !== subByCategoryItem._id);
                        return false;
                    }
                    return true;
                });
                return {...category, subcategoriesId: newSubcategoriesId }

            });
            setCategories(newCategories);
            setSubByCategories(newSubByCategories);
            setSubByCategoriesToDelete([...subByCategoriesToDelete, ...newSubByCategoriesToDelete])
        }


        if (!itemToDelete?.item?.isNewRecord) {
            setSubcategoriesToDelete([...subcategoriesToDelete, itemToDelete.item._id]);
        }
        const newSubList = subcategories.filter(sub => sub._id !== itemToDelete.item._id);
        setSubcategories(newSubList);
    };

    const deleteCategory = async () => {
        const subByCategoryIds = subByCategories.filter(subBy => subBy.categoryId == itemToDelete.item._id && !subBy.isNewRecord).map(item => item._id);
    
        if(!!subByCategoryIds.length){
            const canDelete = await canDeleteSubByCategory(subByCategoryIds);
            if(!canDelete.result){
                enqueueSnackbar("No se puede eliminar porque está asociado a un producto.", {
                    variant: 'warning'
                });
                return;
            }
        }
        
        const isRelated = !!itemToDelete?.item.subcategoriesId.length;

        if(isRelated){
            let newSubByCategoriesToDelete = [];
            let newSubByCategories = [...subByCategories];

            itemToDelete?.item.subcategoriesId.forEach(subInCategory => {
                const subByCategoryItem = subByCategories.find(item => item._id == subInCategory.subByCategoriesId);
                if(!subByCategoryItem.isNewRecord){
                    newSubByCategoriesToDelete.push(subByCategoryItem._id);
                }
                newSubByCategories = newSubByCategories.filter(item => item._id !== subByCategoryItem._id);
            });

            setSubByCategories(newSubByCategories);
            setSubByCategoriesToDelete([...subByCategoriesToDelete, ...newSubByCategoriesToDelete])
        }
            
        if (!itemToDelete?.item?.isNewRecord) {
            setCategoriesToDelete([...subcategoriesToDelete, itemToDelete.item._id]);
        }
        const newCategoryList = categories.filter(category => category._id !== itemToDelete.item._id);
        setCategories(newCategoryList);
    };

    const deleteSubByCategory = async () => {
        const subcategory = itemToDelete.item.category.subcategoriesId.find(item => item.subcategoryId == itemToDelete.item.subcategory._id);
        const  subByCategory= subByCategories.find(item => item._id == subcategory.subByCategoriesId);
        if(!subByCategory.isNewRecord){
            const canDelete = await canDeleteSubByCategory([subByCategory._id]);
            if(!canDelete.result){
                enqueueSnackbar("No se puede eliminar porque está asociado a un producto.", {
                    variant: 'warning'
                });
                return;
            }
        }
        
        const newSubcategoriesId = itemToDelete.item.category.subcategoriesId.filter(subInCategory => {
            if(subInCategory._id === itemToDelete.item._id){
                const subByCategoryItem = subByCategories.find(item => item._id == subInCategory.subByCategoriesId);
                if(!subByCategoryItem.isNewRecord){
                    setSubByCategoriesToDelete([...subByCategoriesToDelete, subByCategoryItem._id]);
                }
                const newSubByCategoriesList = subByCategories.filter(item => item._id !== subByCategoryItem._id);
                setSubByCategories(newSubByCategoriesList);
                return false;
            }
            return true;
        });

        const newCategoryList = categories.map(category =>
            category._id === itemToDelete.item.category._id
                ? { ...category, subcategoriesId: newSubcategoriesId }
                : category
            );

        setCategories(newCategoryList);
    };

    const onSave =  async () => {
        const FormData = { categories, subcategories, subByCategories, categoriesToDelete, subcategoriesToDelete, subByCategoriesToDelete, subcategoriesToRename};
        const resultForm = await categoriesCreate(FormData);

        if(resultForm.result){
            enqueueSnackbar("Guardado Exitoso.", {
                variant: 'success',
                style: { backgroundColor: '#FC581F'}
            });
            setSubcategoriesToDelete([]);
            setCategoriesToDelete([]);
            setSubcategoriesToRename([]);
            await getCategoriesData();
        }else{
            enqueueSnackbar("Ocurrio un error al guardar.", {
                variant: 'error'
            });
        }
    };

    const onDeleteModal = (item, type) => {
        setItemToDelete({item, type });
    const typeList = {
        subcategory: `subcategoría ${item?.subCategoryName}`,
        category: `categoría ${item?.categoryName}`,
        subByCategory: `relación con la subcategoría ${item?.subcategory?.subCategoryName}`
    }

        setDeleteTitle(`¿Deseas borrar la  ${typeList[type]}`);
        setOpenModal(true);
    };
    
    const onConfirmed = async () => {
        const DeleteMethodList = {
            subcategory: () => deleteSubCategory(),
            category: () => deleteCategory(),
            subByCategory: () => deleteSubByCategory()
        }
        await DeleteMethodList[itemToDelete.type]();
        onCloseModal();
    };

    const onCloseModal = () =>{
        setOpenModal(false);
        setItemToDelete('');
    };

    const getSubcategories = (subcategoriesId) => {
        if(!subcategoriesId.length) return [];
        const subcategoriesName = subcategoriesId.map(({_id, subcategoryId}) => {
            const subcategoryData = subcategories.find((sub) => sub._id === subcategoryId);
            return subcategoryData ? {_id, subcategory: subcategoryData } : null;
        });
        return subcategoriesName.filter(item => item);
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source.subcategoriesId);
        const destClone = Array.from(destination.subcategoriesId);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
    
        const newSubByCategories = subByCategories.map(subByCategory => {
            if(subByCategory._id === removed.subByCategoriesId){
                return { ...subByCategory, categoryId: droppableDestination?.droppableId };
            }
            return subByCategory;
        });

        setSubByCategories(newSubByCategories);
        
        destClone.splice(droppableDestination.index, 0, removed);
        
        return {sourceList: sourceClone, destList: destClone};
    };


    const onDragEnd = result => {
        const { source, destination, draggableId } = result;
        const sourceId = source?.droppableId;
		const destinationId = destination?.droppableId;

		if (!destination || sourceId === destinationId) return

        const destinationCategory = categories.find(item => item._id === destinationId);

        if(sourceId === '1'){
            if(destinationCategory.subcategoriesId){
                const duplicate = destinationCategory.subcategoriesId.find(item => item.subcategoryId === draggableId);
                if (!!duplicate) {
                    enqueueSnackbar("No se permiten duplicados en la misma categoría.", {
                        variant: 'warning'
                    });
                    return;}
            }

            const destClone = {...destinationCategory};
            const subcategoriesIdClone = Array.from(destClone.subcategoriesId);
            const newSubByCategories = addSubByCategories(destinationId,draggableId);
            subcategoriesIdClone.splice(destination.index, 0, {_id: uuidv4(), subcategoryId: draggableId, subByCategoriesId: newSubByCategories._id });
            destClone.subcategoriesId = subcategoriesIdClone;
            const categoriesClone = categories.map(item => item._id == destClone._id ? destClone : item );
            setCategories(categoriesClone);
            return;
        }
        
        const sourceCategory = categories.find(item => item._id === sourceId);

        if (sourceId !== destinationId) {

            const dragabbleSource = sourceCategory.subcategoriesId.find(item => item._id === draggableId);
            const duplicate = destinationCategory.subcategoriesId.find(item => item.subcategoryId === dragabbleSource.subcategoryId);
            if (!!duplicate) {
                enqueueSnackbar("no se permiten duplicados en la misma categoría.", {
                    variant: 'warning'
                });
                return;
            }

            const destClone = {...destinationCategory};
            const sourceClone = {...sourceCategory};

            const {sourceList, destList} = move(sourceCategory, destinationCategory, source, destination);
            destClone.subcategoriesId = destList;
            sourceClone.subcategoriesId = sourceList;

            const categoriesClone = categories.map(item => 
                item._id === destClone._id || item._id === sourceClone._id
                ? item._id === destClone._id ? destClone : sourceClone
                : item
            );

            setCategories(categoriesClone);
        }
    };

    const updateField = (isCategories, index, field, value) => {
        const cloneList = isCategories ? Array.from(categories): Array.from(subcategories) ;
        if(field == 'subCategoryName' && !cloneList[index].isNewRecord){
            let cloneSubsToRename = Array.from(subcategoriesToRename);
            const indexRecord = subcategoriesToRename.findIndex(item => item.newValue == cloneList[index][field]);
            if(indexRecord >= 0){
                cloneSubsToRename[indexRecord].newValue = value;
            }else{
                cloneSubsToRename.push({oldValue: cloneList[index][field], newValue: value})
            }
            setSubcategoriesToRename(cloneSubsToRename)
        }
        cloneList[index][field] = value;
        isCategories ? setCategories(cloneList) : setSubcategories(cloneList);
	};

    const handleKeyDown = ({ isCategories, index, value, key }) => {
        if (key === 'Enter' && value.trim()) {
          updateField(isCategories, index, isCategories ? 'categoryName': 'subCategoryName', value);
        }
        
        if (key === 'Enter' || key === 'Escape') {
          updateField(isCategories, index, 'editable', false);
        }
    };

    return (
        <> 
            <CssBaseline>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Container maxWidth sx={{ height: '100%', px: '1rem !important', pt: '2%'}}>
                        <Grid container sx={{pb: 2}}>
                            <Grid item xs={12}>
                                <Typography variant='h4' component='h1' sx={{fontSize: '1.5rem', lineHeight: '2rem' }}>
                                <strong>CATEGORÍAS</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h7' component='h2' sx={{pt: '6%', pb: '1.8%'}}>
                                <strong>Subcategorías</strong>
                                </Typography>
                            </Grid>
                            <Grid item
                                xs={12}
                                sx={{display: 'flex'}}
                            >
                                <Box
                                    sx={{
                                        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 1px 1px 4px rgba(0, 0, 0, 0.15)',
                                        borderRadius: '4px',
                                        minHeight: '6.25rem',
                                        px: '1.8rem',
                                        width: '100%',
                                        backgroundColor: 'white'
                                    }}
                                >
                                    <Grid container 
                                        sx={{ height: '100%' }}
                                    >
                                        <Droppable droppableId='1' direction='horizontal' isDropDisabled={true}>
                                            {(provided) => (
                                                <Grid item container spacing={1} xs={10} sx={{overflowY: 'auto', maxHeight: '9.25rem', mt: 0, pt: '1.375rem', pb: '1.875rem'}} ref={provided.innerRef} {...provided.droppableProps}>
                                                    {subcategories?.map((subcategory, index) => (
                                                        <Draggable
                                                            key={subcategory._id}
                                                            draggableId={subcategory._id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <>
                                                                    <Grid item 
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            ...provided.draggableProps.style,
                                                                            paddingRight: '12px',
                                                                            transform: snapshot.isDragging
                                                                            ? provided.draggableProps.style?.transform
                                                                            : 'translate(0px, 0px)',
                                                                        }}
                                                                    >
                                                                        <Box sx={{ backgroundColor:'#FC581F', borderRadius: '4px', color: 'white', position: 'relative'}}
                                                                        onDoubleClick={() => updateField(false, index, 'editable', true)}>
                                                                            <CancelIcon
                                                                                sx={{
                                                                                    position: 'absolute',
                                                                                    p: 0,
                                                                                    top: '-.5rem',
                                                                                    right: '-.5rem',
                                                                                    marginLeft: 'auto',
                                                                                    color: '#2C2C2C', 
                                                                                    fontSize: '1.2rem',
                                                                                    opacity: '0.8',
                                                                                    '&:hover': {
                                                                                        opacity: '1',
                                                                                        color: 'black'
                                                                                    }
                                                                                }}
                                                                                onClick={() => onDeleteModal(subcategory, 'subcategory')}
                                                                            />
                                                                            {subcategory.editable 
                                                                                ? <FormControl variant="standard" fullWidth>
                                                                                    <TextField autoFocus inputProps={{style:{fontSize: '13px', color: 'white'}}}
                                                                                        size='small'
                                                                                        name='categoryName' 
                                                                                        defaultValue={subcategory.subCategoryName}
                                                                                        onKeyDown={event => handleKeyDown({ isCategories: false, index, value: event.target.value, key: event.key })}
                                                                                        onBlur={event => handleKeyDown({ isCategories: false, index, value: event.target.value, key: 'Enter' })}
                                                                                    />
                                                                                </FormControl>
                                                                                : <Typography variant='h6' component='h5' sx={{fontSize: '13px', padding: '0.6rem'}}>
                                                                                    {subcategory.subCategoryName}
                                                                                </Typography>
                                                                            }
                                                                        </Box>
                                                                    </Grid>
                                                                    {snapshot.isDragging && 
                                                                        <Grid item 
                                                                            style={{ transform: 'none !important', paddingRight: '12px' }}
                                                                        >
                                                                            <Box sx={{ backgroundColor:'#FC581F', borderRadius: '4px', color: 'white', position: 'relative'}}>
                                                                                <CancelIcon
                                                                                    sx={{
                                                                                        position: 'absolute',
                                                                                        p: 0,
                                                                                        top: '-.5rem',
                                                                                        right: '-.5rem',
                                                                                        marginLeft: 'auto',
                                                                                        color: '#2C2C2C', 
                                                                                        fontSize: '1.2rem',
                                                                                        opacity: '0.8'
                                                                                    }}
                                                                                />
                                                                                <Typography variant='h6' component='h5' sx={{fontSize: '13px', padding: '0.6rem'}}>
                                                                                    {subcategory.subCategoryName}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </Grid>
                                            )}
                                        </Droppable>
                                        <Grid item xs={2} sx={{ display: 'flex', alignItems: "center"}} >
                                            <Button sx={{ 
                                                typography: 'font-family: "Inter", sans-serif;',
                                                color: 'black',
                                                backgroundColor: 'inherit',
                                                borderRadius: '4px',
                                                textTransform: 'none',
                                                '&:hover': { backgroundColor: 'inherit'}
                                                }} variant="contained" startIcon={<AddIcon sx={{color: '#FC581F'}} />}
                                                onClick={addSubCategory}
                                                >
                                                    <strong>
                                                        Agregar Subcategoría
                                                    </strong>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider variant="middle" sx={{mx: 0, my: '6%'}}/>
                        
                        <Grid container sx={{pb: 3}}>
                            <Grid item xs={12} sx={{mb: '1.8%'}}>
                                <Typography variant='h7' component='h2'>
                                <strong>Categorías principales</strong>
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} spacing={4} sx={{pb: '3.6%', mt: 0, maxHeight: '34.56rem', overflowY: 'auto' }}>
                                { categories?.map((category, index) => (
                                    <Grid item xs={4} key={category._id}>
                                        <Box
                                            sx={{
                                                boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 1px 1px 4px rgba(0, 0, 0, 0.15)',
                                                borderRadius: '4px',
                                                minHeight: '13.75rem',
                                                width: '100%',
                                                backgroundColor: 'white'
                                            }}
                                        >   
                                            <Grid container>
                                                <Grid item container xs={12} sx={{ px: '1.2%', pt: '1.2%'}}>
                                                    <Grid item xs={11} onDoubleClick={() => !defaultCategories.includes(category.categoryName) && updateField(true, index, 'editable', true)}>
                                                        {category.editable 
                                                        ? <FormControl variant="standard" fullWidth>
                                                            <TextField autoFocus inputProps={{style:{fontSize: '13px'}}}
                                                                size='small'
                                                                name='categoryName' 
                                                                defaultValue={category.categoryName}
                                                                onKeyDown={event => handleKeyDown({ isCategories: true, index, value: event.target.value, key: event.key })}
																onBlur={event => handleKeyDown({ isCategories: true, index, value: event.target.value, key: 'Enter' })}
                                                            />
                                                          </FormControl>
                                                        : <Typography variant='h7' component='h2' sx={{fontSize: '13px', color: '#A1A1A1'}}>
                                                            <strong>{category.categoryName}</strong>
                                                        </Typography>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={1} sx={{display: 'flex'}} justifyContent="flex-end" alignItems="center">
                                                        <CancelIcon
                                                            sx={{
                                                                p: 0,
                                                                color: '#FC581F',
                                                                opacity: defaultCategories.includes(category.categoryName) ? 0.5 : 1, 
                                                                fontSize: '1.3rem'
                                                            }}
                                                            
                                                            onClick={() =>  !defaultCategories.includes(category.categoryName) && onDeleteModal(category, 'category')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider variant="middle" sx={{mx: 0, my: '1%'}}/>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container xs={12} >
                                                    <Droppable droppableId={category._id} direction='horizontal' >
                                                        {(provided, snapshot) => (
                                                            <Grid item container spacing={1} ref={provided.innerRef} {...provided.droppableProps}
                                                                style={{ 
                                                                    ...provided.droppableProps.style,
                                                                    backgroundColor: snapshot.isDraggingOver
                                                                        ? 'whitesmoke'
                                                                        : 'inherit'
                                                                }}
                                                                sx={{
                                                                    mx: '.3rem',
                                                                    mt: '.3rem',
                                                                    pb: '.5rem',
                                                                    minHeight: '11rem',  
                                                                    maxHeight: '11rem',
                                                                    overflow: 'auto', flexWrap:'wrap'
                                                                }}
                                                            >
                                                                {!!category?.subcategoriesId?.length && getSubcategories(category.subcategoriesId).map(({_id, subcategory }, index) => (
                                                                    <Draggable
                                                                        key={_id}
                                                                        draggableId={_id}
                                                                        index={index}
                                                                    >
                                                                        {(provided) => (
                                                                            <>
                                                                                <Grid item 
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={{
                                                                                        ...provided.draggableProps.style,
                                                                                        paddingRight: '12px',
                                                                                    }}
                                                                                >
                                                                                    <Box sx={{ backgroundColor:'#FC581F', borderRadius: '4px', color: 'white', position: 'relative'}}>
                                                                                        <CancelIcon
                                                                                            sx={{
                                                                                                position: 'absolute',
                                                                                                p: 0,
                                                                                                top: '-.5rem',
                                                                                                right: '-.5rem',
                                                                                                marginLeft: 'auto',
                                                                                                color: '#2C2C2C', 
                                                                                                fontSize: '1.2rem',
                                                                                                opacity: '0.8',
                                                                                                '&:hover': {
                                                                                                    opacity: '1',
                                                                                                    color: 'black'
                                                                                                }
                                                                                            }}
                                                                                            onClick={() => onDeleteModal({_id, subcategory, category}, 'subByCategory')}
                                                                                        />
                                                                                        <Typography variant='h6' component='h5' sx={{fontSize: '13px', padding: '0.6rem'}}>
                                                                                            {subcategory.subCategoryName}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </>
                                                                        )}
                                                                    </Draggable>

                                                                ))
                                                                }
                                                                {provided.placeholder}   
                                                            </Grid>
                                                        )}
                                                    </Droppable>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}
                                <Grid item xs={4} sx={{display: 'flex'}}>
                                    <Box
                                        sx={{
                                            boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.1), 1px 1px 4px rgba(0, 0, 0, 0.15)',
                                            borderRadius: '4px',
                                            minHeight: '13.75rem',
                                            width: '100%',
                                            backgroundColor: 'white'
                                        }}
                                    >   
                                        <Grid container sx={{height: '100%', display: 'flex'}}>
                                            <Grid item xs={12} sx={{ px: '1.2%', pt: '1.2%'}}>
                                                <Typography variant='h7' component='h2' sx={{fontSize: '13px', color: '#A1A1A1'}}>
                                                    <strong>NUEVA CATEGORÍA</strong>
                                                </Typography>
                                                <Divider variant="middle" sx={{mx: 0, my: '1%'}}/>
                                            </Grid>
                                            <Grid item xs={12} sx={{display: 'flex' , height:'100%'}} justifyContent="center" alignItems="center" onClick={addCategory}>
                                                <Typography variant="button" component={Button} startIcon={<AddIcon  sx={{color: '#FC581F'}}/>} sx={{fontSize: '16px', color: 'black', textTransform: 'none'}}>
                                                    <strong>
                                                        Agregar Categoría
                                                    </strong>
                                                </Typography> 
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={11}/>
                            <Grid item xs={1} sx={{display: 'flex', pt: '1rem'}} justifyContent="flex-end">
                                <Button
                                    fullWidth
                                    sx={{ 
                                        backgroundColor: '#FC581F',
                                        borderRadius: '4px',
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: '#FC581F'}
                                    }} 
                                    variant="contained"
                                    onClick={onSave}
                                >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </DragDropContext>
                <DeleteModal title={deleteTitle} confirmButtonText={'Eliminar'} onConfirmed={onConfirmed} openModal={openModal} onCloseModal={onCloseModal}/>
            </CssBaseline>
        </>
    )
}

export default CategoriesPage