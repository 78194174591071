import { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';

const AutocompleteProductType = ({options, selectedValue, onOptionChange, onSearchTermChange, isLoading=false}) => {
  const [debounceTimer, setDebounceTimer] = useState(null);

  const onAutocompleteChange = (evt, newValue) => {
    const event = {
      target: {
        name: 'productType',
        value: newValue
      }
    }
    onOptionChange(event);

    if(!newValue){
      onSearchTermChange(newValue);
    }
    
  };
  
  const onInputChange = (evt) => {
    const newValue = evt.target.value; 

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newDebounceTimer = setTimeout(() => { 
      onSearchTermChange(newValue);
    }, 300);

    setDebounceTimer(newDebounceTimer);
  };  

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      autoComplete={false}
      disablePortal
      name={'productType'}
      value={selectedValue}
      blurOnSelect
      options={options}
      loading={isLoading}
      loadingText={'buscando...'}
      noOptionsText="sin resultados."
      onChange={onAutocompleteChange}
      renderInput={(params) => (
        <TextField
          fullWidth
          sx={{
            "& .MuiInputBase-input": {
              padding: '0 !important',
            },
            "& .MuiInputBase-root": {
              padding: '0.625rem 0.75rem 0.625rem 1rem',
            },
          }}
          {...params}
          InputProps={{ ...params.InputProps }}
          onChange={onInputChange}
          placeholder="Elige el tipo de producto"
        />
      )}
    />
  );
};

export default AutocompleteProductType;