import { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { login } from '../../services/userService';
import { UserDispatchContext } from '../../context/UserContext';
import { useSnackbar } from 'notistack';

  
export const LoginForm = () => {
    const navigate = useNavigate();
    const globalUserDispatchContext = useContext(UserDispatchContext);
    const { enqueueSnackbar} = useSnackbar();

    const defaultErrorValues = {
        userNameError: false,
        passwordError: false
    };

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const [errors, setErrors] = useState(defaultErrorValues);
    const [userForm, setUserForm] = useState({
        userName: '',
        password: ''
    });
    const { userName, password } = userForm;
    const { userNameError, passwordError } = errors;
    const updateUserForm = (e) => {
        let inputName = e.target.name;
        let value =  e.target.value;
        setUserForm({
            ...userForm,
            [inputName]: value
        });
        (!userName || !password) && setErrors(defaultErrorValues);
    }
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const OnSubmitForm = async (evt) => {
        evt.preventDefault();
        const result = await login(userName, password);
        
        if(result.AccessToken){
            globalUserDispatchContext({
                type: 'LOG_IN',
                AccessToken: result.AccessToken,
                RefreshToken: result.RefreshToken,
                uid: result.uid
            });
            navigate('/admin/products');
        }else{
            setErrors({
                userNameError: true,
                passwordError: true
            });
            setUserForm({
                ...userForm,
                password: ''
            });
            enqueueSnackbar("Usuario y/o contraseña incorrecta.", {
                variant: 'error',
                autoHideDuration: 3000
            });
        }
    }


  return (
    <div className='mt-6 xl:mt-8 w-full'>
        <form onSubmit={OnSubmitForm}>
            <div className='mb-2 xl:mb-4'>
                <TextField 
                    sx={{
                        '& .MuiOutlinedInput-root':{
                            borderRadius: '36px',
                            height: '47px',
                            color: 'white',
                            "&.Mui-focused fieldset": {
                                borderColor: 'white'
                            }
                        },
                        '& .MuiOutlinedInput-root fieldset':{
                            borderColor: '#ffffff8c',
                            borderWidth: '2px',
                            height: '52px'
                        },
                        '& .MuiOutlinedInput-input':{
                            background: 'inherit',
                            height: '28px',
                            textAlign: 'center',
                            autoComplete: 'off'
                        }
                    }}
                    error={userNameError}
                    id="outlined-basic" 
                    placeholder='Usuario'
                    fullWidth
                    variant="outlined"
                    size="small"
                    name={"userName"}
                    value={userName}
                    onChange={updateUserForm}
                />
            </div>
            <FormControl 
                sx={{
                    '& .MuiOutlinedInput-root':{
                        borderRadius: '36px',
                        height: '47px',
                        color: 'white',
                        "&.Mui-focused fieldset": {
                            borderColor: 'white'
                        }
                    },
                    '& .MuiOutlinedInput-root fieldset':{
                        borderColor: '#ffffff8c',
                        borderWidth: '2px',
                        height: '52px',
                    },
                    '& .MuiOutlinedInput-input':{
                        background: 'inherit',
                        height: '28px',
                        textAlign: 'center',
                        autoComplete: "off",
                        paddingLeft: '42px',
                    },
                    '& .MuiInputAdornment-root':{
                        marginLeft: 0
                    },
                    '& .MuiSvgIcon-root':{
                        color: 'white'
                    },
                    m: 1, width: '100%', margin: 0
                }}
                variant="outlined">
                <OutlinedInput
                    fullWidth
                    size="small"
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    name={"password"}
                    value={password}
                    placeholder='Contraseña'
                    onChange={updateUserForm}
                    error={passwordError}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    }
                />
            </FormControl>
            <button type='submit'className='w-full h-12 mt-4 xl:mt-6 bg-admin-primary text-white text-base font-bold font-inter rounded-full'>Ingresar</button>
        </form>
    </div>
  )
}


