import moment from 'moment';
require('moment/locale/es');

export function truncateString(str, limit) {
    limit = limit-3;
    if (str.length > limit) {
        return str.substring(0, limit) + '...';
    }
    return str;
}

export function truncateDecimal(number) {
  const numberString = number.toString();
  const decimalPointIndex = numberString.indexOf('.');
  if (decimalPointIndex !== -1 && numberString.length - decimalPointIndex > 2) {
    return parseFloat(numberString.slice(0, decimalPointIndex + 3)).toFixed(2);
  }
  return number.toFixed(2);
}

export const formatNumberToCurrency = (number) => {
  const numberDecimal = truncateDecimal(number);
  return `$ ${numberDecimal}`;
  
};

export const currencyFormat = (number) => {
  const numberWithCommas = Number(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formattedNumber = `$ ${numberWithCommas}`;
  return formattedNumber;
};

export const formatDate = (date, dateFormat = 'DD/MM/YY') => {
  moment.locale('es');
  return moment(date).format(dateFormat);
};


export function normalizeText(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
};

export const getCode = (text) => {
  const cleanedChars = text.replace(/[^a-zA-Z0-9ñÑ]/g, '')
  const slicedChars = normalizeText(cleanedChars.slice(0, 2)).trim().toUpperCase();
  const duplicatedChars = slicedChars.length < 2 ? slicedChars + slicedChars[0] : slicedChars;

  return duplicatedChars;
}

export const nextBase36Consecutive = (values) => {
  if (values.length === 0) {
    return '1';
  }

  const base36Integers = values.map(value => parseInt(value, 36));
  const max = Math.max(...base36Integers);
  const nextConsecutive = (max + 1).toString(36).toUpperCase();

  return nextConsecutive;
}

export const getSku = (characteristics) => {
  return characteristics
  .filter(characteristic => characteristic.code)
  .map(characteristic => characteristic.code)
  .join('-');
}

export function buildTree(products) {
    let mergedOptions = [];

    for (let i = 0; i < products.length; i++) {
        const product = products[i];
        mergedOptions = mergeOptions(mergedOptions, product, 0);
    }

    return mergedOptions;
}

function mergeOptions(existingOptions, product, index) {
    if (index >= product.length) {
        return existingOptions;
    }

    const option = product[index];
    const optionName = option.characteristicName
    const optionValue = option.value
    
    const existingOption = findOption(
      existingOptions,
      optionName,
      optionValue,
    );

    if (existingOption) {
        existingOption.children = mergeOptions(existingOption.children || [], product, index + 1);
    } else {
        const newOption = { 
          ...option,
          children: mergeOptions([], product, index + 1),
        };
        existingOptions.push(newOption);
    }

    return existingOptions;
}

function findOption(options, name, value) {
    for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (option.characteristicName === name && value === option.value) {
            return option;
        }
    }
    return null;
}

export function getElementByPath(path, processedJson, originalJson) {
    const targetCharacteristics = [];
  
    for (let i = 0; i < path.length; i++) {
      const index = path[i];
      
  
      if (processedJson && processedJson.length > index) {
        const currentNode = processedJson[index];
        targetCharacteristics.push({
          characteristicName: currentNode.characteristicName,
          value: currentNode.value,
        });
        processedJson = currentNode.children;
      } else {
        console.error(`Invalid path at index ${i}. Path does not exist.`);
        return null;
      }
    }
  
    const elementInOriginalJson = findElementByCharacteristics(originalJson, targetCharacteristics);
  
    return elementInOriginalJson;
}
  
export function findElementByCharacteristics(json, targetCharacteristics) {
    for (let i = 0; i < json.length; i++) {
      const product = json[i].characteristics;
  
      if (areCharacteristicsEqual(product, targetCharacteristics)) {
        return json[i];
      }
    }
    return null;
}
  
function areCharacteristicsEqual(productCharacteristics, targetCharacteristics) {
    if (productCharacteristics.length !== targetCharacteristics.length) {
      return false;
    }
  
    for (let i = 0; i < productCharacteristics.length; i++) {
      const productCharacteristic = productCharacteristics[i];
      const targetCharacteristic = targetCharacteristics[i];
  
      if (
        productCharacteristic.characteristicName !== targetCharacteristic.characteristicName ||
        productCharacteristic.value !== targetCharacteristic.value
      ) {
        return false;
      }
    }
  
    return true;
}

export function sumExtraPrice(json) {
  let totalExtraPrice = 0;

  if (json && json.characteristics && Array.isArray(json.characteristics)) {
    json.characteristics.forEach((characteristic) => {
      const extraPrice = parseFloat(characteristic.extraPrice);
      if (!isNaN(extraPrice)) {
        totalExtraPrice += extraPrice;
      }
    });
  }

  return totalExtraPrice;
}

function isValidValue(attribute, value){
  if(attribute == 'Stock'){
    return /^-?\d+$/.test(value);
  } else if (attribute == 'Tiempo de entrega'){
    return /^\d*$/.test(value);
  }
  return !!value
}

function validateInitialAttributes(row, line) {
  const requiredAttributes = ["Nombre del Producto", "Descripción del Producto", "Precio Base del Producto", "Stock", "Tiempo de entrega"];
  requiredAttributes.forEach(attribute => {
    if (!isValidValue(attribute,row[attribute])) {
      throw new Error(`Atributo invalido - ${attribute} - Linea: ${line}`);
    }
  });
}

function processVariantRow(row, line) {
  const productVariant = {
    characteristics: [],
    stock: parseInt(row["Stock"]),
    extraDeliveryTime: parseInt(row["Tiempo de entrega"]),
    longitude: row["Largo"] ? truncateDecimal(parseFloat(row["Largo"])) : 0,
    width: row["Ancho"] ?  truncateDecimal(parseFloat(row["Ancho"])) : 0,
    height: row["Alto"] ? truncateDecimal(parseFloat(row["Alto"])) : 0,
    weight: row["Peso"] ? truncateDecimal(parseFloat(row["Peso"])) : 0,
    createdAt: moment().format(),
  };

  let number = 0;
  const columns = Object.keys(row).length;

  for (let i = 10; i < columns; i += 3) {
    number++;

    const characteristic = row[`Característica ${number}`];
    const value = row[`Valor ${number}`];
    const priceExtra = row[`Precio Extra ${number}`];

    if (characteristic && value && (priceExtra >= 0)) {
      productVariant.characteristics.push({
        characteristicName: characteristic,
        value: value,
        extraPrice: priceExtra ? parseFloat(priceExtra) : 0,
        code: ''
      });
    } else {
      const triplet = {
        [`Característica ${number}`]: characteristic,
        [`Valor ${number}`]: value,
        [`Precio Extra ${number}`]: priceExtra,
      };

      if (Object.keys(triplet).some(item => !!triplet[item])) {
        Object.keys(triplet).forEach((item,key) => {
          if (triplet[item] == null || !triplet[item].toString() || (item === `Precio Extra ${number}` && (!Number(triplet[item]) || triplet[item] < 0) ) ) {
            throw new Error(`Atributo invalido - ${item} - Linea: ${line}`);
          }
        });
      }
    }

  }

  return productVariant;
}

export function parseCSV(result) {
  let line = 1;

  const products = result.data.reduce((acc, row) => {
    line++;
    validateInitialAttributes(row, line);

    const productName = row["Nombre del Producto"];
    const productVariant = processVariantRow(row, line);

    if (!acc[productName]) {
      acc[productName] = {
        productName: productName,
        description: row["Descripción del Producto"],
        price: parseFloat(row["Precio Base del Producto"]),
        longitude: row["Largo"] ? truncateDecimal(parseFloat(row["Largo"])) : 0,
        width: row["Ancho"] ?  truncateDecimal(parseFloat(row["Ancho"])) : 0,
        height: row["Alto"] ? truncateDecimal(parseFloat(row["Alto"])) : 0,
        weight: row["Peso"] ? truncateDecimal(parseFloat(row["Peso"])) : 0,
        ownBox: row["Caja Propia"] ? row["Caja Propia"] : false,
        woodenBox: row["Caja de Madera"] ? row["Caja de Madera"] : false,
        productVariants: [],
        stock: !productVariant.characteristics.length ? parseInt(row["Stock"]) : 0,
        extraDeliveryTime: !productVariant.characteristics.length ? parseInt(row["Tiempo de entrega"]) : 0

      };
    }
    if(!!productVariant.characteristics.length){
      acc[productName].productVariants.push(productVariant);
    }

    return acc;
  }, {});

  return Object.values(products);
}

export function validateProductAttributeCsv(csvArray) {  
  
  function isValidTripletName(name) {
    return /^Característica \d+$|^Valor \d+$|^Precio Extra \d+$/.test(name.trim());
  }

  const initialAttributes = ["Nombre del Producto", "Descripción del Producto", "Precio Base del Producto", "Largo", "Ancho", "Alto", "Peso", "Caja Propia", "Caja de Madera", "Stock", "Tiempo de entrega"];
  const attributes = csvArray;

  for (let i = 0; i < initialAttributes.length; i++) {
    if (attributes[i] !== initialAttributes[i]) {
      return { attribute: attributes[i], index: i+1, msj: 'Atributo mal escrito' };
    }
  }

  if ((attributes.length - initialAttributes.length) % 3 !== 0) {
    let isValid = true;
    let response = null;
    for (let i = initialAttributes.length; i < attributes.length - 1; i += 3) { 
      const triplet = attributes.slice(i, i + 3);
      triplet.forEach((item, idx) => {
        if(item.includes("\"_")){
          isValid = false;
          response = { attribute: item.split('\"_')[0], index: i+idx, msj: 'Atributo repetido' };
          return;
        }
      });
      if(!isValid){
        return response;
      }
    }
    return { attribute: attributes[attributes.length - 1], index: attributes.length - 1, msj: 'El número de atributos no coincide' };
  }

  let isValid = true;
  let response = null;
  let acc = 0;
  for (let i = initialAttributes.length; i < attributes.length - 1; i += 3) {
      acc++
      const triplet = attributes.slice(i, i + 3);
      triplet.forEach((item, idx) => {
        if(item.includes("\"_")){
          isValid = false;
          response = { attribute: item.split('\"_')[0], index: i+idx, msj: 'Atributo repetido' };
          return;
        }
        
        if (!isValidTripletName(item)) {
          isValid = false;
          response = { attribute: item, index: i+idx, msj: 'Atributo mal escrito' };
          return;
        }

        let splitItem = item.split(' ');
        if(!(Number(splitItem[splitItem.length-1]) == acc)){
          isValid = false;
          response = { attribute: item, index: i+idx, msj: 'Secuencia numérica incorrecta' };
          return;
        }
      });
      if(!isValid){
        return response;
      }
  }
   
  return { attribute: true, index: -1, msj: ""};
}

export function getVariantCharacteristicNames(characteristics) {
  return characteristics.map(item => `${item.characteristicName}/${item.value}`).join(', ');
}