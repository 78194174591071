import { useState, useEffect } from 'react'
import DataTable from '../components/DataTable'
import { getProductsList } from '../services/productService'
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import { productsDelete } from '../services/productService';

import DownloadCSVButton from '../components/DownloadCSVButton';
import { useSnackbar } from 'notistack';
import UploadCSVButton from '../components/UploadCSVButton';
import SearchBar from '../components/SearchBar';
import TitleText from '../components/TitleText';
import { productCsv } from '../utils/csvConfig';


const ProductPage = () => {

  const [ searchTerm, setSearchTerm ] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [ tableData, setTableData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ LastEvaluatedKey, setLastEvaluatedKey ] = useState({});
  

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    getTableData();
  }, [searchTerm, page, rowsPerPage]);
  
  const getTableData = async() => {
    setIsLoading(true);
    let { items, totalPages } = await getProductsList(
      searchTerm,
      page,
      rowsPerPage,
      LastEvaluatedKey
    );

    setTableData(items);
    setTotalPages(totalPages);
    setLastEvaluatedKey(LastEvaluatedKey);
    setIsLoading(false);
  };

  const onEdit = async(item) => {
    navigate('/admin/products/form', {state:{ productToEdit: item}})
  };

  const onVariants = async(item) => {
    navigate('/admin/products/variantsForm', {state:{ product: item}})
  };

  const onDelete = async(item) => {
      const deleteResult = await productsDelete(item._id);
      if (deleteResult.result){
        enqueueSnackbar("Eliminación exitosa", {
          variant: 'success'
        });
        await getTableData();
      } else{
        enqueueSnackbar("Ocurrio un error al eliminar", {
          variant: 'error'
        });
      }
      
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const handleChangeSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
  };

  return (
    <Box sx={{px:{xs:'1.5rem', md:'3rem'}}} >
      <TitleText title={'PRODUCTOS'}/>
      <Box sx={{display:'flex', gap:{xs:'1rem', md:'1.5rem', lg:'2rem'}, pb: {xs:'1.5rem', md:'2rem', lg:'2.13rem'} }}>
        <Box sx={{display:'flex', flexGrow: 1 }}>
          <SearchBar onSearchTermChange={handleChangeSearchTerm} placeHolder={"Buscar Producto"} />
        </Box>
        <Box sx={{ display:'flex', gap:{xs:'0.75rem', lg:'1rem'} }}>
          <DownloadCSVButton customSx={{ display:{ xs:'none', md:'inline-flex' } }} data={productCsv} fileName={'plantillaProductos.csv'}/>
          <UploadCSVButton customSx={{ display:{ xs:'none', md:'inline-flex' } }} reloadTableData={ getTableData }/>
          <Button 
            sx={{ 
              fontSize: '0.8125rem',
              fontWeight:'700',
              background: '#FFF',
              color: '#FC581F',
              textTransform:'none',
              minWidth: {xs: 0,lg: '64px'},
              p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
              borderRadius:'62.5rem',
              boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
              '& .MuiButton-startIcon':{
                marginRight: {xs:'0', lg: '8px'},
                marginLeft: {xs:'0', lg: '0px'},
              },
              '&:hover': { 
                backgroundColor: '#FAFAFA'
              }
            }}
            variant="contained" 
            startIcon={<AddIcon sx={{fontSize:'1.5rem !important'}} />}
            onClick={() => {navigate('/admin/products/form')}}
          >
            <Typography sx={{ display:{xs:'none', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700', }}>
              Agregar producto
            </Typography>
          </Button>
        </Box>
      </Box>
      <DataTable 
        tableData={tableData}
        page={page}
        rowsPerPage={rowsPerPage}
        totalPages={totalPages}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onEdit={onEdit} 
        onDelete={onDelete} 
        onVariants={onVariants} 
        isLoading={isLoading} 
      />
    </Box>
  )
}

export default ProductPage;