import { useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';

const AutocompleteSearch = ({options, selectedCharacteristics, onOptionChange, onSearchTermChange, isDisabled}) => {
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [selectValue, setselectValue] = useState(null)

  const onAutocompleteChange = (evt, newValue) => {
    if(!!newValue){
      onOptionChange(newValue);
    }
  };
  
  const onInputChange = (evt) => {
    const newValue = evt.target.value;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newDebounceTimer = setTimeout(() => { 
      onSearchTermChange(newValue);
    }, 300);

    setDebounceTimer(newDebounceTimer);
  };  

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      disablePortal
      disabled={isDisabled}
      value={selectValue}
      blurOnSelect
      isOptionEqualToValue={(option, value) => option.characteristicName === value.characteristicName}
      getOptionLabel={(option) => option.characteristicName}
      options={options}
      filterOptions={(options) =>
        options.filter(
          (option) =>
            !selectedCharacteristics
            .some( (item) => item.characteristicName === option.characteristicName )
        )
        .slice(0,10)
      }
      noOptionsText="sin resultados."
      onChange={onAutocompleteChange}
      onBlur={ () => { onInputChange({target:{value:''}}) } }
      renderInput={(params) => (
        <TextField
          fullWidth
          sx={{
            "& .MuiInputBase-input": {
              padding: '0 !important',
            },
            "& .MuiInputBase-root": {
              padding: '0.625rem 0.75rem 0.625rem 1rem',
            },
          }}
          {...params}
          InputProps={{ ...params.InputProps }}
          onChange={onInputChange}
          placeholder="Selecciona del listado de variantes"
        />
      )}
    />
  );
};

export default AutocompleteSearch;