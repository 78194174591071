import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import TableCell  from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { getVariantCharacteristicNames } from '../utils/utils';
import EditStock from './EditStock';

const tableStyle = {
  headerCell: {
    color: '#A1A1A1', 
    borderBottom: '0', 
    padding: '0.5rem 0.5rem',
    fontSize:'0.8125rem',
    width:'20%'
  },
  headerRow:{
    borderBottom:'1px solid #A1A1A1'
  },
  bodyCell:{
    padding: '1rem 0.5rem',
    width: 'calc( 100% / 3)',
    borderBottom: '1px solid #F8F8F8',
    maxWidth: 0
  },
  preventOverflow: {
    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
  },
  twoLinesOverflow: {
    fontSize: '0.875rem', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', color:'#303030'
  },
  oneLineOverflow: {
    fontSize: '0.75rem', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden', color:'#303030'
  },
  pagination:{
    fontSize: '0.875rem',
    color: '#303030'
  },
  iconPagination: {
    padding: 0,
    color:'#303030'
  },
  SelectOptions:{
    fontSize: {xs:'0.875rem', md:'1rem'}
  },
  pageNumber: {
    padding: {xs:'0.469rem 3.217rem', md: '0.688rem 1.155rem'},
    borderRadius: '0.25rem',
    border: '1px solid #D9D9D9',
    background: '#FFFEFC',
    fontSize:'1rem'
  }
};

export default function DataTableProductVariant({
  tableData = [],
  onEdit, 
  page, 
  rowsPerPage,
  totalPages,
  onPageChange,
  onRowsPerPageChange,
  isLoading
}) {

  const [rows, setRows] = useState([]);
  
  useEffect( () => {
    setRows(tableData);
  },[tableData]);

  const handleBackButton = () => {
    onPageChange(page - 1);
  };

  const handleNextButton = () => {
    onPageChange(page + 1);
  };

  const handleRowsPerPageChange = (evt) => {
    onRowsPerPageChange(evt.target.value);
  };

  const handleEditStock = (itemToEdit, newStock) => {
    onEdit({variantId: itemToEdit._id, productId: itemToEdit.productId, newStock});
  }

  const setMaxText = (text, max) => {
    if(!text)
      return ''
    if(text.length > max)
      text = text.substr(0, max) + '...'
    return text
  }

  return (
    <>
      <TableContainer sx={{display:{xs:'none', sm:'block'} }} component={Paper}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow sx={tableStyle.headerRow}>
              <TableCell sx={{...tableStyle.headerCell, width: '25%'}} align="center">PRODUCTO BASE</TableCell>
              <TableCell sx={{...tableStyle.headerCell, width: '25%'}} align="center">VARIANTE</TableCell>
              <TableCell sx={{...tableStyle.headerCell, width: '25%'}} align="center">ID ÚNICO</TableCell>
              <TableCell sx={{...tableStyle.headerCell, width: '25%'}} align="center">STOCK</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {(rows
            ).map((row, index) => (
              <TableRow hover key={index}>
                <TableCell sx={{...tableStyle.bodyCell, width: '28%'}} align="center">
                  <Tooltip title={row.productName} arrow>
                    <Typography sx={{color:'#2C2C2C', fontSize:'0.875rem', fontWeight:'600', ...tableStyle.twoLinesOverflow }}>
                      {setMaxText(row.productName, 40)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{...tableStyle.bodyCell, width: '28%'}} align="center">
                  <Tooltip title={getVariantCharacteristicNames(row.characteristics)} arrow>
                    <Typography sx={{color:'#2C2C2C', fontSize:'0.875rem', fontWeight:'600', ...tableStyle.twoLinesOverflow }}>
                      {setMaxText(getVariantCharacteristicNames(row.characteristics), 40)}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{...tableStyle.bodyCell, width: '28%'}} align="center">
                  <Typography sx={tableStyle.twoLinesOverflow}>
                    {row.sku}
                  </Typography>
                </TableCell>
                <TableCell sx={{...tableStyle.bodyCell, width: '16%'}} align="center">
                    <EditStock item={row} suffix={'pcs.'} onEditStock={handleEditStock}/>
                </TableCell>
              </TableRow>
            ))}

            {rows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell colSpan={6} align='center' >
                  SIN DATOS
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{display:{xs:'flex', sm:'none'}, flexDirection: 'column', gap:{xs:'0.75rem'} }}>
        {(rows).map( (row, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              padding: '0.75rem 1.5rem',
              alignItems: 'center',
              height:'100%',
              alignItems: 'center',
              alignSelf: 'stretch',
              borderRadius: '0.25rem',
              background: '#FFF',
              boxShadow: '2px 4px 10px 0px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Box sx={{display:'flex', width: '80%' ,flexDirection:'column'}}>
              <Typography sx={{...tableStyle.twoLinesOverflow, fontSize: '0.75rem', fontWeight:'700', height:'1.25rem'}}>
                {row.productName}
              </Typography>
              <Typography sx={{...tableStyle.twoLinesOverflow, fontSize: '0.75rem', fontWeight:'700', height:'1.25rem'}}>
                {setMaxText(getVariantCharacteristicNames(row.characteristics), 39)}
              </Typography>
              <Typography sx={tableStyle.oneLineOverflow}>
                ID ÚNICO: {row.sku}
              </Typography>
            </Box>
            <Box sx={{display:'flex', width: '20%'}}>
              <EditStock item={row} suffix={'pcs.'} onEditStock={handleEditStock}/>
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={{py:{xs:'1.5rem', md:'2rem'}, display:'flex', flexDirection: {xs:'column', sm: 'row'}, justifyContent:'space-between' }}>
        <Box>
          <Typography sx={{ display: {xs: 'block', sm: 'none' }, textAlign:'center', color: '#676565', pb:'1.2rem', fontSize: tableStyle.pagination.fontSize }}>{`Mostrando ${rows.length} resultados`}</Typography>
          <Select
            sx={{
              display:{xs:'none', sm:'inline-flex'},
              backgroundColor:'#FFFEFC', 
              fontSize: {xs:'0.875rem', md:'1rem'},
              '& .MuiSelect-select': {
                paddingRight: '0.75rem !important', 
              },
            }}
            IconComponent={() => <KeyboardArrowLeft sx={{transform: 'rotate(270deg)', mr: '0.75rem' }}/>}
            MenuProps={{
              MenuListProps:{
                fontSize: '5px'
              }
            }}
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            disabled={isLoading}
          >
            <MenuItem sx={tableStyle.SelectOptions} value={25}>25 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={50}>50 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={75}>75 resultados</MenuItem>
            <MenuItem sx={tableStyle.SelectOptions} value={100}>100 resultados</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display:'flex', alignItems:'center', gap:{xs:'0rem', sm:'0.5rem', md:'0.75rem'}, justifyContent:{xs: 'space-between', sm:'unset'} }}>
          <IconButton
            sx={tableStyle.iconPagination}
            onClick={handleBackButton}
            disabled={page === 1 || isLoading}
          >
            <KeyboardArrowLeft/>
          </IconButton>
          <Typography sx={tableStyle.pagination}>Página</Typography>
          <Typography sx={tableStyle.pageNumber}>{page}</Typography>
          <Typography sx={tableStyle.pagination}>{`de ${totalPages}`}</Typography>
          <IconButton
            sx={tableStyle.iconPagination}
            onClick={handleNextButton}
            disabled={page === totalPages || isLoading}
          >
            <KeyboardArrowRight/>
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
