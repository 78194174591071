import React from 'react'

 const UserPage = () => {
  return (
    <div className='flex items-center justify-center h-full '>
        <h1 className='text-center xl:text-9xl'>Usuarios</h1>
    </div>
  )
}

export default UserPage;