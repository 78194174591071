import axios from '../utils/axios';
import  { v4 as uuidv4 } from 'uuid';

const SERVICE_URL = '/products';

export const getProductsList = async (
    searchTerm,
    page,
    rowsPerPage,
    LastEvaluatedKey
) => {
    try {
        let response = await axios.post(
            `${SERVICE_URL}`
            , 
            { 
                searchTerm,
                page,
                rowsPerPage,
                LastEvaluatedKey
            }
        );
        return response.data;
    } catch (error) {
        return {
            items: [], 
            LastEvaluatedKey: {},
            totalPages: 1
        };
    }
}

export const getProductVariantsList = async (
    searchTerm,
    page,
    rowsPerPage,
    LastEvaluatedKey
) => {
    try {
        let response = await axios.post(
            `${SERVICE_URL}/variants`
            , 
            { 
                searchTerm,
                page,
                rowsPerPage,
                LastEvaluatedKey
            }
        );
        return response.data;
    } catch (error) {
        return {
            Items: [], 
            LastEvaluatedKey: {},
            totalPages: 1
        };
    }
}

export const getProductsById = async (
    _id
) => {

    try {
        let response = await axios.post( `${SERVICE_URL}/id` , { _id } );
        return response.data;
    } catch (error) {
        return {
            error
        };
    }
}

export const productsCreate = async (formData) => {

    try {
        let response = await axios.post(`${SERVICE_URL}/create`, formData );
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productsUpdate = async (formData) => {

    try {
        let response = await axios.put(`${SERVICE_URL}/update`, formData );
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productVarianStockUpdate = async (data) => {

    try {
        let response = await axios.put(`${SERVICE_URL}/variants/stock`, data );
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productVarianStockUpdateByCsv = async (items) => {
    return axios.post(`${SERVICE_URL}/variants/update-csv`, { items } )
}

export const productsDelete = async (_id) => {
    try {
        let response = await axios.delete(`${SERVICE_URL}/delete?id=${_id}`);
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productsGetImageUrl = async (extension) => {
    const id = uuidv4();
    try {
        let response = await axios.get(`${SERVICE_URL}/imagelink?name=${id}${extension}&type=image`);
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productsGetFileUrl = async (name) => {
    try {
        let response = await axios.get(`${SERVICE_URL}/imagelink?name=${name}&type=file`);
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productsUploadImage = async (image, imageUrl) => {
    try {
        await fetch(imageUrl, {
            method:'PUT',
            body: image,
            headers: {
                'Content-Type': `${image.type}`,
            }
        });
        return { result: true };
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productsGetImageUrlToDelete = async (url) => {
    try {
        let response = await axios.get(`${SERVICE_URL}/imagelinktodelete?imageId=${url}`);
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productsDeleteImage = async (url) => {
    try {
        await fetch(url, {
            method:'DELETE',
        });
        return { result: true };
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const productsUploadCsv = async (data) => {

    try {
        let response = await axios.post(`${SERVICE_URL}/uploadcsv`, data );
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}