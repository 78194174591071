import { Box, IconButton, TextField, Typography, InputAdornment } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react'
import MexicanFlagIcon from './icons/MexicanFlagIcon';
import { formatDate } from '../utils/utils';



const EditExchangeRate = ({exchangeValue, updatedAt, isDisabled, onEditValue}) => {
    const [value, setValue] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    useEffect( () => {
        setValue(exchangeValue);
        setIsEdit(false);
    },[exchangeValue]);

    const handleEdit = () => {
        if(value === '' || value == 0){
            return;
        }
        if(isEdit){
            let currencyValue = 0 + Number(value);
            const decimalDigits = currencyValue.toString().split('.')[1];
            if (!decimalDigits || decimalDigits.length < 2) {
                currencyValue = parseFloat(currencyValue).toFixed(2);
            }
            setValue(currencyValue);
            onEditValue(currencyValue);
        }
        setIsEdit(!isEdit);
    };

    const handleValueChange = (event) => {
        const newValue = event.target.value;

        if (!/^\d*\.?\d{0,2}$/.test(newValue)) {
            return;
        }

        setValue(newValue);
    };

    const styles = {
        stock: {
            display: 'flex',
            padding: {xs: '0.5rem 0.3rem', sm: '0.5rem 0.3rem 0.5rem 0.3rem'},
            alignItems: 'center',
            fontSize: '0.875rem', 
            color:'#929191',
            borderRadius: '0.25rem 0rem 0rem 0.25rem',
            border: '1px solid #929191',
            justifyContent: 'end',
            width: '100%',
        },
        button: {
            display: 'flex',
            padding: {xs:'0.5rem', md: '0.5rem 0.75rem'},
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0rem 0.25rem 0.25rem 0rem',
            background: isEdit ? '#00CA45': '#FC581F',
            color: 'white',
            '&:hover': { backgroundColor: isEdit ? '#00CA45': '#FC581F'},
            '&.Mui-disabled': {
                backgroundColor: 'lightgrey',
                color: 'darkgrey'
            }
        },
        icon: {
            fontSize:{xs: '1rem', lg: '1.25rem'}
        },
        textField: {
            "& .MuiInputBase-root": {
                borderRadius: '0.25rem 0rem 0rem 0.25rem',
                fontSize: '1rem',
                px: '1rem',
                py: '0.34rem'
            },
            "& .MuiInputBase-root input": {
                padding: {xs: '0.5rem 0.3rem !important', sm: '0.5rem 0.3rem 0.5rem 0.3rem !important'},
                textAlign: 'end'
            },
        }
    }

    return (
        <>
            <Typography sx={{ fontSize: '0.875rem', lineHeight: '1rem', color: '#929191', pb:'0.5rem' }}>PRECIO ACTUAL DEL DÓLAR</Typography>
            <Box sx={{display:'flex', justifyContent: 'center', width:'18.25rem'}}> 
                <TextField 
                    value={value}
                    size='small'
                    sx={styles.textField}
                    variant="outlined"
                    disabled={!isEdit}
                    fullWidth
                    InputProps={{
                        inputProps: {min: 0},
                        startAdornment: 
                            <InputAdornment position="start"> 
                                <Typography sx={{ fontSize: '1rem', lineHeight: 'inherit', color: '#929191'}}>1 USD =</Typography>
                            </InputAdornment>,
                        endAdornment: 
                            <InputAdornment position="end">
                                <MexicanFlagIcon/>
                                <Typography sx={{ fontSize: '1rem', lineHeight: 'inherit', color: '#929191', pl: '0.62rem'}}>MXN</Typography>
                            </InputAdornment>,
                    }}
                    onChange={handleValueChange}
                    onKeyDown={(e) => { if(e.key === "Enter") handleEdit(e) }}
                />
                <IconButton 
                    sx={styles.button} 
                    onClick={handleEdit}
                    disabled={isDisabled}
                >
                    { 
                        isEdit
                        ? <DoneIcon sx={styles.icon}/>
                        : <EditOutlinedIcon sx={styles.icon}/>
                    }
                </IconButton>
            </Box>
            <Typography sx={{ fontSize: '0.875rem', lineHeight: '1rem', color: '#929191', pt:'0.5rem' }}>{`Última actualización: ${!!updatedAt ? formatDate(updatedAt, 'DD/MM/YY HH:mm [hrs]' ) : ''} `}</Typography>
        </>
    )
}

export default EditExchangeRate