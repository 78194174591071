import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import moment from 'moment';
import  { v4 as uuidv4 } from 'uuid';

import { productsUpdate } from '../services/productService';
import { getCharacteristicsSearch } from '../services/characteristics';

import { Box, Typography, TextField, IconButton, Select, MenuItem, InputAdornment, Button, Grid } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import TitleText from '../components/TitleText';
import AutocompleteSearch from '../components/AutocompleteSearch';
import ClearIcon from '../components/icons/ClearIcon';
import { buildTree, normalizeText, findElementByCharacteristics } from '../utils/utils';
import DeleteModal from '../components/DeleteModal';
import ProductVariantResume from '../components/ProductVariantResume';
import ButtonStyled from '../components/ButtonStyled';

import { useSnackbar } from 'notistack';

const styles = {
  textList: {
    color: '#252323',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    pb: '1.5rem'
  },
  titleInput:{
    color: '#A1A1A1',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    pb:'0.625rem'
  },
  card:{
    borderRadius: '0.25rem',
    background: '#FFF',
    display: 'flex',
    padding: '1.5rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  input: {
    "& .MuiInputBase-root input": { padding:'0.625rem 1rem !important' },
    "& .MuiInputBase-input:disabled": {
      color: 'black !important',
      WebkitTextFillColor: '#303030'
    }
  },
  characteristicGrid: {
    display:'flex',
    gap: '1.5rem',
    width:'100%'
  },
  characteristicGridItem: {
    width:'25%'
  }
}

const Chip = ({index, label, onDelete, isDisabled}) => {

  return(
    <Box 
      sx={{
        borderRadius: '62.5rem',
        background: '#FC581F',
        boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
        display: 'flex',
        padding:' 0.4375rem 0.75rem',
        alignItems: 'center',
        gap: '0.625rem',
        color:'white'
      }}
    >
      <Typography
        sx={{
          fontSize:' 0.8125rem',
          fontStyle: 'normal',
          fontWeight: '600',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
      {!isDisabled && (
        <IconButton 
          sx={{ padding: 0 }} 
          onClick={() => onDelete(index)}
        >
          <ClearIcon/>
        </IconButton>

      )}
    </Box>
  )
}

const defaultMeasurementsForm = {
  longitude: '',
  width: '',
  height: '',
  weight: '',
};

const measurementsInputs = ['longitude', 'width', 'height', 'weight'];

const defaultMeasurementsFormErrors = {
  longitudeError: false,
  widthError: false,
  heightError: false,
  weightError: false,
};

const ProductsVariantsForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [filteredOptions, setFilteredOptions] = useState([]);

  const product = state && state.product ? state.product : '';

  const [selectedCharacteristics, setSelectedCharacteristics] = useState([]);
  const [characteristicForm, setCharacteristicForm] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [stockForm, setStockForm] = useState('');
  const [extraDeliveryTimeForm, setExtraDeliveryTimeForm] = useState('');
  const [measurementsForm, setMeasurementsForm] = useState(defaultMeasurementsForm);
  const [measurementsFormErrors, setMeasurementsFormErrors] = useState(defaultMeasurementsFormErrors);
  const [characteristicFormErrors, setCharacteristicFormErrors] = useState([]);
  const [stockFormError, setStockFormError] = useState(false);
  const [extraDeliveryTimeFormError, setExtraDeliveryTimeFormError] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [productVariantToDelete, setProductVariantToDelete] = useState(null);
  const [deleteTitle, setDeleteTitle] = useState('');
  const [stepper, setStepper] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [isProductVariantEdit, setIsProductVariantEdit] = useState(false);
  const [productVariantEdit, setProductVariantEdit] = useState(null);
  
  const { longitudeError, widthError, heightError, weightError } = measurementsFormErrors

  useEffect(() => {
    loadData();
  
  }, [])

  const loadData = async () => {
    if(!!product.productVariants){
      setProductVariants(product.productVariants);
    }
    const searchResult = await getCharacteristicsSearch('');
    setFilteredOptions(searchResult.characteristics)
  };
  

  const AddCharacteristics = (newCharacteristic) => {
    setSelectedCharacteristics([...selectedCharacteristics, newCharacteristic]);
    const newCharacteristicObject = { characteristicId: newCharacteristic._id, characteristicName: newCharacteristic.characteristicName, value: '', code: '', preCode: newCharacteristic.code, extraPrice:''}
    setCharacteristicFormErrors([]);
    setStockFormError(false);
    setCharacteristicForm([...characteristicForm, newCharacteristicObject ]);
  }

  const handleDeleteCharacteristic = (index) => {
    const newCharacteristic = selectedCharacteristics.filter((item, i) => i !== index);
    setSelectedCharacteristics(newCharacteristic);

    const newCharacteristicForm = characteristicForm.filter((item, i) => i !== index);
    setCharacteristicForm(newCharacteristicForm);

    setCharacteristicFormErrors([]);
    setStockFormError(false);
  }

  const onSearchTermChange =  async (newSearchValue) => {
    const searchResult = await getCharacteristicsSearch(newSearchValue);
    setFilteredOptions(searchResult.characteristics)
  }

  const getSku = () => {
    const cha = characteristicForm
    .filter(characteristic => characteristic.code)
    .map(characteristic => characteristic.code)
    .join('-');
    return cha.length ? `${product.code}-${cha}` : ''
  }

  const updateCharacteristicForm = (e, indx) => {
    let inputName = e.target.name;
    let value =  e.target.value;

    if (inputName === 'extraPrice' && !/^\d*\.?\d{0,2}$/.test(value)) {
      return;
    }

    const newForm = JSON.parse(JSON.stringify(characteristicForm)); 
    newForm[indx][inputName] = value;

    if(inputName === 'value'){
      const option = selectedCharacteristics[indx].values.find(element => element.valueName === value)
      newForm[indx]['code'] = `${newForm[indx].preCode}${option.code}`;
    }
    setCharacteristicForm(newForm);
    

    if(characteristicFormErrors.length){
      const newCharacteristicFormErrors = JSON.parse(JSON.stringify(characteristicFormErrors));
      newCharacteristicFormErrors[indx][inputName] = false;
      setCharacteristicFormErrors(newCharacteristicFormErrors);
    }
  };

  const updateMeasurementsForm = (e) => {
    let inputName = e.target.name;
    let value =  e.target.value;

    if (!/^\d*\.?\d{0,2}$/.test(value)) {
      return;
    }

    setMeasurementsForm({
      ...measurementsForm,
      [inputName]: value
    });

    setMeasurementsFormErrors({
      ...measurementsFormErrors,
      [`${inputName}Error`]: false
    });

  };

  const updateStockAndDeliveryTimeForm = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value

    if (!/^\d*$/.test(value)) {
      return;
    }

    if(name === 'stock'){
      setStockForm(value);
      if (stockFormError) setStockFormError(!stockFormError);
    }else{
      setExtraDeliveryTimeForm(value);
      if (extraDeliveryTimeFormError) setExtraDeliveryTimeFormError(!extraDeliveryTimeFormError);
    }
  };

  const currencyFormat = (e, indx) => {
    const inputName = e.target.name;

    const isMeasurementValue = [...measurementsInputs, 'price'].includes(inputName);

    if((isMeasurementValue && measurementsForm[inputName]) || (!isMeasurementValue && characteristicForm[indx].extraPrice) ){
      
      let newPrice = 0 + Number(isMeasurementValue ? measurementsForm[inputName] : characteristicForm[indx].extraPrice);
      const decimalDigits = newPrice.toString().split('.')[1];
      if (!decimalDigits || decimalDigits.length < 2) {
        newPrice = parseFloat(newPrice).toFixed(2);
      }
      if(isMeasurementValue){
        setMeasurementsForm({
          ...measurementsForm,
          [inputName]: newPrice
      });
      }else{
        const newForm = JSON.parse(JSON.stringify(characteristicForm));
        newForm[indx].extraPrice = newPrice;
        setCharacteristicForm(newForm);
      }
    }
  };

  const validateForm = () =>{
    let newCharacteristicErrors = [];
    let newMeasurementsErrors = [];
    
    characteristicForm.forEach( characterict =>{
      let newErr = {};

      ['value','extraPrice'].forEach(characteristicKey => {
          newErr[characteristicKey] = !characterict[characteristicKey].toString();
      });

      newCharacteristicErrors.push(newErr);
    });

    measurementsInputs.forEach(measurementKey => {
      newMeasurementsErrors[`${measurementKey}Error`] = !measurementsForm[measurementKey] || !(measurementsForm[measurementKey] > 0);
    });
  
    setMeasurementsFormErrors(newMeasurementsErrors);
    setCharacteristicFormErrors(newCharacteristicErrors);
    setStockFormError(!stockForm.toString());
    setExtraDeliveryTimeFormError(!extraDeliveryTimeForm.toString());
    
    for(let key of Object.keys(newMeasurementsErrors)){
      if(newMeasurementsErrors[key]) return false;
    }
      
    for (let key in newCharacteristicErrors) {
      for(let subKey of Object.keys(newCharacteristicErrors[key])){
        if(newCharacteristicErrors[key][subKey]) return false;
      }
    }
    
    if (!stockForm.toString()) {
      return false;
    }

    if (!extraDeliveryTimeForm.toString()) {
      return false;
    }

    return true;
  }

  const onAddVariant = () => {
    if(!validateForm()){
      return;
    }
    const isDuplicateVariant = findElementByCharacteristics(productVariants, characteristicForm);
    if(!!isDuplicateVariant) {
      enqueueSnackbar("ya existe una variante con las mismas características.", {
        variant: 'warning'
      });
      return;
    }

    setProductVariants([...productVariants, {
      _id: uuidv4(), 
      characteristics: characteristicForm, 
      stock: stockForm ,
      extraDeliveryTime: extraDeliveryTimeForm ,
      sku: getSku(), 
      createdAt: moment().format(),
      longitude: measurementsForm.longitude ? measurementsForm.longitude : 0,
      width: measurementsForm.width ? measurementsForm.width : 0,
      height: measurementsForm.height ? measurementsForm.height : 0,
      weight: measurementsForm.weight ? measurementsForm.weight : 0,
    }]);
    
    const newCharacteristicForm = JSON.parse(JSON.stringify(characteristicForm));
    newCharacteristicForm.forEach(item => {
      item.value = '';
      item.code = '';
      item.extraPrice = '';
    });

    setCharacteristicForm(newCharacteristicForm);
    setStockForm('');
    setExtraDeliveryTimeForm('');
    setMeasurementsForm(defaultMeasurementsForm);

  }

  const onDeleteModal = (producVariant, indx) => {
    setProductVariantToDelete(indx);
    setDeleteTitle(`¿Deseas borrar la variante ${producVariant.sku}?`);
    setOpenModal(true);
  };

  const onCloseModal = () =>{
    setOpenModal(false);
    setProductVariantToDelete('');
  };

  const onConfirmed = () => {
    onDelete();
    onCloseModal();
  
  };

  const onDelete = () => {
    const newArray = productVariants.filter((element, index) => index !== productVariantToDelete);
    setProductVariants(newArray);
    onCloseModal();
  
  };

  const handleStepper = (number) => {
    const resultingTree = buildTree(productVariants.map(item => item.characteristics)); 
    setStepper(stepper + number);
  }

  const onSubmit = async () => {
    const newProduct = JSON.parse(JSON.stringify(product));
    newProduct.productVariants = productVariants;
    const resultForm = await productsUpdate(newProduct);
    
    if(resultForm.result){
      enqueueSnackbar("Guardado Exitoso.", {
        variant: 'success',
        style: { 
          backgroundColor: '#FC581F',
          Width: '100%',
          justifyContent: 'center'
        }
      });
      navigate('/admin/products');
    } else {
      enqueueSnackbar(`Error al guardar el producto.`, {
        variant: 'error'
      });
    }
  }

  function getVariantCharacteristicNames(characteristics) {
    return characteristics.map(item => `${item.characteristicName}/${item.value}`).join(', ');
  }

  const resetForms = () => {
    setProductVariantEdit(null);
    setSelectedCharacteristics([]);
    setCharacteristicForm([]);
    setStockForm('');
    setExtraDeliveryTimeForm('');
    setMeasurementsForm(defaultMeasurementsForm);
    setMeasurementsFormErrors(defaultMeasurementsFormErrors);
    setCharacteristicFormErrors([]);
    setStockFormError(false);
    setExtraDeliveryTimeFormError(false);
  }

  const onEditVariant = (productVariant, indx) => {
    setIsProductVariantEdit(true);
    setProductVariantEdit(productVariant);


    setCharacteristicForm(productVariant.characteristics);
    const editedCharacteristic = productVariant.characteristics.map(characteristic => ({characteristicName: characteristic.characteristicName, values: [{valueName: characteristic.value}]}))
    setSelectedCharacteristics(editedCharacteristic);

    setMeasurementsForm({    
      longitude: productVariant.longitude ,
      width: productVariant.width,
      height: productVariant.height,
      weight: productVariant.weight,
    });

    setStockForm(!(productVariant.stock == 'undefined') ? productVariant.stock : '');
    setExtraDeliveryTimeForm(!(productVariant.extraDeliveryTime == 'undefined') ? productVariant.extraDeliveryTime : '');

  }

  const onCancelEdit = () => {
    resetForms();
    setIsProductVariantEdit(false);
  }

  const OnSaveEdit = () => {
    if(!validateForm()){
      return;
    }

    const newProductVariants = JSON.parse(JSON.stringify(productVariants));
    const variantIndx = newProductVariants.findIndex(variant => variant._id == productVariantEdit._id)

    const variantEdited = {
      ...productVariantEdit,
      characteristics: characteristicForm, 
      stock: stockForm ,
      extraDeliveryTime: extraDeliveryTimeForm ,
      updatedAt: moment().format(),
      longitude: measurementsForm.longitude,
      width: measurementsForm.width,
      height: measurementsForm.height,
      weight: measurementsForm.weight
    };

    newProductVariants[variantIndx] = variantEdited;

    setProductVariants(newProductVariants);
    
    setIsProductVariantEdit(false);
    resetForms();
  }

  return (
    <Box sx={{px:{xs:'1.5rem', md:'3rem'}, width:'100%' }} >
      <TitleText title={!stepper ? 'PERSONALIZACIÓN': 'RESUMEN'}/>
      {stepper === 0 && (
        <>
          <Typography sx={styles.textList}>1. Elige un Producto Base para iniciar con su personalización.</Typography>
          <Box sx={styles.card}>
            <Typography sx={styles.titleInput}>PRODUCTO BASE</Typography>
            <TextField disabled value={product.productName} fullWidth size='small' variant="outlined" sx={styles.input}/>
          </Box>
          <TitleText title={'OPCIONES'}/>
          <Typography sx={styles.textList}>2. Selecciona las variantes a aplicar en el producto elegido.</Typography>
          <Box sx={styles.card}>
            <Typography 
              sx={{
                ...styles.titleInput, 
                ...(isProductVariantEdit && { color: 'black' })
              }}
            >
              { isProductVariantEdit ? 'EDITAR VARIANTE': 'LISTADO DE CARACTERÍSTICAS' }
            </Typography>
            {
              !isProductVariantEdit && (
                <AutocompleteSearch options={filteredOptions} selectedCharacteristics={selectedCharacteristics} onOptionChange={AddCharacteristics} onSearchTermChange={onSearchTermChange} isDisabled={isProductVariantEdit}/>
              )
            }
            {!!selectedCharacteristics.length && !isProductVariantEdit && (
              <Box 
                sx={{
                  display:'flex',
                  width:'100%',
                  gap:'0.62rem',
                  pt:'0.73rem',
                  overflowX: 'auto',
                  flexWrap: 'nowrap',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  }
                }}
              >
                {selectedCharacteristics.map((item, indx) => (
                  <Chip key={indx} index={indx} label={item.characteristicName} onDelete={handleDeleteCharacteristic} isDisabled={isProductVariantEdit}/>
                ))}
              </Box>
            )}
            {!!selectedCharacteristics.length && (
              <>
                <Grid sx={{ pt: '1.5rem' }} container rowSpacing={{xs:'0.62rem' }} columnSpacing={{xs: '1.5rem'}}>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>LARGO</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>ANCHO</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>ALTO</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>PESO</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      sx={{...styles.input, "& .MuiInputBase-root input": { padding:'0.625rem 0rem 0.625rem 1rem !important', textAlign:'right'} }}
                      fullWidth
                      placeholder='10'
                      size='small'
                      name='longitude'
                      error={longitudeError}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <Typography sx={{ lineHeight: 'inherit', color: '#303030'}}>cm</Typography>
                        </InputAdornment>,
                      }}
                      value={measurementsForm.longitude}
                      onChange={updateMeasurementsForm}
                      onBlur={currencyFormat}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      sx={{...styles.input, "& .MuiInputBase-root input": { padding:'0.625rem 0rem 0.625rem 1rem !important', textAlign:'right'} }}
                      fullWidth
                      placeholder='10'
                      size='small'
                      name='width'
                      error={widthError}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <Typography sx={{ lineHeight: 'inherit', color: '#303030'}}>cm</Typography>
                        </InputAdornment>,
                      }}
                      value={measurementsForm.width}
                      onChange={updateMeasurementsForm}
                      onBlur={currencyFormat}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      sx={{...styles.input, "& .MuiInputBase-root input": { padding:'0.625rem 0rem 0.625rem 1rem !important', textAlign:'right'} }}
                      fullWidth
                      placeholder='10'
                      size='small'
                      name='height'
                      error={heightError}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <Typography sx={{ lineHeight: 'inherit', color: '#303030'}}>cm</Typography>
                        </InputAdornment>,
                      }}
                      value={measurementsForm.height}
                      onChange={updateMeasurementsForm}
                      onBlur={currencyFormat}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      sx={{...styles.input, "& .MuiInputBase-root input": { padding:'0.625rem 0rem 0.625rem 1rem !important', textAlign:'right'} }}
                      fullWidth
                      placeholder='100'
                      size='small'
                      name='weight'
                      error={weightError}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <Typography sx={{ lineHeight: 'inherit', color: '#303030'}}>kg</Typography>
                        </InputAdornment>,
                      }}
                      value={measurementsForm.weight}
                      onChange={updateMeasurementsForm}
                      onBlur={currencyFormat}
                    />
                  </Grid>
                </Grid>
                <Box sx={{width:'100%', height:'0.0625rem', backgroundColor: '#FFEBE6', my:'1.5rem'}}/>
                <Grid sx={{  }} container rowSpacing={{xs:'0.62rem' }} columnSpacing={{xs: '1.5rem'}}>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>CARACTERÍSTICAS</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>VALOR</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>SKU</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={styles.titleInput}>PRECIO ADICIONAL</Typography>
                  </Grid>
                </Grid>
                
                <Grid container rowSpacing={{xs:'1.5rem' }}>
                  {characteristicForm.map((characteristic, indx) => {
                    return(
                      <Grid key={indx} item container xs={12} columnSpacing={'1.5rem'}>
                        <Grid item xs={3}>
                          <TextField disabled fullWidth size='small' name='characteristicName' value={characteristic.characteristicName} sx={styles.input}/>
                        </Grid>
                        <Grid item xs={3}>
                          <Select
                            name='value'
                            size='small'
                            fullWidth
                            disabled={isProductVariantEdit}
                            value={characteristic.value}
                            error={characteristicFormErrors.length ? characteristicFormErrors[indx].value : false }
                            onChange={(e) => updateCharacteristicForm(e,indx)}
                          >
                            {selectedCharacteristics[indx].values.map((valueItem, indx2) => {
                              return (
                                <MenuItem key={indx2} value={valueItem.valueName}>{valueItem.valueName}</MenuItem>
                              )
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField 
                            disabled
                            fullWidth
                            size='small' 
                            name='code' 
                            value={characteristic.code} 
                            sx={styles.input }
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField 
                            size='small' 
                            name='extraPrice'
                            fullWidth
                            error={characteristicFormErrors.length ? characteristicFormErrors[indx].extraPrice : false }
                            placeholder='0.00'
                            value={characteristic.extraPrice}
                            onChange={(e) => updateCharacteristicForm(e, indx)} 
                            onBlur={(e) => currencyFormat(e,indx)}
                            sx={{ "& .MuiInputBase-root input": {padding:'0.625rem 0rem 0.625rem 1rem !important', textAlign:'right'}}} 
                            inputProps={{ inputMode: 'numeric'}} 
                            InputProps={{ 
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              endAdornment: <InputAdornment position="end"> USD</InputAdornment>,
                            }}
                          />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
                <Box sx={{width:'100%', height:'0.0625rem', backgroundColor: '#FFEBE6', my:'1.5rem'}}/>
                <Box sx={{...styles.characteristicGrid, flexDirection:'column' }}>
                  <Box sx={{...styles.characteristicGrid, justifyContent: 'flex-end'}}>
                    <Box sx={{width:'27%'}}>
                      <Typography sx={styles.titleInput } >STOCK:</Typography>
                      <TextField 
                        fullWidth 
                        size='small'  
                        placeholder='0' 
                        name='stock'
                        value={stockForm}
                        error={stockFormError}
                        onChange={updateStockAndDeliveryTimeForm}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                            endAdornment: <InputAdornment position="end"> piezas</InputAdornment>,
                        }}
                        sx={{
                          "& .MuiInputBase-root input": { 
                            padding:'0.625rem 0rem 0.625rem 1rem !important',
                            textAlign:'right'
                          } 
                        }}/>
                    </Box>
                    <Box sx={{width:'27%'}}>
                      <Typography sx={styles.titleInput } >TIEMPO DE ENTREGA:</Typography>
                      <TextField 
                        fullWidth 
                        size='small'  
                        placeholder='0' 
                        name='extraDeliveryTime'
                        value={extraDeliveryTimeForm}
                        error={extraDeliveryTimeFormError}
                        onChange={updateStockAndDeliveryTimeForm}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          endAdornment: <InputAdornment position="end">semanas</InputAdornment>,
                        }}
                        sx={{
                          "& .MuiInputBase-root input": { 
                            padding:'0.625rem 0rem 0.625rem 1rem !important',
                            textAlign:'right'
                          } 
                        }}/>
                    </Box>
                    <Box sx={{width:'27%'}}>
                      <Typography sx={styles.titleInput}>CLAVE ÚNICA:</Typography>
                      <TextField disabled sx={styles.input} fullWidth size='small' name='sku' value={isProductVariantEdit ? productVariantEdit.sku : getSku()} placeholder='Generada automáticamente'/>
                    </Box>
                  </Box>
                  <Box sx={{...styles.characteristicGrid, justifyContent: 'flex-end'}}>
                    { isProductVariantEdit 
                    ? (
                      <>
                        <Button
                          sx={{ 
                            fontSize: '0.8125rem',
                            fontWeight:'700',
                            background: '#FFF',
                            color: '#FC581F',
                            textTransform:'none',
                            minWidth: {xs: 0,lg: '64px'},
                            p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                            borderRadius:'62.5rem',
                            boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                            '& .MuiButton-startIcon':{
                              marginRight: {xs:'0', lg: '8px'},
                              marginLeft: {xs:'0', lg: '0px'},
                            },
                            '&:hover': { 
                              backgroundColor: '#FAFAFA'
                            }
                          }}
                          variant="contained" 
                          onClick={onCancelEdit}
                        >
                          <Typography sx={{fontSize: '0.8125rem', fontWeight:'700', color:'black' }} >
                            Cancelar
                          </Typography>
                        </Button>
                        <Button
                          sx={{ 
                            fontSize: '0.8125rem',
                            fontWeight:'700',
                            background: '#FC581F',
                            color: 'white',
                            textTransform:'none',
                            minWidth: {xs: 0,lg: '64px'},
                            p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                            borderRadius:'62.5rem',
                            boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                            '& .MuiButton-startIcon':{
                              marginRight: {xs:'0', lg: '8px'},
                              marginLeft: {xs:'0', lg: '0px'},
                            },
                            '&:hover': { 
                              backgroundColor: '#FC581F'
                            }
                          }}
                          variant="contained" 
                          onClick={OnSaveEdit}
                        >
                          <Typography sx={{fontSize: '0.8125rem', fontWeight:'700', color:'#FFFEFC' }} >
                            Guardar
                          </Typography>
                        </Button>
                      </>
                    )
                    : (
                        <Button 
                          sx={{ 
                            fontSize: '0.8125rem',
                            fontWeight:'700',
                            background: '#FFF',
                            color: '#FC581F',
                            textTransform:'none',
                            minWidth: {xs: 0,lg: '64px'},
                            p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                            borderRadius:'62.5rem',
                            boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                            '& .MuiButton-startIcon':{
                              marginRight: {xs:'0', lg: '8px'},
                              marginLeft: {xs:'0', lg: '0px'},
                            },
                            '&:hover': { 
                              backgroundColor: '#FAFAFA'
                            }
                          }}
                          variant="contained" 
                          startIcon={<AddIcon sx={{fontSize:'1.5rem !important'}} />}
                          onClick={onAddVariant}
                        >
                          <Typography sx={{ display:{xs:'none', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700', color:'black' }} >
                            Agregar variante
                          </Typography>
                        </Button>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <Box sx={{display: productVariants.length ? 'block' : 'none', pb: '2rem' }}>
            <Typography sx={{...styles.textList, pt: '2rem'}}>3. Comprueba el listado de variantes, su stock y precio.</Typography>
            <Box sx={styles.card}>
              <Box sx={{...styles.characteristicGrid }}>
                <Typography sx={{...styles.titleInput, flexGrow: 1}}>VARIANTES / LISTADO</Typography>
                <Typography sx={{...styles.titleInput, width: '16.6%' }}>STOCK</Typography>
                <Typography sx={{...styles.titleInput, width: '16.6%' }}>TIEMPO DE ENTREGA</Typography>
                <Typography sx={{...styles.titleInput, width: '16.6%' }}>CLAVE ÚNICA</Typography>
                <Typography sx={{...styles.titleInput, width: '16.6%', textAlign:'center' }}>ACCIONES</Typography>
              </Box>
              <Box sx={{...styles.characteristicGrid, flexDirection:'column' }}>
                {productVariants.map((productVariant, indx) => {
                  return(
                    <Box key={indx} sx={styles.characteristicGrid}>
                      <TextField 
                        disabled 
                        size='small' 
                        name='characteristicName' 
                        value={getVariantCharacteristicNames(productVariant.characteristics)} 
                        sx={{ ...styles.input, flexGrow: 1 }}
                      />
                      <TextField 
                        disabled 
                        size='small' 
                        name='characteristicName' 
                        value={`${productVariant.stock} pzas.`} 
                        sx={{
                          width: '16.6%', 
                          "& .MuiInputBase-root input": { 
                            ...styles.input["& .MuiInputBase-root input"],
                            ...styles.input["& .MuiInputBase-input:disabled"],
                            textAlign:'right', 
                          }
                        }}
                      />
                      <TextField 
                        disabled 
                        size='small' 
                        name='characteristicName' 
                        value={`${!!productVariant.extraDeliveryTime ? productVariant.extraDeliveryTime : 'N/A'} semanas`} 
                        sx={{
                          width: '16.6%', 
                          "& .MuiInputBase-root input": { 
                            ...styles.input["& .MuiInputBase-root input"],
                            ...styles.input["& .MuiInputBase-input:disabled"],
                            textAlign:'right', 
                          }
                        }}
                      />
                      <TextField disabled size='small' name='characteristicName' value={productVariant.sku} sx={{ ...styles.input, width: '16.6%' }}/>
                      <Box sx={{ width: '16.6%', display:'flex', gap:'1rem', justifyContent:'center', alignItems:'center' }}>
                        <IconButton
                          disabled={isProductVariantEdit}
                          sx={{ padding: 0 }} 
                          onClick={() => onEditVariant(productVariant, indx)}
                        >
                          <EditOutlinedIcon style={{ fontSize: {lg:'1.5rem'}, color: isProductVariantEdit ? 'gray' : 'black'}} />
                        </IconButton>
                        <IconButton
                          disabled={isProductVariantEdit}
                          sx={{ padding: 0 }} 
                          onClick={() => onDeleteModal(productVariant, indx)} 
                        >
                          <DeleteOutlineOutlinedIcon style={{ fontSize: {lg:'1.5rem'}, color: isProductVariantEdit ? 'gray' : 'black'}} />
                        </IconButton>
                      </Box>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent:'flex-end', pt:'2rem' }}>
              <ButtonStyled text={'VER RESUMEN'} isDisabled={isProductVariantEdit} handleClick={() => {handleStepper(1)}}/>
            </Box>
          </Box>
        </>
      )}
      {stepper === 1 && (
        <ProductVariantResume product={product} handleStepper={handleStepper} handleSaveForm={onSubmit} productVariants={productVariants} tree={buildTree(productVariants.map(item => item.characteristics))} selectedCharacteristics={selectedCharacteristics}/>
      )}
      <DeleteModal title={deleteTitle} confirmButtonText={'Eliminar variante'} onConfirmed={onConfirmed} openModal={openModal} onCloseModal={onCloseModal}/>
    </Box>
  )
}

export default ProductsVariantsForm