import { Box, Typography } from '@mui/material';

const TitleText = ({title, customStyle}) => {
  return (
    <Box sx={{display:'flex', flexDirection:'column', gap:'0.63rem', py:{xs:'1.5rem', md:'2rem'}, ...customStyle }}>
        <Typography sx={{ fontSize:{xs:'1.125rem', md:'1.5rem'}, fontWeight: 'bold' }} >{title}</Typography>
        <Box sx={{ width:'3.75rem', height:'0.0625rem', background: '#FC581F' }} />
    </Box>
  )
}

export default TitleText