import { Box, IconButton, TextField, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react'



const EditStock = ({item, suffix, onEditStock}) => {
    const [stock, setStock] = useState(0);
    const [isEdit, setIsEdit] = useState(false);

    useEffect( () => {
        setStock(item.stock);
        setIsEdit(false);
    },[item]);

    const handleEdit = () => {
        if(stock === ''){
            return;
        }
        if(isEdit){
            onEditStock(item, stock);
        }
        setIsEdit(!isEdit);
    };

    const handleStockChange = (event) => {
        const newStock = event.target.value;
        if(newStock.length > 5)
            return
        setStock(newStock);
    };

    const styles = {
        stock: {
            display: 'flex',
            padding: {xs: '0.5rem 0.3rem', sm: '0.5rem 0.3rem 0.5rem 0.3rem'},
            alignItems: 'center',
            fontSize: '0.875rem', 
            color:'#929191',
            borderRadius: '0.25rem 0rem 0rem 0.25rem',
            border: '1px solid #929191',
            justifyContent: 'end',
            width: '100%',
        },
        button: {
            display: 'flex',
            padding: {xs:'0.5rem', md: '0.5rem 0.75rem'},
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0rem 0.25rem 0.25rem 0rem',
            background:  isEdit ? '#FC581F': '#FC581F',
            color: 'white',
            '&:hover': { backgroundColor: '#FC581F'}
        },
        icon: {
            fontSize:{xs: '1rem', lg: '1.25rem'}
        },
        textField: {
            "& .MuiInputBase-root": {
                borderRadius: '0.25rem 0rem 0rem 0.25rem',
                fontSize: '0.875rem',
                p: '0'
            },
            "& .MuiInputBase-root input": {
                padding: {xs: '0.5rem 0.3rem !important', sm: '0.5rem 0.3rem 0.5rem 0.3rem !important'},
                textAlign: 'end'
            },
        }
    }

    return (
        <>
            <Box sx={{display:'flex', justifyContent: 'center', width:'100%'}}>
                {
                    isEdit
                    ? <TextField 
                        value={stock}
                        size='small'
                        type='number'
                        sx={styles.textField}
                        variant="outlined"
                        fullWidth
                        InputProps={{inputProps: {min: 0}}}
                        onChange={handleStockChange}
                        onKeyDown={(e) => { if(e.key === "Enter") handleEdit(e) }}
                    />
                    : <Typography sx={styles.stock}>
                        {`${stock} ${suffix}`}
                    </Typography> 
                }
                
                <IconButton 
                    sx={styles.button} 
                    onClick={handleEdit}
                >
                    { 
                        isEdit
                        ? <DoneIcon sx={styles.icon}/>
                        : <EditOutlinedIcon sx={styles.icon}/>
                    }
                </IconButton>
            </Box>
        </>
    )
}

export default EditStock