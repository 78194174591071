import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EquysisIcon from '../icons/EquysisIcon'
import { NavLink } from 'react-router-dom';


const NavbarMobile = ({menuList ,selectedItem}) => {
  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const handleItemClick = (item) => {
    if(!!item.submenu){
      setSubmenuOpen(!submenuOpen);
    }else{ 
      if(submenuOpen && !item.isSubmenu){
        setSubmenuOpen(false);
      }
    }
  };

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className='h-18 xl:hidden'>
        <div className=' h-full flex items-center justify-between'>
          <EquysisIcon className="h-11 w-24 ml-4 my-auto"></EquysisIcon>
          
          <IconButton
            sx={{ mr: 2, width: 32, height: 32, p: 0 }}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleToggleMenu}
          >
            <MenuIcon sx={{ height: '100%', width: '100%'}} />
          </IconButton>
        </div>
        <Drawer
          anchor="right"
          PaperProps={{ style: { borderRadius: '24px 0 0 24px', width: '261px' } }}
          open={isMenuOpen}
          onClose={handleToggleMenu}
        >
          <div className='pr-5'>
            <div className='h-18 flex flex-grow items-center justify-end'>
              <IconButton
              sx={{ width: 32, height: 32, p: 0, mr: -1 }}
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleToggleMenu}
              >
                <CloseOutlinedIcon sx={{ height: '100%', width: '100%'}} />
              </IconButton>
            </div>
            <ul className=' flex flex-col gap-7'>
              {menuList.map((item)=> {
                  const isSubActive = !!item.submenu && item.submenu.some(sub => sub.route === selectedItem);
                  return (
                      <li className='pr-5 w-full flex flex-col'
                        key={item.name}
                      >
                        {
                          !!item.route ? (
                            <NavLink className='h-full w-full flex flex-row' to={item.route} onClick={() => handleItemClick(item)}>
                              <div className={`w-2 h-auto ${selectedItem === item.route && "bg-admin-primary"}`}></div>
                              <span className={`text-xl w-full pl-9 text-left flex items-center ${selectedItem === item.route && "font-bold"}`}> {item.name}</span>
                            </NavLink>
                          ): (
                            <>
                              <div className='h-full w-full flex flex-row' onClick={() => handleItemClick(item)}>
                                <div className={`w-2 h-auto ${isSubActive && "bg-admin-primary"}`}></div>
                                <span className={`text-xl w-full pl-9 text-left flex items-center ${isSubActive && "font-bold"}`}> {item.name}</span>
                              </div>
                              {submenuOpen && (
                                <div className='pl-3 mt-2 flex flex-col gap-2'>
                                  {item.submenu.map( sub => {
                                    return (
                                      <NavLink key={sub.name} className='h-full w-full flex flex-row' to={sub.route}  onClick={() => handleItemClick(sub)}>
                                        <div className={`w-2 h-full ${selectedItem === sub.route && "bg-admin-primary"}`}></div>
                                        <span className={`text-xl w-full pl-9 text-left flex items-center ${selectedItem === sub.route && "font-bold"}`}> {'• '+ sub.name}</span>
                                      </NavLink>
                                    )
                                  }
                                  )}
                                </div>
                              )}
                            </>
                          )
                        }
                        
                      </li>
                  )
                })
              }
            </ul>
          </div>
        </Drawer>
    </div>
  );
};

export default NavbarMobile;
