import axios from '../utils/axios';

const SERVICE_URL = '/consignmentNoteClasses';

export const getSubCategoriesList = async () => {
    try {
        let response = await axios.get(`${SERVICE_URL}`);
        return response.data.subCategories.items;
    } catch (error) {
        return [];
    }
}

export const consignmentNoteClassesSearch = async (search) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/search`, {search});
        return response.data;
    } catch (error) {
        return {
            message: 'ocurrio un problema al buscar',
            consignmentNoteClasses: [],
            result: false
        };
    }
}