import React from "react";
import { Box, Typography, Grid, FormControl, FormLabel, TextField, InputAdornment, Select, MenuItem, IconButton, colors, Hidden, Divider, Button, Stack } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { UserContext } from "../context/UserContext";

const iconStyles = {
    color: '#FC581F',
    fontSize: '2rem'
}

const FileDownloader = ({index, file, onDelete, canDelete = false}) => {
    const { domain } = React.useContext(UserContext);

    const openPDF = () => {
        const url = !!file.url ? `${domain}${file.url}` : URL.createObjectURL(file);
        window.open(url, '_blank');
    };

    const fileName = !!file.url ? file.fileName : file.name?.split(`.${file.type.split('/')[1]}`)[0];

    const downloadPDF = async () => {
        try {
          const response = await fetch(`${domain}${file.url}`);
          const blob = await response.blob();
          const newBlob = new Blob([blob], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(newBlob);
          link.setAttribute('download', file.fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading PDF:', error);
        }
    };
    
    return (
        <Box sx={{display:'flex', alignItems: 'center' }}>
            <IconButton sx={{padding: '0px', cursor: 'pointer' }} onClick={openPDF}>
                <FileDownloadOutlinedIcon sx={iconStyles}/>
            </IconButton>
            <Typography sx={{fontSize: {xs: '0.75rem', md: '1rem'}, pl:'0.5rem', pr: canDelete ? '0.62rem' : '', flex: 1, overflow:'hidden', textOverflow:"ellipsis" }} >{fileName}</Typography>
            {canDelete && (
                <IconButton sx={{padding: '0px', cursor: 'pointer' }} onClick={() => onDelete(index)}>
                    <DeleteOutlineOutlinedIcon sx={iconStyles}/>
                </IconButton>
            )}
        </Box>
    );
};

export default FileDownloader;
