import React from "react";

function CartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58"
      height="39"
      fill="none"
      viewBox="0 0 58 39"
    >
      <path
        fill="#FC581F"
        d="M16.139 6.815h36.114l-3.575 16.088H15.53L9.334 2.245A3.15 3.15 0 006.316 0H.106v4.524h5.188l6.225 20.75a3.021 3.021 0 002.894 2.153h35.471a3.02 3.02 0 002.95-2.366l5.06-22.772H18.335a2.197 2.197 0 00-2.197 2.197v2.33zM18.427 38.908a4.58 4.58 0 100-9.162 4.58 4.58 0 000 9.162zM45.914 38.907a4.58 4.58 0 100-9.162 4.58 4.58 0 000 9.162z"
      ></path>
      <path
        fill="#FC581F"
        d="M32.171 8.65c-6.581 0-11.936 5.354-11.936 11.935 0 .485.37.485.932.485h2.416v-.97h-2.368a10.912 10.912 0 012.471-6.454l1.761 1.575.646-.723-1.76-1.574a10.935 10.935 0 017.354-3.294v2.367h.97V9.63a10.935 10.935 0 017.354 3.294l-1.76 1.574.646.723 1.76-1.575a10.912 10.912 0 012.47 6.454H40.76v.97h3.348v-.485c0-6.581-5.355-11.936-11.937-11.936z"
      ></path>
      <path
        fill="#FC581F"
        d="M30.922 17.306l-.034.025a2.193 2.193 0 00-.184.148l-.084.075a2.094 2.094 0 103.117 2.784c1.32-1.526 1.324-4.265 1.324-4.265-2.156 0-3.523.784-4.11 1.21-.009.008-.018.016-.029.023z"
      ></path>
    </svg>
  );
}

export default CartIcon;