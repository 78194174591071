import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { useSnackbar } from "notistack";
import TitleText from "../components/TitleText";
import EditExchangeRate from "../components/EditExchangeRate";
import {
  exchangeRatesByCode,
  exchangeRatesUpdate,
} from "../services/ExchangeRateService";

const ExchangeRatesPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [dollarPrice, setDollarPrice] = useState(0);
  const [updatedAt, setUpdatedAt] = useState('');

  useEffect(() => {
    getExchangeRateByCode();
  }, []);

  const getExchangeRateByCode = async () => {
    setIsLoading(true);
    let { message, result, exchangeRate } = await exchangeRatesByCode("USD");
    if (result) {
      setDollarPrice(exchangeRate.exchangeRate);
      setUpdatedAt(exchangeRate.updatedAt);
      setIsLoading(false);
    } else {
      setDollarPrice(0);
      setUpdatedAt('');
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  const updateExchangeRate = async (newValue) => {
    setIsLoading(true);
    const updateResult = await exchangeRatesUpdate({
      code: "USD",
      exchangeRate: newValue,
    });
    enqueueSnackbar(updateResult.message, {
      variant: updateResult.result ? "success" : "error",
    });
    setIsLoading(false);
    await getExchangeRateByCode();
  };

  return (
    <Box sx={{ px: { xs: "1.5rem", md: "3rem" } }}>
      <TitleText title={"TIPO DE CAMBIO"} />
      <Typography
        sx={{
          fontSize: "0.75rem",
          fontWeight: 300,
          lineHeight: "0.875rem",
          color: "#252323",
          pb: "3rem",
        }}
      >
        *Todos los precios de la plataforma se darán de alta en dólares
        estadounidenses (USD), pero se mostrarán en pesos mexicanos (MXN) con
        base en el valor actual del dólar.
      </Typography>
      <EditExchangeRate
        exchangeValue={dollarPrice}
        updatedAt={updatedAt}
        isDisabled={isLoading}
        onEditValue={updateExchangeRate}
      />
    </Box>
  );
};

export default ExchangeRatesPage;
