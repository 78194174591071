import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';

import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import ChecklistIcon from '@mui/icons-material/Checklist';
import StraightenIcon from '@mui/icons-material/Straighten';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import PriceChangeIcon from '@mui/icons-material/PriceChange';

import LogoBlackIcon from '../icons/LogoBlackIcon';
import CartIcon from '../icons/CartIcon';

const IconsList  = {
  CategoryOutlinedIcon,
  ShoppingCartOutlinedIcon,
  SettingsIcon,
  ChecklistIcon,
  StraightenIcon,
  Inventory2RoundedIcon,
  PriceChangeIcon
};

const drawerOpenWidth = 289;
const drawerCloseWidth = 100;

const openedMixin = (theme) => ({
  width: drawerOpenWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerCloseWidth,
  [theme.breakpoints.up('sm')]: {
    width: drawerCloseWidth,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerOpenWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function NavbarDesktop({menuList ,selectedItem, isDrawerOpen, setIsDrawerOpen}) {

  const [open, setOpen] = useState(isDrawerOpen);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const handleDrawer = () => {
    setOpen(!open);
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleItemClick = (item) => {
    if(!!item.submenu){
      setSubmenuOpen(!submenuOpen);
    }else{ 
      if(submenuOpen && !item.isSubmenu){
        setSubmenuOpen(false);
      }
    }
  };

  return (
    <div className='hidden xl:block'>
      <Drawer 
        variant="permanent" 
        open={open}
        PaperProps={{ style: { borderRadius: '0 24px 24px 0' } }}
      >
        <Box sx={{pt:'2rem', px: !open ? '1.25rem' :'2.56rem', pb:'3.12rem'}}>
          <NavLink  to={'/admin/products'}>
            <Box>
              {open && <LogoBlackIcon/>}
              {!open && <CartIcon/>}
            </Box>
          </NavLink>
          <Divider sx={{pt:'3.12rem'}}/>
        </Box>
        <ul className='flex flex-col gap-[2.5rem]'>
          {menuList.map(({name, icon, route, submenu})=> {
            const Icon = IconsList[icon];
            const isSubActive = !!submenu && submenu.some(sub => sub.route === selectedItem);
              return (
                <li className='w-full flex flex-col hover:bg-slate-100 cursor-pointer'
                  key={name}
                >
                  {!!route ? (
                    <Tooltip title={ !open ? `${name}` : ''} arrow>
                      <NavLink  className=' h-full w-full flex flex-row' to={route} onClick={() => handleItemClick({name, icon, route, submenu})}>
                        <div className={`flex items-center ${open && 'pl-9'} ${!open && 'w-full justify-center'}`}>
                          <Icon style={{ fontSize: '1.7rem', color: selectedItem === route ? '#FC581F': '#303030'}}/>
                        </div>
                        <span className={`text-base w-full ml-5 text-left flex items-center ${selectedItem === route && "font-bold"} ${!open && 'hidden'}`}> {name}</span>
                        <div className={`w-2 h-full ${selectedItem === route && "bg-admin-primary"}`}></div>
                      </NavLink>
                    </Tooltip>
                  ) : (
                    <>
                      <div className=' h-full w-full flex flex-row' onClick={() => handleItemClick({name, icon, route, submenu})} >
                        <Tooltip title={ !open ? `${name}` : ''} arrow>
                          <div className={`flex items-center ${open && 'pl-9'} ${!open && 'w-full justify-center'}`}>
                            <Icon style={{ fontSize: '1.7rem', color: isSubActive ? '#FC581F': '#303030'}}/>
                          </div>
                        </Tooltip>
                        <span className={`text-base w-full ml-5 text-left flex items-center ${isSubActive && "font-bold"} ${!open && 'hidden'}`}> {name}</span>
                        <div className={`w-2 h-full ${isSubActive && "bg-admin-primary"}`}></div>
                      </div>
                      {submenuOpen && (
                        <ul className='bg-[#F4F4F4] mt-3 flex flex-col gap-3'>
                          {submenu.map( submenu => {
                            const SubIcon = IconsList[submenu.icon];
                            return (
                              <li className='pl-0 w-full flex flex-row hover:bg-slate-100 cursor-pointer'
                                key={submenu.name}
                                onClick={() => handleItemClick(submenu)}
                              >
                                <Tooltip title={ (!open && submenuOpen) ? `${submenu.name}` : ''} arrow>
                                  <NavLink  className={`h-full w-full flex flex-row ${open && 'pl-9'} ${!open && 'justify-center'}`} to={submenu.route}>
                                      <SubIcon style={{ fontSize: '1.7rem', color: selectedItem === submenu.route ? '#FC581F': '#303030'}}/>
                                      <span className={`text-base w-full ml-5 text-left flex items-center ${selectedItem === submenu.route && "font-bold"} ${!open && 'hidden'}`}> {submenu.name}</span>
                                  </NavLink>
                                </Tooltip>
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </>
                  )
                }
                </li>
              )
            })
          }
        </ul>
        <div className='h-full ml-8 pt-9 mb-9 flex flex-col justify-end'>
            <div className='h-8 w-full flex justify-end items-center'>
              <IconButton id='botonFlecha' sx={{ padding: 0, backgroundColor: '#FC581F !important', opacity: '0.7', color: 'white'}}  onClick={handleDrawer}>
                {!open ? <ChevronRightIcon style={{ fontSize: '2rem'}} /> : <ChevronLeftIcon  style={{ fontSize: '2rem'}}/>}
              </IconButton>                
            </div>
            <NavLink className='mt-1 h-8 w-full flex items-center hover:bg-slate-100' to={'/'}>
              <IconButton sx={{ padding: 0 }}>
                <LogoutIcon style={{ fontSize: '1.7rem', color: '#303030'}}/>
              </IconButton>               
              <span className={`pl-5 ${!open && 'hidden'}`}>Salir</span>
            </NavLink >
        </div>
      </Drawer>
    </div>
  );
}