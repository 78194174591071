import { createContext, useReducer } from "react";

export const UserContext = createContext();
export const UserDispatchContext = createContext();

export const UserProvider = ({ children }) => {
    const [userState, userActions] = useReducer(
        userReducer,
        initialState
    );
    
    return (
        <UserContext.Provider value={userState}>
            <UserDispatchContext.Provider value={userActions}>
                {children}
            </UserDispatchContext.Provider>
        </UserContext.Provider>
    );
}

function userReducer(state, action) {
    switch (action.type) {
      case 'LOG_IN': {
        localStorage.clear();
        localStorage.setItem('token', action.AccessToken);
        localStorage.setItem('refreshToken', action.RefreshToken);
        localStorage.setItem('UID', action.uid);

        return {
            ...state,
            isAuthenticated: true
        };
      }
      
      case 'LOG_OUT': {
        localStorage.clear();
        return {
            ...state,
            isAuthenticated: false
        }
      }
      
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }
  
  const initialState = {
    isAuthenticated: false,
    domain: window.location.protocol + "//" + window.location.host + "/assets/"
  };