
function ClearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#fff"
        d="M.923 12L0 11.077 5.077 6 0 .923.923 0 6 5.077 11.077 0 12 .923 6.923 6 12 11.077l-.923.923L6 6.923.923 12z"
      ></path>
    </svg>
  );
}

export default ClearIcon;