import {useState, useRef} from 'react'
import Papa from 'papaparse';
import { useSnackbar } from 'notistack';

import { productsUploadCsv } from '../services/productService'

import { Button, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { parseCSV, validateProductAttributeCsv } from '../utils/utils';

const columnMapping = {
  "Nombre": "productName",
  "Descripción": "description",
  "Precio Base": "price",
};

const UploadCSVButton = ({ customSx, reloadTableData }) => {

  const inputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    inputRef.current.value = '';

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: async (csv) => {
        const validation = validateProductAttributeCsv(csv.meta.fields);
        if(validation.index !== -1){
          enqueueSnackbar(`${validation.msj} - Atributo: "${validation.attribute}" - Índice: ${validation.index}`, {
            variant: 'error'
          });
          return;
        }
        try {
          const result = parseCSV(csv);
          const resultUpload = await productsUploadCsv(result);
          if(resultUpload.result){
            enqueueSnackbar("Carga de plantilla exitosa", {
              variant: 'success'
            });
            await reloadTableData()
          } else{
            enqueueSnackbar("Error al subir la plantilla", {
              variant: 'error'
            });
          }
        } catch (error) {
          enqueueSnackbar(error.toString(), {
            variant: 'error'
          });
          return;
        }
      },
      error: (error) =>{
        enqueueSnackbar("Error al leer el archivo", {
          variant: 'error'
        });
      }
    });
  };

  const handleLabelClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <input type="file" style={{display:'none'}} ref={inputRef} accept=".csv" onChange={handleFileChange} />
      <Button 
        sx={{ 
          fontSize: '0.8125rem',
          fontWeight:'700',
          background: '#FFF',
          color: '#FC581F',
          textTransform:'none',
          minWidth: {xs: 0,lg: '64px'},
          p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
          borderRadius:'62.5rem',
          boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
          '& .MuiButton-startIcon':{
            marginRight: {xs:'0', lg: '8px'},
            marginLeft: {xs:'0', lg: '0px'},
          },
          '&:hover': { 
            backgroundColor: '#FAFAFA'
          },
          ...customSx
        }}
        variant="contained" 
        startIcon={<UploadFileIcon sx={{fontSize:'1.5rem !important'}} />}
        onClick={handleLabelClick}
      >
        <Typography sx={{ display:{xs:'none', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700' }}>
        Cargar plantilla
        </Typography>
      </Button>
    </>
  )
}

export default UploadCSVButton