import React from 'react'
import { LoginForm } from '../components/login/LoginForm';
import EquysisIcon from '../components/icons/EquysisIcon';
import ShapeRightIcon from '../components/icons/ShapeRightIcon';
const LoginPage = () => {
  return (
	<div className="flex flex-col bg-admin-secondary" style={{minHeight:'100vh'}}>
		<div className='bg-admin-secondary w-full h-full relative'>
			<ShapeRightIcon  className="xl:w-5/12 h-auto absolute top-0 left-0 right-0 z-0 xl:-right-[unset] hidden xl:block" leftDireccion={true}></ShapeRightIcon>
			<ShapeRightIcon  className="w-full h-auto absolute top-0 left-0 right-0 z-0 xl:w-5/12 xl:left-[unset]"></ShapeRightIcon>
			<div className='w-[307px] xl:w-4/12 container mx-auto h-[calc(60%)] xl:h-[calc(96%)] xl:mt-[2%] flex flex-col items-center z-10 relative mt-[40%] '>
				<EquysisIcon className="h-auto mx-10 xl:mx-0 mb-6 xl:mb-[35%] xl:px-[30%]" whiteLetter={true}></EquysisIcon>
				<h1 className='text-3xl xl:text-4xl text-white font-bold text-center'>Inicio de sesión</h1>
				<LoginForm></LoginForm>
				<div className='text-white text-sm mt-7.5 xl:mt-9.5'>
					{/* <a className='underline'> ¿Olvidaste tu contraseña? </a> */}
				</div>
			</div>
		</div>
	</div>
  )
}
export default LoginPage;