import React, { useState } from 'react'
import { Typography, Box, Link, Grid, Divider } from '@mui/material';
import ChipLabel from './ChipLabel';
import { chipStatus } from '../utils/enums';
import IOSSwitch from './IOSSwitch';

const customStyle = {
    label: {fontSize: {xs:'0.875rem', lg: '1rem'}, fontWeight: 500, color: '#303030', lineHeight: {xs:'1rem', lg: '1.125rem'}, letterSpacing: '0.01563rem'},
    span: {fontSize: {xs: '0.875rem', lg: '1rem'}, fontWeight: 400, color: '#514F4F', lineHeight: {xs:'1.125rem', lg: '1.25rem'} }
}

const OrderBoxDetail = ({box, indx, isLoading, handleCheckedChange}) => {

    const [checked, setChecked] = useState(!!box.isReadyForCollection);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        handleCheckedChange(event.target.checked, indx);
    };
    return (
        <Box sx={{display:'flex', flexDirection: 'column', gap: '1rem', px: '1rem'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                <Typography sx={{fontSize: {lg: '1.125rem'},fontWeight:'bold', color: '#303030', lineHeight: {xs:'1.125rem', lg: '1.25rem'} }}>{`Caja ${indx+1}`}</Typography>
                <Grid container rowSpacing={{xs:'0.5rem', md: '1rem' }} columnSpacing={{xs:'2rem'}}>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{...customStyle.label, pr:'1rem' }}>
                            Dimensiones:
                            <Typography sx={customStyle.span} variant='span'>{ ` ${box.width}×${box.height}×${box.longitude} CM`}</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography sx={customStyle.label}>
                            Peso Total:
                            <Typography sx={customStyle.span} variant='span'>{` ${box.weight} KG`}</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography sx={customStyle.label}>
                            Stock:
                            <Typography sx={customStyle.span} variant='span'>{` ${!box.isOutStock ? 'Con': 'Sin'} stock` }</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography sx={customStyle.label}>
                            No. Rastreo:
                            <Typography sx={customStyle.span} variant='span'>{` ${!!box.label ? box.label.attributes.tracking_number : '-'}` }</Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={'auto'}>
                        <Typography sx={{...customStyle.label, overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            Paquetería:
                            <Typography sx={customStyle.span} variant='span'>{` ${!!box.shipment ? box.shipment.rate.provider : '-'}` }</Typography>
                        </Typography>
                    </Grid>
                </Grid>

            </Box>
            <Divider sx={{ borderColor:'#E9E9E9'}}/>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: {xs:'1.25rem', md: '0.5rem'} }}>
                {box.products.map((product, indx) => (
                    <Grid key={indx} container sx={{alignItems: 'center'}} rowSpacing={{xs:'0.5rem' }} columnSpacing={{xs:'2rem'}}>
                        <Grid item xs={12} md={4}> 
                            <Typography sx={{...customStyle.label, overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis'}}>
                                {`Producto ${indx+1}:`}
                                <Typography sx={{...customStyle.span, textTransform: 'capitalize'}} variant='span'>{` ${product.product.productName}`}</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography sx={customStyle.label}>
                                {`Cantidad:`}
                                <Typography sx={customStyle.span} variant='span'>{` ${product.quantity} PZA`}</Typography>
                            </Typography>
                        </Grid>
                        <Grid sx={{display: 'flex', alignItems: 'center', gap: '0.5rem'}} item xs={12} md={4}>
                            <Typography sx={customStyle.span}>
                                {`Código: `}
                            </Typography>
                            <ChipLabel label={`${product.sku}`} background={chipStatus.cancelled.background} showTooltip={true}  fontstyle={{fontSize: '0.75rem', lineHeight:'0.875rem', color: '#303030', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis'}} />
                        </Grid>
                    </Grid>
                ))}
            </Box>
            <Divider sx={{ borderColor:'#E9E9E9'}}/>
            <Box sx={{display:'flex', flexDirection: 'column', gap: '1rem'}}>
                <Box sx={{display:'flex', flexDirection: {xs: 'column', md: 'row'}, gap: {xs:'0.75rem', md: '1.5rem'}, }}>
                    <Typography sx={customStyle.label}>
                        Número de Guía:
                        { ((!!box.label && !!box.label.attributes) && !!box.label.attributes.label_url) &&
                            <Link href={`${box.label.attributes.label_url}`} target="_blank" rel="noopener" sx={{...customStyle.label, color: '#2F69FF', textDecorationLine: 'underline', pl:'0.5rem'}}>
                                Imprimir Guía
                            </Link>
                        }
                    </Typography>
                    <Typography sx={customStyle.label}>
                        Link de Rastreo:
                        { ((!!box.label && !!box.label.attributes) && !!box.label.attributes.tracking_url_provider) &&
                            <Link href={`${box.label.attributes.tracking_url_provider}`} target="_blank" rel="noopener" sx={{...customStyle.label, color: '#2F69FF', textDecorationLine: 'underline', pl:'0.5rem'}}>
                                Link de Rastreo
                            </Link>
                        }
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
                    <Typography sx={customStyle.label}>Listo para recolección:</Typography>
                    <IOSSwitch 
                        sx={{ m: 0}}
                        disabled={isLoading}
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}  
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default OrderBoxDetail