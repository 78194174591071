import {useState} from 'react'

import { Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';



const DownloadCSVButton = ({data, fileName, customSx}) => {

  const downloadCSV = () => {
    const csvContent = 'data:text/csv;charset=utf-8,' + data.map(row => row.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button 
      sx={{ 
        fontSize: '0.8125rem',
        fontWeight:'700',
        background: '#FFF',
        color: '#FC581F',
        textTransform:'none',
        minWidth: {xs: 0,lg: '64px'},
        p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
        borderRadius:'62.5rem',
        boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
        '& .MuiButton-startIcon':{
          marginRight: {xs:'0', lg: '8px'},
          marginLeft: {xs:'0', lg: '0px'},
        },
        '&:hover': { 
          backgroundColor: '#FAFAFA'
        },
        ...customSx
      }} 
      variant="contained" 
      startIcon={<DownloadIcon sx={{fontSize:'1.5rem !important'}} />}
      onClick={downloadCSV}
    >
      <Typography sx={{ display:{xs:'none', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700', }}>
        Descargar plantilla
      </Typography>
    </Button>
  )
}

export default DownloadCSVButton