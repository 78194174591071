import React from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LoginPage from '../pages/LoginPage'
import NavbarPage from '../pages/NavbarPage';
import UserPage from '../pages/UserPage';
import ProductPage from '../pages/ProductPage';
import HomePage from '../pages/HomePage';
import CategoriesPage from '../pages/CategoriesPage';
import ProductFormPage from '../pages/ProductFormPage';
import ProductsVariantsForm from '../pages/ProductsVariantsForm'
import CharacteristicPage from '../pages/CharacteristicPage';
import ProductVariantPage from '../pages/ProductVariantPage';
import OrdersPage from '../pages/OrdersPage';
import ExchangeRatesPage from '../pages/ExchangeRatesPage';




const router = createBrowserRouter([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "/admin",
      element: <NavbarPage />,
      children: [
        {
          path: "/admin",
          element: <HomePage />
        },
        {
          path: "/admin/users",
          element: <UserPage />
        },
        {
          path: "/admin/categories",
          element: <CategoriesPage />
        },
        {
          path: "/admin/characteristics",
          element: <CharacteristicPage />
        },
        {
          path: "/admin/products",
          element: <ProductPage />
        },
        {
          path: "/admin/productVariant",
          element: <ProductVariantPage />
        },
        {
          path: "/admin/orders",
          element: <OrdersPage />
        },
        {
          path: "/admin/exchangeRates",
          element: <ExchangeRatesPage />
        },
        {
          path: "/admin/products/form",
          element: <ProductFormPage />
        },
        {
          path: "/admin/products/variantsForm",
          element: <ProductsVariantsForm />
        },
      ],
    }
]);

const Router = () => {
    return (
        <RouterProvider router={router} />
    )
}

export default Router;
