import React from 'react'
import { useState } from 'react';

import NavbarMobile from '../components/navbar/navbarMobile'
import NavbarDesktop from '../components/navbar/NavbarDesktop'
import { Outlet } from "react-router-dom";

import Box from '@mui/material/Box';
import gearIconPng from '../assets/Vector.png';
import logoIconPng from '../assets/LOGO.png'
import { useLocation } from "react-router-dom";

const WSS_URL = process.env.REACT_APP_SOCKET_URL;

const menuList = [
  { name: 'Categorías', icon: 'CategoryOutlinedIcon', route: '/admin/categories' },
  { 
    name: 'Productos',
    icon: 'ShoppingCartOutlinedIcon', 
    route: '',
    submenu: [
      {
        name: 'Productos',
        icon: 'StraightenIcon', 
        route: '/admin/products',
        isSubmenu: true
      },
      {
        name: 'Variantes',
        icon: 'ChecklistIcon', 
        route: '/admin/productVariant',
        isSubmenu: true
      }
    ]
  },
  { name: 'Características', icon: 'SettingsIcon', route: '/admin/characteristics' },
  { name: 'Ordenes', icon: 'Inventory2RoundedIcon', route: '/admin/orders' },
  { name: 'Tipo de Cambio', icon: 'PriceChangeIcon', route: '/admin/exchangeRates' }
];


const NavbarPage = ({children}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;


  return (
    <div>
      <NavbarMobile 
        menuList={menuList}
        selectedItem={currentPath}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <Box sx={{ display: 'flex' }}>
        <NavbarDesktop
          menuList={menuList} 
          selectedItem={currentPath }
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
        <Box sx={{height:'100%', width: {xs: '100%', lg: isDrawerOpen ? 'calc(100% - 289px)' : 'calc(100% - 102px)'}
        }}> 
          <Box style={{ height:'100%', position: 'fixed', display:'flex', alignItems:'flex-end', zIndex:'0', }}>
            <Box style={{ height:'auto', width:'auto'}}>
              <img height={80} src={gearIconPng} alt="logo" />
            </Box>
          </Box>
          <Box style={{ position:'relative', zIndex:'999'}}>
            <div className='flex items-start justify-end'>
              <Box style={{position:'fixed', zIndex:'-1', }}>
                <img height={80} src={logoIconPng} alt="logo" /> 
              </Box>
            </div>
            <Box style={styles.test}>
              <Outlet/>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default NavbarPage

const styles= {
  test: {
    height:'100%'
  }
}