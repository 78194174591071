import {useState} from 'react'
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const style = {
    width:'100%',
    "& .MuiInputBase-root input": {
        py:'0 !important',
        px:'0 !important',
        pl:'0.75rem'
    },
    "& .MuiInputBase-root": {
        background: '#FFFF',
        borderRadius:'0.25rem',
        boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
        py:{xs: '0.6875rem'},
        paddingLeft: '0.8rem',
        paddingRight:'0.8rem !important',
        fontSize: {xs: '0.875rem', md: '1rem'},
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: '1px solid rgba(0, 0, 0, 0.1)',
        },
        '&.Mui-focused fieldset': {
            border: '1px solid rgba(0, 0, 0, 0.87)',
        },
    }
};

const SearchBar = ({ onSearchTermChange, placeHolder }) => {

    const [searchInput, setSearchInput] = useState('');
    const [debounceTimer, setDebounceTimer] = useState(null);

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchInput(newSearchTerm);

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const newDebounceTimer = setTimeout(() => { 
            onSearchTermChange(newSearchTerm);
        }, 300);

        setDebounceTimer(newDebounceTimer);
    };

    return (
        <TextField
            sx={style}
            value={searchInput}
            onChange={handleSearchChange}
            placeholder={placeHolder}
            name="search"
            type='text'
            autoComplete="off"
            inputProps={{autoComplete: 'off'}}
            InputProps={{
                startAdornment:
                <SearchIcon />
            }}
        />
    )
}

export default SearchBar