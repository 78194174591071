import { useState } from 'react';

import { Box, Typography, IconButton } from '@mui/material';
import ClearIcon from '../components/icons/ClearIcon';

const Chip = ({index, label, onDelete}) => {

    return(
      <Box 
        sx={{
          borderRadius: '62.5rem',
          background: '#FC581F',
          boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
          display: 'flex',
          padding:' 0.4375rem 0.75rem',
          alignItems: 'center',
          gap: '0.625rem',
          color:'white'
        }}
      >
        <Typography
          sx={{
            fontSize:' 0.8125rem',
            fontStyle: 'normal',
            fontWeight: '600',
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </Typography>
        <IconButton 
          sx={{ padding: 0 }} 
          onClick={() => onDelete(index)}
        >
          <ClearIcon/>
        </IconButton>
      </Box>
    )
  }

export default Chip


