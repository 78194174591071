import axios from '../utils/axios';

const SERVICE_URL = '/exchangeRates';

export const exchangeRatesCreate = async (data) => {
    try {
        let response = await axios.post(`${SERVICE_URL}`, data);
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema'};
    }
}

export const exchangeRatesUpdate = async (data) => {
    try {
        let response = await axios.put(`${SERVICE_URL}`, data);
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema'};
    }
}


export const exchangeRatesByCode = async (code) => {
    try {
        let response = await axios.get(`${SERVICE_URL}/bycode?code=${code}`);
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema'};
    }
}
