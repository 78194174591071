import axios from '../utils/axios';

const SERVICE_URL = '/orders';

export const getOrdersList = async (
    searchTerm,
    page,
    rowsPerPage,
    LastEvaluatedKey
) => {
    try {
        let response = await axios.post(
            `${SERVICE_URL}`
            , 
            { 
                searchTerm,
                page,
                rowsPerPage,
                LastEvaluatedKey
            }
        );
        return response.data;
    } catch (error) {
        return {
            Items: [], 
            LastEvaluatedKey: {},
            totalPages: 1
        };
    }
}

export const orderUpdate = async (data) => {
    try {
        let response = await axios.put(`${SERVICE_URL}`, data);
        return response.data;
    } catch (error) {
        return {result: false, message: 'ocurrio un problema'};
    }
}

export const getCharacteristicsSearch = async (
    wordToSearch
) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/search`, { wordToSearch });
        return response.data;
    } catch (error) {
        return {
            characteristics: [],
            error
        };
    }
}

export const getCharacteristicById = async (
    _id
) => {

    try {
        let response = await axios.post( `${SERVICE_URL}/id` , { _id } );
        return response.data;
    } catch (error) {
        return {
            error
        };
    }
}
