import { useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material'
import TitleText from '../components/TitleText'
import SearchBar from '../components/SearchBar'
import { getProductVariantsList, productVarianStockUpdate, productVarianStockUpdateByCsv } from '../services/productService'
import DataTableProductVariant from '../components/DataTableProductVariant'
import DownloadCSVButton from '../components/DownloadCSVButton';
import { variantsCsv } from '../utils/csvConfig';
import Papa from 'papaparse';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import { getVariantCharacteristicNames } from '../utils/utils';
import CircularProgress from '@mui/material/CircularProgress';

const ProductVariantPage = () => {
  const [ searchTerm, setSearchTerm ] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [ tableData, setTableData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ creatingFile, setCreatingFile ] = useState(false);
  const [ LastEvaluatedKey, setLastEvaluatedKey ] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef(null);
  
  useEffect(() => {
    getTableData();
  }, [searchTerm, page, rowsPerPage,]);

  const getTableData = async() => {
    setIsLoading(true);
    let { items, totalPages } = await getProductVariantsList(
      searchTerm,
      page,
      rowsPerPage,
      LastEvaluatedKey
    );
    console.log("🚀 ~ getTableData ~ items:", items)
    setTableData(items);
    setTotalPages(totalPages);
    setLastEvaluatedKey(LastEvaluatedKey);
    setIsLoading(false);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };
  
  const handleChangeSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(1);
  };

  const handleEditStock = async (data) => {
    const updateResult = await productVarianStockUpdate(data);
    if (updateResult.result){
        await getTableData();
        enqueueSnackbar("Actualización exitosa", {
          variant: 'success'
        });
      } else{
        enqueueSnackbar(updateResult.message, {
          variant: 'error'
        });
      }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    inputRef.current.value = '';

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: async (csv) => {
        let _items = csv.data.map(item => {
          let dataItem = tableData.find(it => it.sku === item['ID Único'])
          return {
            productId: dataItem.productId,
            _id: dataItem._id,
            stock: item.Stock
          }
        })
        try {
          await productVarianStockUpdateByCsv(_items)
          enqueueSnackbar("Carga de plantilla exitosa", {
            variant: 'success'
          });
          getTableData()
        } catch (error) {
          console.error(error)
          enqueueSnackbar("Error al subir la plantilla", {
            variant: 'error'
          });
        }
      },
      error: (error) =>{
        enqueueSnackbar("Error al leer el archivo", {
          variant: 'error'
        });
      }
    });
  };

  const handleLabelClick = () => {
    inputRef.current.click();
  };

  const downloadCSV = (data) => {
    setCreatingFile(true);
    let _variantsCsv = variantsCsv.concat(tableData.map(item => ([
      item.productName,
      getVariantCharacteristicNames(item.characteristics).replace(/,/g, ' -'),
      item.sku,
      item.stock
    ])))

    const csvContent = 'data:text/csv;charset=utf-8,' + _variantsCsv.map(row => row.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${formatDate()}-inventario`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setCreatingFile(false);
    enqueueSnackbar("Descarga de inventario exitosa", {
      variant: 'success'
    });
  };

  const formatDate = ()  => {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1; 
    let year = date.getFullYear();
  
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
  
    return `${day}-${month}-${year}`;
  }  


  return (
    <>
      <Box sx={{px:{xs:'1.5rem', md:'3rem'}}} >
        <TitleText title={'INVENTARIO DE VARIANTES'}/>
        <Box sx={{display:'flex', gap:{xs:'1rem', md:'1.5rem', lg:'2rem'}, pb: {xs:'1.5rem', md:'2rem', lg:'2.13rem'} }}>
          <Box sx={{display:'flex', flexGrow: 1 }}>
            <SearchBar onSearchTermChange={handleChangeSearchTerm} placeHolder={"Buscar por ID único: ej. DE-ET-RE-23"} />
          </Box>
          <Box sx={{ display:'flex', gap:{xs:'0.75rem', lg:'1rem'} }}>
            {/* <DownloadCSVButton customSx={{ display:{ xs:'none', md:'inline-flex' } }} data={variantsCsv} fileName={'plantilla-variantes.csv'}/> */}
            <>
              <input type="file" style={{display:'none'}} ref={inputRef} accept=".csv" onChange={handleFileChange} />
              <Button 
                sx={{ 
                  fontSize: '0.8125rem',
                  fontWeight:'700',
                  background: '#FFF',
                  color: '#FC581F',
                  textTransform:'none',
                  minWidth: {xs: 0,lg: '64px'},
                  p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                  borderRadius:'62.5rem',
                  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                  '& .MuiButton-startIcon':{
                    marginRight: {xs:'0', lg: '8px'},
                    marginLeft: {xs:'0', lg: '0px'},
                  },
                  '&:hover': { 
                    backgroundColor: '#FAFAFA'
                  },
                  display:{ xs:'none', md:'inline-flex' },
                }}
                variant="contained" 
                startIcon={<UploadFileIcon sx={{fontSize:'1.5rem !important'}} /> }
                onClick={handleLabelClick}
              >
                <Typography sx={{ display:{xs:'none', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700' }}>
                Cargar Inventario
                </Typography>
              </Button>
            </>
            <Button 
              sx={{ 
                fontSize: '0.8125rem',
                fontWeight:'700',
                background: '#FFF',
                color: '#FC581F',
                textTransform:'none',
                minWidth: {xs: 0,lg: '64px'},
                p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                borderRadius:'62.5rem',
                boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                '& .MuiButton-startIcon':{
                  marginRight: {xs:'0', lg: '8px'},
                  marginLeft: {xs:'0', lg: '0px'},
                },
                '&:hover': { 
                  backgroundColor: '#FAFAFA'
                },
                display:{ xs:'none', md:'inline-flex' },
              }}
              variant="contained" 
              startIcon={creatingFile ? <CircularProgress sx={{fontSize:'1.2rem !important'}} /> : <DownloadIcon sx={{fontSize:'1.5rem !important'}} /> }
              onClick={downloadCSV}
            >
              <Typography sx={{ display:{xs:'none', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700' }}>
              Descargar Inventario
              </Typography>
            </Button>
          </Box>
        </Box>
        <DataTableProductVariant
          tableData={tableData}
          page={page}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onEdit={handleEditStock} 
          isLoading={isLoading} 
        />
      </Box>
    </>
    
  )
}

export default ProductVariantPage