import { useState, useEffect, useRef, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Grid, FormControl, FormLabel, TextField, InputAdornment, Select, MenuItem, IconButton, Divider, Button, Checkbox, FormControlLabel} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ReorderIcon from '@mui/icons-material/Reorder';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';

import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { useSnackbar } from 'notistack';

import { productsCreate, productsUpdate, productsGetImageUrl, productsUploadImage, productsGetImageUrlToDelete, productsDeleteImage, productsGetFileUrl } from '../services/productService';
import { getSubCategoriesOnCategoriesList } from '../services/subCategoriesService';
import UploadFile from '../components/UploadFile';
import FileDownloader from '../components/FileDownloader';
import AutocompleteProductType from '../components/AutocompleteProductType';
import { consignmentNoteClassesSearch } from '../services/consignmentNoteClassesService';
import { UserContext } from '../context/UserContext';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
  
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
}));
  
function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
}
  
QontoStepIcon.propTypes = {
    /**
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * @default false
     */
    completed: PropTypes.bool,
};
  
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        marginLeft:'17px'
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top:'1rem',
        [theme.breakpoints.up('md')]: {
            top:'1.4rem',
        },
        [theme.breakpoints.up('lg')]: {
            top:'0',
        },
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            border: '1px solid #FC581F',
            [theme.breakpoints.up('lg')]: {
                border: '0',
                borderColor: '#FC581F',
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px'
            },
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            border: '1px solid #FC581F',
            [theme.breakpoints.up('lg')]: {
                border: '0',
                borderColor: '#FC581F',
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px'
            },
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        border: '1px dashed #D9D9D9',
        [theme.breakpoints.up('lg')]: {
            border: '0',
            borderColor: '#D9D9D9',
            borderLeftStyle: 'dashed',
            borderLeftWidth: '1px',
            height:'116%',
            marginTop: '-2%'
        },
        borderRadius: 1,
    },
}));
  
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    zIndex: 1,
    color: '#D9D9D9',
    border: '1px dashed #D9D9D9',
    padding: '0.5rem',
    [theme.breakpoints.up('lg')]: {
        padding: '0.23rem',
    },
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#303030',
        border: '1px dashed #FC581F'
    }),
    ...(ownerState.completed && {
        color: '#FFEBE6',
        backgroundColor: '#FC581F',
        border: ''
    }),
}));
  
function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    const IconStyle = {fontSize: {xs:'16px', md:'26.7px'} };
    const icons = {
      1: <FeedOutlinedIcon sx={IconStyle} />,
      2: <CategoryOutlinedIcon sx={IconStyle} />,
      3: <FormatPaintIcon sx={IconStyle} />,
      4: <FactCheckOutlinedIcon sx={IconStyle} />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
};
  
ColorlibStepIcon.propTypes = {
    /**
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * @default false
     */
    completed: PropTypes.bool,
    /**
     */
    icon: PropTypes.node,
};
  
const steps = [
    'Información general', 
    'Subcategorías',
    'Características',
    'Finalizar'
];

const formTitles = [
    'Información general', 
    'Subcategorías', 
    'Características del Producto',
    'Resumen de Producto Agregado'
];

const formLabelStyle = {color: '#A1A1A1', fontWeight:'bold', fontSize:{xs: '0.75rem', display: 'flex'} };
const inputStyle = {sx: { fontSize:{xs: '0.8125rem', md: '1rem'} }};
const sectionStyle = { paddingBottom:{xs: '1.88rem'}, paddingTop: {xs: '1.88rem'}, px:{ xs:'1.56rem'}};
const divStyle = { paddingBottom:{xs: '1.25rem'} };
const requiredLabelStyle = {color: '#FC581F', fontWeight:'bold'};
const dropzoneStyle = {
    border: '1px dashed #FC581F',
    borderRadius: '0.5rem',
    padding: '0.75rem 0rem',
    textAlign: 'center',
    cursor: 'pointer',
};

const defaultCategories = [
    'Productos',
    'Marcas',
    'Aplicaciones',
    'Magnitudes'
];

const defaultCategoriesObj = {
    products: 'Productos',
    brands: 'Marcas',
    applications: 'Aplicaciones',
    measures: 'Magnitudes'
};

const defaultErrorValues = {
    productNameError: false,
    descriptionError: false,
    priceError: false,
    stockError: false,
    extraDeliveryTimeError: false,
    productTypeError: false
};

const subTitleStyle = { fontSize:{xs:'1rem', md:'1.125rem'}, pb:{xs:'1.5rem'}, fontWeight:'bold'};
const measurementsTitleStyle = {fontSize: {xs:'0.875rem', md: '1rem'}, fontWeight: '500', color: '#303030' };
const measurementsValueStyle = {fontSize: {xs:'0.875rem', md: '1rem'}, fontWeight: '400', color: '#7C7B7B' };

const InfoBox = ({ label, value, spaceBottom = true}) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: spaceBottom ? '0.5rem': 0  }}>
        <SubTitle label={label} spaceBottom={false}/>
        <Typography sx={{ ...inputStyle.sx }}>{value}</Typography>
      </Box>
    );
};

const DividerOrange = () => {
    return (
        <Box sx={{py: sectionStyle.paddingBottom}}>
            <Divider orientation='horizontal' sx={{ borderWidth:'1px', borderColor:'#FC581F',}}/>
        </Box>
    );
};

const SubTitle = ({label, spaceBottom = true}) => {
    return (
        <Typography sx={{...inputStyle.sx, fontWeight:'600', paddingBottom: spaceBottom ? '0.62rem': 0  }}>{label}</Typography>
    );
};

const ButtonDownload = ({label}) => {
    return (
        <Button
            startIcon={<DownloadIcon sx={{fontSize:{xs:'1.06rem'}}} />}
            sx={{
                width:'100%',
                borderRadius: '0.25rem',
                boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.10)',
                background: '#FFFF',
                display:'flex',
                justifyContent:'center',
                height:{xs:'2rem'},
                color:'#FC581F', 
                fontSize:{xs:'0.625rem'},
                textTransform:'none'
            }}
        >
            {label}
        </Button>
    );
};

const ImgViewer = ({newImages, images, onDeleteFormFiles, onDeleteNewFiles, isView=true}) => {
    const { domain } = useContext(UserContext);
    const filesAux = images.map(item => ({...item, isSaved: true}));
    const finishList = [...filesAux, ...newImages];
    return (
        <Box sx={{ 
            display: 'flex',
            flexWrap:'nowrap',
            overflowX: 'auto',
            width:'100%',
            gap:'0.5rem'
        }}>
            {finishList.map((file, index) => {
                return (
                    <Box key={index} 
                        sx={{
                            display:'flex', 
                                justifyContent:'center', 
                                alignItems:'center', 
                                flex: {
                                    xs: finishList.length === 1 ? '0 0 100%' : '0 0 50%',
                                    md:
                                    finishList.length === 1
                                        ? '0 0 100%'
                                        : finishList.length === 2
                                        ? '0 0 50%'
                                        : `0 0 ${100 / 3}%`,
                                },
                                maxHeight:'6.5625rem', 
                                padding:{xs:'5px 5px'}, 
                                boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                                borderRadius: '0.3125rem',
                                backgroundColor: 'white',
                                color:'white',
                                position: 'relative'
                        }}
                    >
                        <CancelIcon
                            sx={{
                                display: isView ? 'none' : 'block',
                                position: 'absolute',
                                color: '#FC581F', 
                                fontSize: '1.2rem',
                                top: '0',
                                right: '0',
                                '&:hover': {
                                    color: '#FF7E44'
                                }
                            }}
                            onClick={() => file.isSaved ? onDeleteFormFiles(index) : onDeleteNewFiles(index)}
                        />
                        <img
                            style={{
                                objectFit:'contain',  
                                height:'100%' 
                            }}
                            key={index} 
                            src={
                                file.isSaved 
                                ? `${domain}${file.url}`
                                : URL.createObjectURL(file)
                            }
                        /> 
                    </Box>
                )
            })}
        </Box>
    );
};

const grid = 8


const getListStyle = isDraggingOver => ({
    backgroundColor: isDraggingOver ? 'whitesmoke': 'white',
    padding: grid,
    width: '100%',
    minHeight: '300px'
  });

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    
    return result;
};

const ProductFormPage = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const inputRef = useRef(null);
    const productToEdit = state && state.productToEdit ? state.productToEdit : '';
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [categories, setCategories] = useState([]);
    const [characteristics, setCharacteristics] = useState([]);
    const [categoriesResume, setCategoriesResume] = useState([]);
    const [productTypeOptions, setProductTypeOptions] = useState([]);
    const [errors, setErrors] = useState(defaultErrorValues);
    const [productForm, setProductForm] = useState({
        productName: '',
        description: '',
        price: '',
        longitude: '',
        width: '',
        height: '',
        weight: '',
        stock: '',
        extraDeliveryTime: '',
        productType: null,
        subByCategories: {},
        images: [],
        files: [],
        ownBox: false,
        woodenBox: false


    });

    const [newFiles, setNewFiles] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [formFilesToDelete, setFormFilesToDelete] = useState([]);
    const [newCharacteristic, setNewCharacteristic] = useState("");
    const [characteristicIdToEdit, setCharacteristicIdToEdit] = useState(null);

    const requiredValues =  !!productForm.productVariants?.length 
    ? ['productName', 'description', 'price', 'productType'] 
    : ['productName', 'description', 'price', 'stock', 'productType', 'extraDeliveryTime'];

    const onDrop = (DroppedFiles) => {

        const filesAccepted = DroppedFiles.filter(file => file.size <= 1 *  1024 * 1024);
        if(DroppedFiles.length != filesAccepted.length){
            enqueueSnackbar("Algunos archivos son demasiado grandes. Deben ser menor o igual a 1 MB.",{
                variant: 'warning'
            });
        }
        if (filesAccepted.length) {
            setNewImages([...newImages,...filesAccepted]);
            inputRef.current.value = '';
        }

    };
    
    const handleLabelClick = () => {
        inputRef.current.click();
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop,
    });
    
    const handleNewFiles = (uploadedFiles) => {
        setNewFiles([...newFiles, ...uploadedFiles])        
    }
    

    useEffect(() => {
        loadData();
    }, []);

    const { productName, description, price, longitude, width, height, weight, stock, extraDeliveryTime, productType, subByCategories, images, files, ownBox, woodenBox } = productForm;
    const { productNameError, descriptionError, priceError, stockError, extraDeliveryTimeError, subByCategoriesError, productTypeError  } = errors;

    const loadData = async () => { 
        const categoriesResult = await getCategories()
        productToEdit && preSetForm(categoriesResult)
    };

    const preSetForm = (categoriesResult = {}) =>{
        const newList = JSON.parse(JSON.stringify(productToEdit.subByCategories));
        Object.keys(categoriesResult).forEach(key => {
            if(!newList[key]) {
                newList[key]=[];
            }
        });
        if(productToEdit.characteristics?.length) {
            setCharacteristics(productToEdit.characteristics.sort((a, b) => Number(a.orderId) - Number(b.orderId)))
        }
        setProductForm({
            ...productToEdit,
            subByCategories: newList
        });
    };

    const getCategories = async () => {
        const categoriesList = await getSubCategoriesOnCategoriesList();
        (Object.keys(categoriesList).length > 0) && setCategories(categoriesList);
        if(!productToEdit){ 
            const newList = {};
            Object.keys(categoriesList).forEach(key => {
                newList[key]=[];
            });
            setProductForm({
                ...productForm,
                subByCategories: newList
            });
        }
        const newErrors = {};
        Object.keys(categoriesList).forEach(key => {
            newErrors[key] = false;
        });
        setErrors( {...errors, subByCategoriesError: newErrors});
        return categoriesList;
    }

    const updateProductForm = (e, key = null) => {
        let inputName = e.target.name;
        let value =  e.target.value;
        let NewValue = undefined;

        const numValues = ['longitude', 'width', 'height', 'weight', 'price'];

        if (numValues.includes(inputName)  && !/^\d*\.?\d{0,2}$/.test(value)) {
            return;
        }

        if(inputName === 'stock' && !/^-?\d*$/.test(value)){
            return;
        }
        
        if(inputName === 'extraDeliveryTime' && !/^\d*$/.test(value)){
            return;
        }
        
        if (inputName === 'subByCategories') {
            const newSubByCategories = JSON.parse(JSON.stringify(subByCategories));
            newSubByCategories[key] = value;
            NewValue = {[inputName]: newSubByCategories};
        } else {
            NewValue = inputName !== 'file'
                ? {[inputName]: value}
                : {
                    file: e.target.files[0],
                    fileName: value
                }
        }


        setProductForm({
            ...productForm,
            ...NewValue
        });
        
        if (inputName === 'subByCategories' && errors[`${inputName}Error`][key]) {

            const newSubByCategoriesErrors = JSON.parse(JSON.stringify(errors[`${inputName}Error`]));
            newSubByCategoriesErrors[key] = false;
            setErrors({ ...errors, [`${inputName}Error`]: newSubByCategoriesErrors });
        } else {
            errors[`${inputName}Error`] && setErrors({ ...errors, [`${inputName}Error`]: false });
        }
    };

    const updateCheck = (e) => {
        let inputName = e.target.name;
        let value =  e.target.checked;

        const newValues = {}
        if(inputName === 'woodenBox'){
            newValues.woodenBox = value;
            newValues.ownBox = value;
        }else{
            newValues.ownBox = value;
            if(!value && woodenBox){
                newValues.woodenBox = value;
            }
        }

        setProductForm({
            ...productForm,
            ...newValues
        });
    }

    const currencyFormat = (e) => {
        let inputName = e.target.name;
        if(!!productForm[inputName]){
            let newNumber = 0 + Number(productForm[inputName]);
            const decimalDigits = newNumber.toString().split('.')[1];
            if (!decimalDigits || decimalDigits.length < 2) {
                newNumber = parseFloat(newNumber).toFixed(2);
            }
            setProductForm({
                ...productForm,
                [`${inputName}`]: newNumber
            });
        }
    };

    const getCategoriesByName = (isResume = false) => {
        const newCategoriesObj = {
            productsSubcategories: [],
            brandsSubcategories: [],
            applicationsSubcategories: [],
            measuresSubcategories: []
        };

        const categoriesNames = {
            productsSubcategories: 'Productos',
            brandsSubcategories: 'Marcas',
            applicationsSubcategories: 'Aplicaciones',
            measuresSubcategories: 'Magnitud'
        };

        const categoriesReadOnly = {
            Productos: [],
            Marcas: [],
            Aplicaciones: [],
            Magnitud: [],
        };

        Object.keys(productForm.subByCategories).forEach(category => {
            if(defaultCategories.includes(categories[category].categoryName)){
                const subcategoriesNames = categories[category].subsByCategory
                    .filter(sub => productForm.subByCategories[category].includes(sub._id))
                    .map(subItem => isResume ? subItem.subcategory.subCategoryName : subItem.subcategory.subCategoryNameNormalized);
                const keyObj = Object.keys(defaultCategoriesObj).find(key => defaultCategoriesObj[key] == categories[category].categoryName);
                newCategoriesObj[`${keyObj}Subcategories`] = subcategoriesNames;
            }
        });

        if(isResume){
            Object.keys(newCategoriesObj).forEach(key => (categoriesReadOnly[categoriesNames[key]] = newCategoriesObj[key]  ));
        }

        return  !isResume ? newCategoriesObj : categoriesReadOnly;
    };

    const validateForm = () =>{
        let newErrors = {};
        
        requiredValues.forEach( key => {
            if(key == 'subByCategories'){
                let newErr = {};
                Object.keys(productForm[`${key}`]).map(categoryKey => {
                    newErr[categoryKey] = !productForm[`${key}`][categoryKey].length;
                });
                newErrors[`${key}Error`] = newErr;
            }else if(key == 'stock'){
                newErrors[`${key}Error`] = !/^-?\d+$/.test(productForm[`${key}`]);
            } else {
                newErrors[`${key}Error`] = !productForm[`${key}`];
            }
        });

        setErrors({
            ...errors,
            ...newErrors
        });

        for (let key in newErrors) {
            if(key == 'subByCategoriesError'){
                for(let subKey of Object.keys(newErrors[key])){
                    if(newErrors[key][subKey]) return false;
                }
            } else if (newErrors[key]) {
              return false;
            }
          }
        return true;
    }

    const OnSubmitForm = async (evt) => {
        evt.preventDefault();
        if(!validateForm()){
            return;
        }

        setIsLoading(true);

        const imagesSaved = [];
        const filesSaved = [];

        if(newImages){
            for (const index in newImages) {
                const presignedUrl  = await productsGetImageUrl(`.${newImages[index].type.split('/')[1]}`);
                const resultUpload  = await productsUploadImage(newImages[index], presignedUrl.url);
                if(!resultUpload.result){
                    enqueueSnackbar("Error al subir las imágenes.",{
                        variant: 'error'
                    });
                    setIsLoading(false);
                    return;
                }
                imagesSaved.push({
                    url: presignedUrl.key,
                    type: newImages[index].type
                });
            }
        }

        if(newFiles){
            console.log("🚀 ~ OnSubmitForm ~ newFiles:", newFiles)
            for (const index in newFiles) {
                const presignedUrl  = await productsGetFileUrl(newFiles[index].path.replace(/\s/g, '-'),);
                console.log("🚀 ~ OnSubmitForm ~ presignedUrl:", presignedUrl)
                const resultUpload  = await productsUploadImage(newFiles[index], presignedUrl.url);
                if(!resultUpload.result){
                    enqueueSnackbar("Error al subir los archivos.",{
                        variant: 'error'
                    });
                    setIsLoading(false);
                    return;
                }
                filesSaved.push({
                    url: presignedUrl.key,
                    type: newFiles[index].type,
                    fileName: presignedUrl.name
                });
            }
        }
        if(formFilesToDelete){
            for (const file of formFilesToDelete) {
                try {
                    const presignedUrlToDelete  = await productsGetImageUrlToDelete(file.url);
                    const resultDelete  = await productsDeleteImage(presignedUrlToDelete.url);
                    if(!resultDelete.result){
                        enqueueSnackbar("Error al eliminar las imágenes.",{
                            variant: 'error'
                        });
                        setIsLoading(false);
                        return;
                    }
                } catch (error) {
                    enqueueSnackbar("Error al eliminar las imágenes.",{
                        variant: 'error'
                    });
                    setIsLoading(false);
                    return;
                }

            }
        }
        const categoriesByName = getCategoriesByName();
        let _characteristics = []
        if(characteristics.length) {
            _characteristics = characteristics.map((it, index) => ({
                orderId: Number(index),
                name: it.name
            }))
        }
        let resultForm
        if(productToEdit) {
            resultForm = await productsUpdate({...productForm, ...categoriesByName, images: [...images, ...imagesSaved], files: [...files, ...filesSaved], characteristics: _characteristics })
        } else {
            resultForm = await productsCreate({...productForm, ...categoriesByName, images: imagesSaved, files: filesSaved, characteristics: _characteristics });
        }
        setIsLoading(false);
        if(resultForm.result){
            enqueueSnackbar("Guardado Exitoso.", {
                variant: 'success',
                style: { 
                    backgroundColor: '#FC581F',
                    Width: '100%',
                    justifyContent: 'center'
                }
            });
            navigate('/admin/products');
        }else{
            enqueueSnackbar(`Error al ${productToEdit ? 'editar': 'crear' } el producto.`, {
                variant: 'error'
            });
        }
    };

    const onDeleteNewFiles = (index) =>{
        const tempFiles = newFiles.filter((file, i) => i != index);
        setNewFiles(tempFiles);
    };

    const onDeleteFormFiles = (index) =>{
        const tempFile = files.find((files, i) => i == index);
        const newFormFiles = files.filter((file, i) => i != index);
        setFormFilesToDelete([...formFilesToDelete, tempFile]);
        
        setProductForm({
            ...productForm,
            files: newFormFiles
        });

    };

    const onDeleteNewImages = (index) =>{
        const tempImages = newImages.filter((image, i) => i != index);
        setNewImages(tempImages);
    };

    const onDeleteFormImages = (index) =>{
        const tempFile = images.find((images, i) => i == index);
        const newFormImages = images.filter((image, i) => i != index);
        setFormFilesToDelete([...formFilesToDelete, tempFile]);
        
        setProductForm({
            ...productForm,
            images: newFormImages
        });

    };

    const onSearchTermChange =  async (newSearchValue) => {
        if(!!newSearchValue){
            setProductTypeOptions([]);
            setIsSearchLoading(true);
            const searchResult = await consignmentNoteClassesSearch(newSearchValue);
            setIsSearchLoading(false);
            const optionsFiltered = searchResult.consignmentNoteClasses.map(option => ({id: option.code, label: option.name}))
            setProductTypeOptions(optionsFiltered)
        }
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const _items = reorder(
            characteristics,
            result.source.index,
            result.destination.index
        );

        setCharacteristics(_items);
    }

    const addNewCharacteristic = () => {
        if(!newCharacteristic.trim()) {
            enqueueSnackbar("La caracteristica no puede estar vacia.",{
                variant: 'error'
            });
            return
        }
        if(characteristicIdToEdit != null) {
            let _characteristics = JSON.parse(JSON.stringify(characteristics)).map(ch => (
                ch.orderId === characteristicIdToEdit ? { ...ch, name: newCharacteristic.trim() } : ch
                )
            )
            setCharacteristics(_characteristics)
            setCharacteristicIdToEdit(null)
            setNewCharacteristic('')
            return
        }
        setCharacteristics(prev => ([
            ...prev,
            {
                orderId: prev.length,
                name: newCharacteristic.trim()
            }
        ]))
        setNewCharacteristic('')
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            addNewCharacteristic()
        }
      };
    
    const deleteCharacteristic = (orderId) => {
        let _characteristics = JSON.parse(JSON.stringify(characteristics)).filter(ch => ch.orderId !== orderId).map((it, index) => ({
            orderId: Number(index),
            name: it.name
        }))
        setCharacteristics(_characteristics)
    }
    
  return (
    <DragDropContext onDragEnd={onDragEnd}>
        <Box minHeight={'90vh'} display={'flex'} flexDirection={'column'}>
            <Box sx={{ flexGrow:1, pt:{md:'3.13rem', lg:'3.75rem'} }}>
                <Box sx={{ pb:{xs: '1.87rem', md:'3.06rem', lg:'1.88rem'}, pl:{xs: '1.5rem', md:'3.13rem'}, pr:{xs: '1.5rem', md:'3.13rem', lg:'3.09rem'} }}>
                    <Typography variant="body1" color="initial" sx={{fontWeight:'bold', fontSize:{ xs: '1rem', md: '1.5rem' } }}> {productToEdit ? 'EDITAR' : 'AGREGAR'} PRODUCTO</Typography>
                    <Box sx={{width:{xs:'3.75rem'}, height:{xs:'0.0625rem'}, backgroundColor: '#FC581F' }}/>
                </Box>
                <Box sx={{ display:'flex', flexDirection:{xs:'column', lg:'row'} }}>
                    <Box sx={{ width:{lg:'23%'}, pb:{xs:'1.87rem', md:'3.12rem', lg:0}, pl:{lg:'3.13rem'}, overflow:{xs:'Hidden', lg:'inherit'} }}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} orientation="horizontal" sx={{ display:{xs:'flex', lg:'none'}, width:{xs:'200%', md:'auto'}, ml:{xs: activeStep == 0 ? '16.2%': activeStep == 1 ? '-50.5%' : '-117.2%', md:'0' } }}>
                            {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    <>
                                        <Typography sx={{fontWeight:'600', fontSize:{xs: '0.8125rem', md:'0.9375rem'}, mt:{xs:'-.375rem'} }}>
                                            {label}
                                        </Typography>
                                        <Typography sx={{ color: activeStep > index ? '#FC581F' :'#A1A1A1', fontSize:{xs: '0.8125rem', md:'0.9375rem'} }}>
                                            {activeStep > index ? 'Completado' : 'Por completar'}
                                        </Typography>
                                    </>
                                </StepLabel>
                            </Step>
                            ))}
                        </Stepper>

                        <Stepper activeStep={activeStep} 
                            sx={{ display:{xs:'none', lg:'flex'}, height:'13.5rem' }}
                        connector={<ColorlibConnector />} 
                        orientation="vertical" 
                        >
                            {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon} sx={{padding:'0'}}>
                                    <>
                                        <Typography sx={{fontWeight:'600', fontSize:{lg:'0.9375rem'} }}>
                                            {label}
                                        </Typography>
                                        <Typography sx={{ color: activeStep > index ? '#FC581F' :'#A1A1A1', fontSize:{lg:'0.8125rem'} }}>
                                            {activeStep > index ? 'Completado' : 'Por completar'}
                                        </Typography>
                                    </>
                                </StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </Box>
                    <Divider sx={{display:{xs:'none', lg:'block'}, borderWidth:'1px', borderColor:'#D9D9D9', height:'39.4375rem' }}/>
                    <Box sx={{pl:{xs: '1.5rem', md:'3.13rem', lg:'3.09rem'}, pr:{xs: '1.5rem', md:'3.13rem', lg:'3.09rem'}, width:{lg:'77%'}}}>
                        <Box sx={{
                            width:'100%',
                            borderRadius: '0.625rem',
                            border: '1px solid #FC581F',
                            padding: '1.88rem',
                            mb:{xs: '3.12rem', lg:'1rem'}
                        }}>
                            <Typography sx={subTitleStyle}> 
                                {formTitles[activeStep]}
                            </Typography>
                            {activeStep == 0 && (
                                <>
                                    <Grid container rowSpacing={{xs:'1.25rem' }} columnSpacing={{xs:'1.87rem'}}>
                                        <Grid item xs={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <FormLabel sx={formLabelStyle}>
                                                    NOMBRE DEL PRODUCTO <Typography sx={requiredLabelStyle}>*</Typography>
                                                </FormLabel>
                                                <TextField 
                                                    inputProps={inputStyle}
                                                    error={productNameError}
                                                    placeholder='Escribe el nombre del producto'
                                                    size='small'
                                                    name='productName' 
                                                    value={productName}
                                                    onChange={updateProductForm}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <FormLabel sx={formLabelStyle} > 
                                                    DESCRIPCIÓN DEL PRODUCTO <Typography sx={requiredLabelStyle}>*</Typography> 
                                                </FormLabel>
                                                <TextField
                                                    multiline
                                                    rows={4}
                                                    placeholder='Escribe una descripción para el producto' 
                                                    inputProps={inputStyle}
                                                    error={descriptionError}
                                                    size='small'
                                                    name='description' 
                                                    value={description}
                                                    onChange={updateProductForm}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <FormLabel sx={{...formLabelStyle, pb:'0.4rem'}}> MEDIA </FormLabel>
                                                <Box {...getRootProps()} style={dropzoneStyle} onClick={handleLabelClick}>
                                                    <input {...getInputProps()} ref={inputRef} style={{ display: 'none' }}/>
                                                    <ImageOutlinedIcon sx={{color: '#FC581F', fontSize:{xs:'3rem'} }}/>
                                                    <Typography sx={{fontSize:{xs: '0.8125rem', md:'0.9375rem'}, pt:'0.75rem' }}>Arrastra una imagen o <Typography variant='span' sx={{ textDecoration: 'underline', cursor: 'pointer', fontSize:{xs: '0.8125rem', md:'0.9375rem'}, color: '#FC581F' }}>adjunta aquí</Typography>
                                                    </Typography>
                                                </Box>  
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ImgViewer newImages={newImages} images={images} onDeleteFormFiles={onDeleteFormImages} onDeleteNewFiles={onDeleteNewImages} isView={false}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <FormLabel sx={formLabelStyle}> 
                                                    PRECIO BASE <Typography sx={requiredLabelStyle}>*</Typography>
                                                </FormLabel>
                                                <TextField 
                                                    inputProps={{ inputMode: 'numeric', ...inputStyle}} 
                                                    error={priceError}
                                                    size='small'
                                                    placeholder='00.00'
                                                    name='price' 
                                                    value={price}
                                                    onChange={updateProductForm}
                                                    onBlur={currencyFormat}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormControl fullWidth>
                                                <FormLabel sx={formLabelStyle}> 
                                                    TIPO DE PRODUCTO <Typography sx={requiredLabelStyle}>*</Typography>
                                                </FormLabel>
                                                <AutocompleteProductType options={productTypeOptions} isLoading={isSearchLoading} selectedValue={productType} onOptionChange={updateProductForm} onSearchTermChange={onSearchTermChange}/>
                                            </FormControl>
                                        </Grid>
                                        {!productForm.productVariants?.length && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <FormLabel sx={formLabelStyle}> 
                                                            STOCK <Typography sx={requiredLabelStyle}>*</Typography>
                                                        </FormLabel>
                                                        <TextField 
                                                            fullWidth 
                                                            size='small'  
                                                            placeholder='0' 
                                                            name='stock'
                                                            value={stock}
                                                            error={stockError}
                                                            onChange={updateProductForm}
                                                            InputProps={{ endAdornment: <InputAdornment position="end"> piezas</InputAdornment> }}
                                                            sx={{
                                                                ...inputStyle,
                                                                "& .MuiInputBase-root input": { 
                                                                    padding:'0.625rem 0rem 0.625rem 1rem !important',
                                                                    textAlign:'right'
                                                                } 
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FormControl fullWidth>
                                                        <FormLabel sx={formLabelStyle}> 
                                                            TIEMPO DE ENTREGA <Typography sx={requiredLabelStyle}>*</Typography>
                                                        </FormLabel>
                                                        <TextField 
                                                            fullWidth 
                                                            size='small'  
                                                            placeholder='0' 
                                                            name='extraDeliveryTime'
                                                            value={extraDeliveryTime}
                                                            error={extraDeliveryTimeError}
                                                            onChange={updateProductForm}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">semanas</InputAdornment>,
                                                            }}
                                                            sx={{
                                                                ...inputStyle,
                                                                "& .MuiInputBase-root input": { 
                                                                    padding:'0.625rem 0rem 0.625rem 1rem !important',
                                                                    textAlign:'right'
                                                                } 
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item >
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox name='ownBox' checked={ownBox} onChange={updateCheck} />
                                                } 
                                                label="Caja Propia"
                                            />
                                        </Grid>
                                        <Grid item >
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox name='woodenBox' checked={woodenBox} onChange={updateCheck} />
                                                } 
                                                label="Caja de Madera"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography sx={{ color:'#FC581F', fontWeight:'bold', fontSize:{xs:'0.8125rem'}, pt:{xs: '1.25rem'} }}>
                                        *Campos marcados son obligatorios
                                    </Typography>
                                </>
                            )}
                            {activeStep == 1 && (
                                <>
                                    <Grid container rowSpacing={{xs:'1.25rem' }} columnSpacing={{lg:'1.81rem'}}>
                                        {Object.keys(subByCategories).length > 0 && Object.keys(categories).map((key, indx) => {
                                            const category = categories[key];
                                            return (
                                                <Grid key={indx} item xs={12} lg={6}>
                                                    <FormControl fullWidth>
                                                        <FormLabel sx={{...formLabelStyle, textTransform:'uppercase'}}> Selecciona las subcategorías de "{category.categoryName}": </FormLabel>
                                                        <Select
                                                            sx={ inputStyle.sx }
                                                            error={subByCategoriesError[key]}
                                                            placeholder={category.categoryName}
                                                            displayEmpty
                                                            multiple
                                                            size='small'
                                                            value={subByCategories[key]}
                                                            name='subByCategories'
                                                            IconComponent={() => <NavigateNextIcon sx={{transform: 'rotate(90deg)', color: '#FC581F', fontSize: {xs:'1.7rem', md:'2rem'} }}/>}
                                                            MenuProps={{
                                                                style: {
                                                                fontSize: '11px',
                                                            }}}
                                                            onChange={e => updateProductForm(e, key)}
                                                        >
                                                            {category.subsByCategory.length && category.subsByCategory.map( subByCategory => (
                                                                <MenuItem sx={inputStyle.sx} key={subByCategory._id} value={subByCategory._id}>{subByCategory.subcategory.subCategoryName}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    <Box sx={{py:'1.88rem'}}>
                                        <Divider orientation='horizontal' 
                                        sx={{
                                            borderWidth:'1px', 
                                            borderColor:'#FFEBE6', 
                                        }}
                                        />
                                    </Box>
                                    <Typography sx={subTitleStyle}> 
                                            Dimensiones
                                        </Typography>
                                    <Grid container rowSpacing={{xs:'1.25rem' }} columnSpacing={{xs: '1rem', lg:'1.87rem'}}>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <FormLabel sx={formLabelStyle}>
                                                    LARGO
                                                </FormLabel>
                                                <TextField
                                                    inputProps={inputStyle}
                                                    sx={{"& .MuiInputBase-root input": {textAlign:'right'}}} 
                                                    placeholder='10'
                                                    size='small'
                                                    name='longitude'
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography sx={{...inputStyle.sx, lineHeight: 'inherit', color: '#303030'}}>cm</Typography>
                                                        </InputAdornment>,
                                                    }}
                                                    value={longitude}
                                                    onChange={updateProductForm}
                                                    onBlur={currencyFormat}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <FormLabel sx={formLabelStyle}>
                                                    ANCHO
                                                </FormLabel>
                                                <TextField
                                                    inputProps={inputStyle}
                                                    sx={{"& .MuiInputBase-root input": {textAlign:'right'}}} 
                                                    placeholder='10'
                                                    size='small'
                                                    name='width'
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography sx={{...inputStyle.sx, lineHeight: 'inherit', color: '#303030'}}>cm</Typography>
                                                        </InputAdornment>,
                                                    }}
                                                    value={width}
                                                    onChange={updateProductForm}
                                                    onBlur={currencyFormat}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <FormLabel sx={formLabelStyle}>
                                                    ALTO
                                                </FormLabel>
                                                <TextField
                                                    inputProps={inputStyle}
                                                    sx={{"& .MuiInputBase-root input": {textAlign:'right'}}} 
                                                    placeholder='10'
                                                    size='small'
                                                    name='height'
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography sx={{...inputStyle.sx, lineHeight: 'inherit', color: '#303030'}}>cm</Typography>
                                                        </InputAdornment>,
                                                    }}
                                                    value={height}
                                                    onChange={updateProductForm}
                                                    onBlur={currencyFormat}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="standard" fullWidth>
                                                <FormLabel sx={formLabelStyle}>
                                                    PESO
                                                </FormLabel>
                                                <TextField
                                                    inputProps={inputStyle}
                                                    sx={{"& .MuiInputBase-root input": {textAlign:'right'}}} 
                                                    placeholder='100'
                                                    size='small'
                                                    name='weight'
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">
                                                            <Typography sx={{...inputStyle.sx, lineHeight: 'inherit', color: '#303030'}}>kg</Typography>
                                                        </InputAdornment>,
                                                    }}
                                                    value={weight}
                                                    onChange={updateProductForm}
                                                    onBlur={currencyFormat}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{py:'1.88rem'}}>
                                        <Divider orientation='horizontal' 
                                        sx={{
                                            borderWidth:'1px', 
                                            borderColor:'#FFEBE6', 
                                        }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography sx={subTitleStyle}> 
                                            Cargar Manual / Ficha Técnica
                                        </Typography>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem', pb: (newFiles.length || files.length) ? '1.25rem': '' }}>
                                            {files.map((item, indx) => (
                                                <FileDownloader key={`${indx}a`} index={indx} file={item} onDelete={onDeleteFormFiles} canDelete={true}/>
                                            ))}
                                            {newFiles.map((item, indx) => (
                                                <FileDownloader key={indx} index={indx} file={item} onDelete={onDeleteNewFiles} canDelete={true}/>
                                            ))}
                                        </Box>
                                        <UploadFile label={'Agregar archivo PDF'} acceptTypes={{'application/pdf': []}} handleNewFiles={handleNewFiles} />
                                    </Box>
                                </>
                            )}
                            {activeStep == 2 && (
                                <>
                                    <FormLabel sx={{ ...formLabelStyle, paddingBottom: '0.7rem' }}>
                                        AGREGAR CARACTERISTICA
                                    </FormLabel>
                                    <Grid container rowSpacing={{xs:'1.25rem' }} columnSpacing={{xs:'1.87rem'}}>
                                        <Grid item xs={10} md={8} lg={8}>
                                            <FormControl variant="standard" fullWidth>
                                                <TextField 
                                                    inputProps={{sx: { fontSize:{xs: '0.8125rem', md: '1rem', background: 'white'} }}}
                                                    error={productNameError}
                                                    placeholder='Escribe la característica para el producto'
                                                    size='small'
                                                    name='productName' 
                                                    value={newCharacteristic}
                                                    onKeyDown={handleKeyDown}
                                                    onChange={(e) => setNewCharacteristic(e.target.value)}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2} md={4} lg={4}>
                                            <Box sx={{ display:'flex', gap:{xs:'0.25rem', lg:'1rem'}, width: '100%', height: '100%' }}>
                                                <Button 
                                                    sx={{ 
                                                    fontSize: '0.8125rem',
                                                    fontWeight:'700',
                                                    background: '#FC581F',
                                                    color: '#FFF',
                                                    textTransform:'none',
                                                    minWidth: {xs: 0, sm: '100%', lg: '100%'},
                                                    p:{xs:' 0.688rem 0.688rem', lg:'6px 16px'},
                                                    borderRadius: { xs: '62.5rem', lg: '0.2rem', md: '0.2rem' },
                                                    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)',
                                                    '& .MuiButton-startIcon':{
                                                        marginRight: {xs:'0', lg: '8px'},
                                                        marginLeft: {xs:'0', lg: '0px'},
                                                    },
                                                    '&:hover': { 
                                                        backgroundColor: '#FAFAFA',
                                                        color: '#FC581F',
                                                    }
                                                    }}
                                                    variant="contained" 
                                                    startIcon={characteristicIdToEdit ? <SaveOutlinedIcon sx={{fontSize:'1.5rem !important'}} /> : <AddIcon sx={{fontSize:'1.5rem !important'}} />}
                                                    onClick={addNewCharacteristic}
                                                >
                                                    <Typography sx={{ display:{xs:'none', md:'block', lg:'block'}, fontSize: '0.8125rem', fontWeight:'700', }}>
                                                        {characteristicIdToEdit ? 'Guardar cambios' : 'Agregar característica'}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Droppable droppableId="droppable" direction='vertical'>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                {characteristics?.map((item, index) => (
                                                    <Draggable key={'draggable-characteristics-key-' + item.orderId} draggableId={'draggable-characteristics-' + item.orderId} index={index}>
                                                        {(provided, snapshot) => (
                                                            <Box
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    borderBottom: '1px solid #E9E9E9',
                                                                    paddingBottom: '0.5rem',
                                                                    paddingTop: '0.5rem',
                                                                    color: '#514F4F'
                                                                }}
                                                            >
                                                                <Box>
                                                                    <ReorderIcon
                                                                        sx={{
                                                                            color: '#303030',
                                                                            marginRight: '5px'
                                                                        }}
                                                                    />
                                                                    {item.name}
                                                                </Box>
                                                                <Box>
                                                                    <ModeEditOutlineOutlinedIcon
                                                                        sx={{
                                                                            color: '#303030',
                                                                            marginRight: '5px'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            setCharacteristicIdToEdit(item.orderId)
                                                                            setNewCharacteristic(item.name)
                                                                        }}
                                                                    />
                                                                    <DeleteOutlinedIcon
                                                                        sx={{
                                                                            color: '#303030',
                                                                            marginRight: '5px'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            deleteCharacteristic(item.orderId)
                                                                        }}
                                                                    />
                                                                    
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                                </div>
                                            )}
                                            </Droppable>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                            {activeStep == 3 && (
                                <>
                                    <Box sx={{px: sectionStyle.px }}>
                                        <Typography sx={{fontSize:{xs:'0.875rem', lg:'1.125rem'}, ...divStyle, fontWeight:'700' }}>{productName}</Typography>
                                        <Typography sx={{fontSize:{xs:'0.875rem', lg:'1.125rem'}, ...divStyle }}>{description}</Typography>
                                        <Box sx={{pb: sectionStyle.paddingBottom, px:{ md:'1.5625rem'} }}>
                                            <ImgViewer newImages={newImages} images={images} onDeleteFormFiles={() =>{}} onDeleteNewFiles={() => {}} isView={true}/>
                                        </Box>
                                        <InfoBox label={'Precio Base'} value={`$${price}`} spaceBottom={true}/>
                                        {!productForm.productVariants?.length && (
                                            <>
                                                <InfoBox label={'Stock'} value={`${stock} Piezas`} spaceBottom={true}/>
                                                <InfoBox label={'Tiempo extra'} value={`${extraDeliveryTime} Semanas`} spaceBottom={false}/>
                                            </>    
                                        )}
                                    </Box>
                                    <DividerOrange/>
                                    <Box sx={{px:{ md: sectionStyle.px.xs} }}>
                                        {
                                            Object.keys(categoriesResume).map((key, index) => {
                                                const categoriesString = categoriesResume[key].join(', ');
                                                return (
                                                    <Box key={index} sx={divStyle}>
                                                        <SubTitle label={key} />
                                                        <Typography sx={{...inputStyle.sx }}> {categoriesString ? categoriesString : 'No Aplica'}</Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Box >
                                            <SubTitle label={'Características'} />
                                            {characteristics.length && (
                                                <Typography sx={{...inputStyle.sx }}>{characteristics.map(x => x.name).join(', ')}</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <DividerOrange/>
                                    <Box sx={{px:{ md: sectionStyle.px.xs}}}>
                                        <SubTitle label={'Dimensiones'} />
                                        <Grid sx={{ display:{md: 'none'} }} container rowSpacing={{xs:'0.5rem' }} columnSpacing={{xs: '0.5rem'}}>
                                            <Grid item xs={6}>
                                                <Box sx={{display: 'flex', gap: '0.5rem'}}>
                                                    <Typography sx={measurementsTitleStyle}>Largo:</Typography>
                                                    <Typography sx={measurementsValueStyle}>{`${longitude} cm.`}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{display: 'flex', gap: '0.5rem'}}>
                                                    <Typography sx={measurementsTitleStyle}>Ancho:</Typography>
                                                    <Typography sx={measurementsValueStyle}>{`${width} cm.`}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{display: 'flex', gap: '0.5rem'}}>
                                                    <Typography sx={measurementsTitleStyle}>Alto:</Typography>
                                                    <Typography sx={measurementsValueStyle}>{`${height} cm.`}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box sx={{display: 'flex', gap: '0.5rem'}}>
                                                    <Typography sx={measurementsTitleStyle}>Peso:</Typography>
                                                    <Typography sx={measurementsValueStyle}>{`${weight} kg.`}</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box sx={{display: {xs: 'none', md: 'flex'}, gap: '0.5rem' }}>
                                            <Typography sx={measurementsTitleStyle}>Largo:</Typography>
                                            <Typography sx={measurementsValueStyle}>{`${longitude} cm.`}</Typography>
                                            <Typography sx={measurementsTitleStyle}>/</Typography>
                                            <Typography sx={measurementsTitleStyle}>Ancho:</Typography>
                                            <Typography sx={measurementsValueStyle}>{`${width} cm.`}</Typography>
                                            <Typography sx={measurementsTitleStyle}>/</Typography>
                                            <Typography sx={measurementsTitleStyle}>Alto:</Typography>
                                            <Typography sx={measurementsValueStyle}>{`${height} cm.`}</Typography>
                                            <Typography sx={measurementsTitleStyle}>/</Typography>
                                            <Typography sx={measurementsTitleStyle}>Peso:</Typography>
                                            <Typography sx={measurementsValueStyle}>{`${weight} kg.`}</Typography>
                                        </Box>
                                    </Box>
                                    <DividerOrange/>
                                    <Box sx={{px:{ md: sectionStyle.px.xs}}}>
                                        <SubTitle label={'Características'} />
                                        <Typography sx={{...inputStyle.sx }}> Modular, compact, inline flow meter for compressed air and gases, hot film sensor element.</Typography>
                                    </Box>
                                    <DividerOrange/>
                                    <Box sx={{px:{ md: sectionStyle.px.xs}}}>
                                        <SubTitle label={'Manual / Ficha Técnica'} />
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                            {files.map((item, indx) => (
                                                <FileDownloader key={`${indx}a`} index={indx} file={item} onDelete={onDeleteFormFiles} canDelete={false}/>
                                            ))}
                                            {newFiles.map((item, indx) => (
                                                <FileDownloader key={indx} index={indx} file={item} onDelete={onDeleteNewFiles} canDelete={false}/>
                                            ))}
                                        </Box>
                                    </Box>
                                </>
                            )}
                            <Box sx={{ display:'flex', justifyContent: (activeStep > 0) ? 'space-between' : 'flex-end', alignItems:'center', pt:{xs:'1.87rem', md:'1.97rem'} }}>
                                <IconButton sx={{p:0, opacity: isLoading ? 0.5 : 1, display: (activeStep > 0) ? 'flex' : 'none' }}
                                    onClick={() => {
                                        setActiveStep(activeStep-1);
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}
                                    disabled={isLoading}
                                >
                                    <ArrowForwardIcon sx={{color:'#FC581F', fontSize:{xs:'1.13rem', md:'1.88rem'}, transform: 'rotate(180deg)'}}/>
                                    <Typography sx={{color:'#303030', fontWeight:'bold', fontSize:{xs:'0.8125rem', md: '0.875rem'}, pl:{xs:'0.25rem'} }}>Anterior</Typography>
                                </IconButton>
                                <IconButton sx={{p:0, opacity: isLoading ? 0.5 : 1, display: (activeStep > 2) ? 'none' : 'flex' }}
                                    onClick={() => {
                                        if(activeStep == 0 && !validateForm()){
                                            return;
                                        }
                                        if(activeStep==1){
                                            setCategoriesResume(getCategoriesByName(true));
                                        }
                                        setActiveStep(activeStep+1);
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }}
                                    disabled={isLoading}
                                >
                                    <Typography sx={{color:'#303030', fontWeight:'bold', fontSize:{xs:'0.8125rem', md: '0.875rem'}, pr:{xs:'0.25rem'} }}>{activeStep > 1 ? 'Ir a Resumen' : 'Siguiente'}</Typography>
                                    <ArrowForwardIcon sx={{color:'#FC581F', fontSize:{xs:'1.13rem', md:'1.88rem'} }}/>
                                </IconButton>
                                <IconButton sx={{p:0, opacity: isLoading ? 0.5 : 1, display: (activeStep > 2) ? 'flex' : 'none' }}
                                    onClick={(e) => {
                                        // activeStep < 4 && setActiveStep(activeStep+1);
                                        OnSubmitForm(e);
                                    }}
                                    disabled={isLoading}
                                >
                                    <Typography sx={{color:'#303030', fontWeight:'bold', fontSize:{xs:'0.8125rem', md: '0.875rem'}, pr:{xs:'0.5rem'} }}>Finalizar</Typography>
                                    <DoneIcon sx={{color:'#FFFFFF', backgroundColor:'#00CA45', borderRadius:'100%', fontSize:{xs:'0.75rem', md:'2.44rem'}, padding: {xs:'1px', md:'7px'} }}/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    </DragDropContext>
  )
}

export default ProductFormPage