import React, { useState, useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Select, MenuItem, InputAdornment, Button } from '@mui/material';
import ButtonStyled from './ButtonStyled';
import InputSelect from './productVariants/InputSelect';
import { getElementByPath, sumExtraPrice, truncateDecimal } from '../utils/utils';
import { UserContext } from '../context/UserContext';

const styles = {
    titleInput: {
        color: '#A1A1A1',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        pb:'1.5rem'
    },
    selectInput: {
        backgroundColor: '#FFF'
    },
    stackGrid: {
        display: 'flex',
        flexDirection: 'column',
        gap:'1.5rem'
    },
    card:{
        borderRadius: '0.25rem',
        background: '#FFF',
        display: 'flex',
        padding: '2rem 3rem',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width:'100%'
    },
    titleProduct: {
        color: '#303030',
        textAlign: 'center',
        fontSize: '1.125rem',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        pt: '1.88rem',
        pb:'1rem'
    }
}

const ProductDetails = ({text, value, bold = false}) => {
    return (
        <Box sx={{display:'flex', gap:'0.625rem'}}>
            <Typography sx={{width:'50%', fontSize: '1rem', fontStyle: 'normal', fontWeight: '400',}}>{text}</Typography>
            <Typography sx={{width:'50%', fontSize: '1rem', fontStyle: 'normal', textAlign:'end', fontWeight: bold ? '700' : '600'}}>{value}</Typography>
        </Box>
    )
}

const ProductResumeCard = ({product, selectedVariant, handleStepper, handleSaveForm}) => {
    const { domain } = React.useContext(UserContext);
    
    const ProductPrice = truncateDecimal(Number(product.price).toFixed(5));
    const totalExtraPrice = truncateDecimal(Number(sumExtraPrice(selectedVariant)).toFixed(5));
    const totalPrice = truncateDecimal(((Number(totalExtraPrice) + Number(product.price)).toFixed(5)));
    return (
        <Box sx={styles.card}>
            <Box sx={{width: '100%'}}>
                <Box sx={{ height:'19.0625rem', display:'flex', justifyContent:'center', alignItems: 'center' }}>
                    {
                        !!product.images.length
                        ?
                            <img
                                style={{
                                    objectFit:'contain',  
                                    height:'100%' 
                                }}
                                src={`${domain}${product.images[0].url}`}
                            /> 
                        : <Typography>Sin Imagen</Typography>
                    }
                </Box>
                <Typography sx={styles.titleProduct}> {product.productName}</Typography>
                <Box sx={{background: '#FC581F', height:'0.0625rem'}}/>
            </Box>
            <Box sx={{width:'100%', display:'flex', flexDirection:'column', gap: '0.62rem', pt:'3.12rem'}}>
                <ProductDetails text={'ID Único de Producto'} value={selectedVariant ? selectedVariant.sku : ''}/>
                <ProductDetails text={'Precio Base'} value={`$ ${ProductPrice} USD`}/>
                <ProductDetails text={'Personalización'} value={`$${totalExtraPrice} USD`}/>
                <ProductDetails text={'Total'} value={`$${totalPrice} USD`}/>
            </Box>
            <Box sx={{width:'100%', display:'flex', justifyContent: 'space-between', gap: '2.5rem', pt: '1.88rem' }}>
                <ButtonStyled text={'REGRESAR'} primary={false} handleClick={() => {handleStepper(-1)}}/>
                <ButtonStyled text={'COMPLETAR'} handleClick={handleSaveForm}/>
            </Box>
        </Box>
    )
}

const ProductVariantResume = ({product, handleStepper, handleSaveForm, productVariants, tree, selectedCharacteristics}) => {

    const [pathTree, setPathTree] = useState([]);
    const [characteristicListData, setCharacteristicListData] = useState([]);
    const [selectedVariant, setSelectedVariant] = useState(null);

    useEffect(() => {
        updateTreeList();
    }, [])

    const updateTreeList = () => {
        const result = getInputSelect(tree, 0, []);
        setPathTree(result.path);
        setCharacteristicListData(result.elements);

        const element = getElementByPath(result.path,tree, productVariants);
        setSelectedVariant(element);
    }

    const getInputSelect = (tree, lvl, path ) => {
        if(!tree.length) {
            return {elements: [], path: []};
        }

        const leafNumber = !!path.length ? path[0] : 0;
        let result = getInputSelect(tree[leafNumber].children, lvl+1,path.filter((item,index) => index > 0));
        const name = tree[0].characteristicName;
        const values = tree.map(item => item.value);
        return {
            elements: [
                {
                    key: lvl,
                    level: lvl,
                    textTitle: name,
                    options: values,
                    defaultValue: leafNumber,
                }, 
                ...result.elements
            ],
            path: [leafNumber ,...result.path],
        };
        
    }

    const handleOnchange = (title, value, level, indexOption) => {        
        let newPathTree = pathTree.filter((element, index) => index < level);
        newPathTree.push(indexOption);
        setPathTree(newPathTree);
        
        const result  = getInputSelect(tree, 0, newPathTree);
        setCharacteristicListData(result.elements);
        setPathTree(result.path);
        const element = getElementByPath(result.path,tree, productVariants);
        setSelectedVariant(element);
    }
    
    return (
    <Box sx={{width:'100%', display:'flex', gap: '3rem' }}>
        <Box sx={{width:'55%', ...styles.stackGrid }}>
            {!!characteristicListData.length && characteristicListData.map(item => 
                (<InputSelect key={item.key} level={item.level} textTitle={item.textTitle} options={item.options} defaultValue={item.defaultValue} handleOnchange={handleOnchange}/>)
            )}
        </Box>
        <Box sx={{width:'45%'}}>
            <ProductResumeCard product={product} selectedVariant={selectedVariant} handleStepper={handleStepper} handleSaveForm={handleSaveForm}/>
        </Box>
    </Box>
  )
}

export default ProductVariantResume