import axios from '../utils/axios';

const SERVICE_URL = '/categories';

export const getCategoriesList = async () => {
    try {
        let response = await axios.get(`${SERVICE_URL}`);
        return response.data;
    } catch (error) {
        return {
            subCategories: [],
            categories: [],
            subByCategories: []
        };
    }
}

export const categoriesCreate = async (formData) => {

    try {
        let response = await axios.post(`${SERVICE_URL}/create`, formData );
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}

export const canDeleteSubByCategory = async (ids) => {

    try {
        let response = await axios.post(`${SERVICE_URL}/candeletesubbycategory `, {ids} );
        return response.data;
    } catch (error) {
        return {
            result: false,
            error
        };
    }
}