import axios from '../utils/axios';

const SERVICE_URL = '/users';

export const login = async (username, password) => {
    try {
        let response = await axios.post(`${SERVICE_URL}/login`, { username, password });
        return response.data;
    } catch (error) {
        return error;
    }
}

export const userGetPresignedUrlForCfdiByCartId = async (cartId) => {
    try {
        let response = await axios.get(`${SERVICE_URL}/cfdi/get-url/cart/${cartId}`,);
        return response.data;
    } catch (error) {
        return error;
    }
}
