import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";

const UploadFile = ({ label, acceptTypes, handleNewFiles }) => {
  const inputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = (DroppedFiles) => {
    const filesAccepted = DroppedFiles.filter(
      (file) => file.size <= 7 * 1024 * 1024
    );
    if (DroppedFiles.length != filesAccepted.length) {
      enqueueSnackbar(
        "Algunos archivos son demasiado grandes. Deben ser menor o igual a 7 MB.",
        {
          variant: "warning",
        }
      );
    }
    if (filesAccepted.length) {
      handleNewFiles(filesAccepted);
      inputRef.current.value = '';
    }
  };

  const handleLabelClick = () => {
    inputRef.current.click();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptTypes,
    onDrop,
  });

  return (
    <Box>
      <input {...getInputProps()} ref={inputRef} style={{ display: "none" }} />
      <Button
        {...getRootProps()}
        onClick={handleLabelClick}
        startIcon={<AddIcon sx={{ fontSize: "1.5rem !important" }} />}
        sx={{
          borderRadius: "62.5rem",
          padding: "0.75rem 1rem",
          border: isDragActive ? "1px dashed #FC581F" : "1px solid #D9D9D9",
          background: "#FFFEFC",
          display: "flex",
          justifyContent: "center",
          color: "#FC581F",
          fontSize: { xs: "0.8125rem" },
          fontWeight: "bold",
          textTransform: "none",
          boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        {label}
      </Button>
    </Box>
  );
};

export default UploadFile;
